import Vue from "vue";
import useRequest from "@/utils/useRequest";

export default {
  namespaced: true,
  state: {
    attorneys: [],
  },
  mutations: {
    set_attorneys(state, attorneys) {
      Vue.set(state, "attorneys", attorneys);
    },
  },
  actions: {
    async loadAttorneys({ commit, rootGetters }) {
      if (!rootGetters.isAuthenticated) {
        console.log("unauthenticated, I will not load profile infos");
        return;
      }
      await useRequest({
        path: `attorneys?firmId=${rootGetters.firm.id}`,
        onSuccess: (attorneys) => commit("set_attorneys", attorneys),
      });
    },
    async insert({ state, commit }, newAttorney) {
      const { data: res } = await useRequest({
        method: "post",
        path: "attorneys",
        data: newAttorney,
        showLoading: true,
      });
      if (res.success) {
        commit("set_attorneys", [
          ...state.attorneys,
          { ...newAttorney, id: res.addedId },
        ]);
      }
    },
    async update({ state, commit }, updatedAttorney) {
      const { data: res } = await useRequest({
        method: "put",
        path: "attorneys",
        data: updatedAttorney,
      });
      if (res.success) {
        const attorneys = state.attorneys.map((attorney) => {
          if (attorney.id === updatedAttorney.id)
            return { ...attorney, ...updatedAttorney };
          else return attorney;
        });
        commit("set_attorneys", attorneys);
      }
    },
    async delete({ state, commit }, id) {
      const { data: res } = await useRequest({
        method: "delete",
        path: "attorneys",
        data: {
          id,
        },
        showLoading: true,
      });
      if (res.success) {
        commit(
          "set_attorneys",
          state.attorneys.filter((attorney) => attorney.id !== id)
        );
      }
    },
  },
  getters: {
    attorneys: (state) => state.attorneys,
  },
};
