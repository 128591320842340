<template>
  <div>
    <div class="mt-4 mb-2 font-weight-medium">Column Headers</div>
    <div>
      Review the following table and ensure that your column headers match. The
      first row on your spreadsheet must contain all data.
    </div>

    <v-data-table
      :headers="headers"
      :items="clientHeaders"
      class="mt-8 secondary--text"
      hide-default-footer
    >
      <template v-slot:item="{ item, index }">
        <tr>
          <td class="font-weight-medium">{{ index + 1 }}</td>
          <td>{{ item.A }}</td>
          <td>{{ item.B }}</td>
          <td>{{ item.C }}</td>
          <td>{{ item.D }}</td>
          <td>{{ item.E }}</td>
          <td>{{ item.F }}</td>
          <td>{{ item.G }}</td>
          <td>{{ item.H }}</td>
          <td>{{ item.I }}</td>
        </tr>
      </template>
    </v-data-table>

    <div class="mt-12 mb-2 font-weight-medium">Accepted File Types</div>
    <div>
      The following file types are accepted: CSV, XLS, XLSX. You may upload 1000
      rows at a time.
    </div>

    <v-card-actions class="mt-16 mb-8 d-flex justify-end">
      <v-btn
        color="primary"
        class="mr-auto btn--plain capital--btn"
        rounded
        text
        @click="downloadSample"
      >
        Download Sample File
      </v-btn>
      <v-btn
        color="secondary"
        class="mr-4 btn--plain capital--btn"
        rounded
        text
        @click="back"
      >
        Go Back
      </v-btn>
      <v-btn
        color="primary"
        class="px-10 white--text"
        rounded
        depressed
        @click="next"
      >
        Next
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  name: "PrepareFile",
  data() {
    return {
      headers: [
        {
          text: "",
          class: "header-text secondary--text font-weight-medium",
          align: "center",
          sortable: false,
          width: "60px",
        },
        {
          text: "A",
          value: "A",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
          width: "200px",
        },
        {
          text: "B",
          value: "B",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
          width: "200px",
        },
        {
          text: "C",
          value: "C",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
          width: "200px",
        },
        {
          text: "D",
          value: "D",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
          width: "200px",
        },
        {
          text: "E",
          value: "E",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
          width: "200px",
        },
        {
          text: "F",
          value: "F",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
          width: "200px",
        },
        {
          text: "G",
          value: "G",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
          width: "200px",
        },
        {
          text: "H",
          value: "H",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
          width: "200px",
        },
        {
          text: "I",
          value: "I",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
          width: "200px",
        },
      ],
      clientHeaders: [
        {
          A: "First Name",
          B: "Last Name",
          C: "Company",
          D: "Phone",
          E: "Email",
          F: "Street Address",
          G: "City",
          H: "State",
          I: "Zip Code",
        },
      ],
    };
  },
  methods: {
    back() {
      this.$emit("updateStep", "back");
    },
    next() {
      this.$emit("updateStep", "next");
    },
    downloadSample() {
      window.open("/import_client_sample.xlsx", "_self");
    },
  },
};
</script>

<style scoped>
.v-data-table >>> th,
.v-data-table >>> td {
  border: 1px solid lightgrey;
}
.theme--light.v-data-table >>> thead tr th:first-child {
  border-top-left-radius: 0 !important;
}
.theme--light.v-data-table >>> thead tr th:last-child {
  border-top-right-radius: 0 !important;
}
.theme--light.v-data-table >>> tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0 !important;
}
.theme--light.v-data-table >>> tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0 !important;
}
</style>
