export const courtOutcomesObject = {
  "Case Scheduled for Trial": [
    "Did not reach an agreement", // 0
    "Defendants didn't show up", // 1
  ],
  "Executed Consent to Enter Judgment": [
    "Tenant Pays and Stays", // 2
    "Tenant Vacates with Payment Plan", // 3
    "Tenant Vacates without Payment Plan", // 4
    "Pending Judge Approval", // 5
  ],
  Other: [
    "Marini Hearing Scheduled", // 6
    "Bankruptcy Pending", // 7
  ],
};

export const courtOutcomesString = () => {
  const result = [];
  Object.keys(courtOutcomesObject).forEach((key) => {
    courtOutcomesObject[key].forEach((item) => {
      result.push(`${key} - ${item}`);
    });
  });

  return result;
};

export const breachTermsString = [
  "TENANT HAS NOT VACATED",
  "FAILED TO MAKE PAYMENT",
  "BREACH OF OTHER CONDITIONS",
];

export const trialActionsObject = {
  "Request for Warrant of Removal": [
    "Default Judgment", // 0
    "Judgment for Possession", // 1
    "Breach of Consent - Pay and Stay", // 2
    "Breach of Consent - Vacate with Payment Plan", // 3
    "Breach of Consent - Vacate without Payment Plan", // 4
  ],
  "Executed Consent to Enter Judgment": [
    "Tenant Pays and Stays", // 5
    "Tenant Vacates with Payment Plan", // 6
    "Tenant Vacates without Payment Plan", // 7
  ],
  "Marini Hearing Scheduled": [], // 8
  "Bankruptcy Pending": [], // 9
  Other: [], // 10
};

export const trialActionsString = () => {
  const result = [];
  Object.keys(trialActionsObject).forEach((key) => {
    if (trialActionsObject[key].length)
      trialActionsObject[key].forEach((item) => {
        result.push(`${key} - ${item}`);
      });
    else result.push(key);
  });

  return result;
};
