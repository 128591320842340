<template>
  <div class="mx-auto secondary--text">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$emit('close:form')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div style="width: 80%" class="pt-10 pb-4 mx-auto text-left">
      <div class="custom-title pt-8 pb-4">Legal Fees</div>
      <div class="pb-4 font-weight-medium">
        Would you like to include legal fees in your complaint? [NOTE: It is
        your responsibility to ensure the lease allows pass through of legal
        fees.]
      </div>
      <div class="py-4">
        <v-btn
          rounded
          :color="legalFeesPermitted === false ? 'primary' : 'info_light'"
          dark
          class="px-12 capital--btn"
          :class="{ 'secondary--text': legalFeesPermitted !== false }"
          depressed
          @click="optionClicked(false)"
        >
          no
        </v-btn>
        <v-btn
          rounded
          :color="legalFeesPermitted === true ? 'primary' : 'info_light'"
          dark
          class="px-12 ml-4 capital--btn"
          :class="{ 'secondary--text': legalFeesPermitted !== true }"
          depressed
          @click="optionClicked(true)"
        >
          yes
        </v-btn>
      </div>

      <div class="mt-8 pb-4 font-weight-medium">Insert Attorney's Fees</div>
      <v-text-field
        v-model="attorneyFee"
        rounded
        :rules="[required]"
        solo
        flat
        dense
        background-color="#F0F5F6"
        @keydown="handleRentAmountType"
        :disabled="!legalFeesPermitted"
      >
        <div slot="prepend-inner">$</div>
      </v-text-field>
    </div>
    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-end py-4 pr-12 mt-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="back"
      >
        go back
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        :disabled="!valid"
        @click="next"
      >
        save & continue
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LegalFeesPermitted",
  data() {
    return {
      legalFeesPermitted: null,
      attorneyFee: 0,
      required: (
        value //* 0's are fine
      ) => {
        if (value === 0) {
          return true;
        }
        return !!value || "Item is required";
      },
    };
  },
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
      currentProperty: "property/currentProperty",
    }),
    valid() {
      return this.legalFeesPermitted !== null;
    },
  },
  activated() {
    window.scrollTo(0, 0);
    this.legalFeesPermitted = this.currentCase.legalFeesPermitted ?? null; //for guest;;
    const _attorneyFee =
      this.currentCase.legalFees !== null
        ? this.currentCase.legalFees.find(
            (item) => item.type === "Attorney Fee"
          )
        : null;
    this.attorneyFee = _attorneyFee ? _attorneyFee.amount : 0;
  },
  deactivated() {
    this.saveData();
  },
  methods: {
    handleRentAmountType(e) {
      if (
        e.code === "Minus" ||
        (e.keyCode === 187 && e.shiftKey) ||
        e.keyCode === 188
      )
        e.preventDefault();
    },
    async saveData() {
      const legalFees = [
        {
          type: "Filing Fee",
          amount: "50.00",
          selected: this.legalFeesPermitted,
        },
        {
          type: "Mileage Fee",
          amount: "7.00",
          selected: this.legalFeesPermitted,
        },
      ];
      if (this.legalFeesPermitted) {
        legalFees.push({
          type: "Attorney Fee",
          amount: parseFloat(this.attorneyFee).toFixed(2),
          selected: true,
        });
      }
      await this.$store.dispatch("eviction/updateCase", {
        caseData: {
          legalFeesPermitted: this.legalFeesPermitted,
          legalFees: this.legalFeesPermitted ? legalFees : null,
        },
        status: "vc-legalFees",
      });
    },
    next() {
      this.$router.push({ name: "vc-confirmation" });
    },
    optionClicked(bool) {
      this.legalFeesPermitted = bool;
      if (bool) this.attorneyFee = "";
      else this.attorneyFee = 0;
      // this.next();
    },
    back() {
      this.$router.push({
        name: this.currentCase.additionalChargesPermitted
          ? "vc-additional-charges-extended"
          : "vc-additional-charges",
      });
    },
  },
};
</script>
