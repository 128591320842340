<template>
  <v-card
    class="secondary--text mr-4"
    width="100%"
    height="15%"
    style="box-shadow: 15px 15px 40px #00000029; border-radius: 20px"
  >
    <v-card-title class="font-weight-bold mx-4 pt-8">
      <div>Tasks</div>
    </v-card-title>
    <div style="max-height: 270px; overflow: auto">
      <v-container class="pa-0">
        <TaskTable :isCard="true" :_tasks="tasks" />
      </v-container>
    </div>
    <div class="card-action-bottom">
      <v-btn
        color="accent_light"
        class="px-4 white--text"
        rounded
        depressed
        @click="newTaskDialog = true"
      >
        New Task
      </v-btn>
    </div>

    <v-dialog v-model="newTaskDialog" persistent max-width="700">
      <NewTaskDialog
        :fromCaseView="true"
        @close="newTaskDialog = false"
        @saveNewTask="saveNewTask"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import TaskTable from "@components/tables/TaskTable";
import NewTaskDialog from "@/components/dialogs/NewTaskDialog.vue";
import { mapGetters } from "vuex";

export default {
  name: "TasksCard",
  components: {
    TaskTable,
    NewTaskDialog,
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          opacity: 0,
          size: "5px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "6px",
        },
      },
      tasks: [],
      newTaskDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      _tasks: "task/tasks",
      loaded: "task/loaded",
      currentCase: "eviction/currentCase",
    }),
  },
  async created() {
    if (!this.loaded) {
      await this.$store.dispatch("task/loadTasks");
      this.$store.commit("task/set_loaded", true);
    }
    this.tasks = this._tasks.filter(
      (item) => item.caseId === this.currentCase.id
    );
  },
  methods: {
    async saveNewTask(newTask) {
      this.newTaskDialog = false;
      await this.$store.dispatch("task/create", newTask);
    },
  },
};
</script>

<style>
.card-action-bottom {
  padding: 25px 30px;
  background: #f8fafb;
}
</style>
