import Vue from "vue";
import useRequest from "@/utils/useRequest";

export default {
  namespaced: true,
  state: {
    tasks: [],
    loaded: false,
  },
  mutations: {
    set_tasks(state, tasks) {
      Vue.set(state, "tasks", tasks);
    },
    set_loaded(state, loaded) {
      Vue.set(state, "loaded", loaded);
    },
  },
  actions: {
    async loadTasks({ commit }) {
      await useRequest({
        path: `tasks`,
        // showLoading: true,
        onSuccess: (tasks) => commit("set_tasks", tasks),
      });
    },
    async create({ state, commit }, newTask) {
      const { data: res } = await useRequest({
        method: "post",
        path: "tasks",
        data: newTask,
        showLoading: true,
      });
      if (res.success) {
        commit("set_tasks", [res.createdTask, ...state.tasks]);
      }
    },
    async update({ state, commit }, updatedTask) {
      const { data: res } = await useRequest({
        method: "put",
        path: "tasks",
        data: updatedTask,
      });
      if (res.success) {
        const tasks = state.tasks.map((task) => {
          if (task.id === updatedTask.id) return { ...task, ...updatedTask };
          else return task;
        });
        commit("set_tasks", tasks);
      }
    },
  },
  getters: {
    tasks: (state) => state.tasks,
    loaded: (state) => state.loaded,
  },
};
