<template>
  <div>
    <div class="px-16 sticky-header">
      <div class="d-flex mb-8 page-subheader">
        <div class="d-flex align-center">
          <div class="secondary--text custom-heading mr-12">Tasks</div>
          <v-btn
            fab
            depressed
            color="primary"
            height="45"
            width="45"
            @click="newTaskDialog = true"
          >
            <span
              class="white--text font-weight-regular"
              style="font-size: 50px"
              >+</span
            >
          </v-btn>
        </div>

        <div class="d-flex pl-8 mt-4 ml-auto">
          <!-- search start -->
          <v-menu
            v-model="searchMenu"
            :close-on-content-click="false"
            :nudge-width="200"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="px-4 subheader-btn" v-bind="attrs" v-on="on">
                <i class="icofont-search-1" color="grey" />&nbsp;Search
              </v-btn>
            </template>

            <v-card
              style="box-shadow: 15px 15px 40px #00000029; border-radius: 20px"
            >
              <div class="px-8 py-4 table-custom-search">
                <input
                  v-model="search"
                  type="text"
                  placeholder="Search Tasks"
                />
              </div>
            </v-card>
          </v-menu>
          <!-- search end -->

          <!-- export start -->
          <v-btn class="ml-4 px-4 subheader-btn" @click="exportExcel">
            <i class="icofont-external" />&nbsp;Export
          </v-btn>
          <!-- export end -->

          <!-- filter start -->
          <v-menu
            v-model="filterMenu"
            :close-on-content-click="false"
            :nudge-width="530"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-4 px-4 subheader-btn" v-bind="attrs" v-on="on">
                <v-icon size="xx-large" color="primary"
                  >icofont-dotted-down</v-icon
                >&nbsp;Filter By:
              </v-btn>
            </template>

            <v-card
              style="box-shadow: 15px 15px 40px #00000029; border-radius: 20px"
            >
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <strong class="secondary--text">FILTER BY</strong>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list>
                <v-list-item v-for="(item, index) in filters" :key="index">
                  <v-list-item-action>
                    <v-icon @click="removeFilter(index)">mdi-close</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-select
                      v-model="item.filterColumn"
                      :items="item.columns"
                      class="mr-2"
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                      style="max-width: 200px"
                    >
                      <v-icon slot="append" medium color="accent">
                        mdi-chevron-down
                      </v-icon>
                    </v-select>
                    <v-select
                      v-model="item.filterOperator"
                      :items="item.operators"
                      class="mr-2"
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                      style="max-width: 150px"
                    >
                      <v-icon slot="append" medium color="accent">
                        mdi-chevron-down
                      </v-icon>
                    </v-select>
                    <v-menu
                      v-if="item.filterColumn === 'DATE DUE'"
                      v-model="dateDueMenu"
                      :close-on-content-click="false"
                      offset-x
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="
                            item.filterValue
                              ? $dayjs(item.filterValue).format('MM/DD/YYYY')
                              : $dayjs().format('MM/DD/YYYY')
                          "
                          v-bind="attrs"
                          v-on="on"
                          append-icon="mdi-calendar"
                          class="mr-4"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.filterValue"
                        no-title
                        @input="dateDueMenu = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-select
                      v-else
                      v-model="item.filterValue"
                      :items="item.values"
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                      style="max-width: 200px"
                    >
                      <v-icon slot="append" medium color="accent">
                        mdi-chevron-down
                      </v-icon>
                    </v-select>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-card-actions>
                <v-btn
                  class="btn--plain ml-12"
                  color="secondary"
                  icon
                  @click="addFilter"
                >
                  <v-icon class="mr-2" color="accent" style="font-size: 28px"
                    >mdi-plus-circle-outline</v-icon
                  >
                  Add Filter
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-badge v-if="filterCount > 0" color="primary" overlap>
            <span slot="badge" style="font-size: 17px; font-weight: bold">{{
              filterCount
            }}</span>
          </v-badge>
          <!-- filter end -->
        </div>
      </div>
    </div>

    <TaskTable
      :search="search"
      :filters="filters"
      :_tasks="tasks"
      ref="taskTable"
    />

    <v-dialog v-model="newTaskDialog" persistent max-width="700">
      <NewTaskDialog
        @close="newTaskDialog = false"
        @saveNewTask="saveNewTask"
      />
    </v-dialog>
  </div>
</template>

<script>
import TaskTable from "@components/tables/TaskTable";
import NewTaskDialog from "@/components/dialogs/NewTaskDialog.vue";
import { mapGetters } from "vuex";

export default {
  name: "Tasks",
  components: {
    TaskTable,
    NewTaskDialog,
  },
  data() {
    return {
      search: "",
      searchMenu: false,
      filters: [
        {
          columns: [
            "STATUS",
            "ATTORNEY",
            "CLIENT",
            "PARTIES",
            "CASE NO.",
            "SUBJECT",
            "DETAILS",
            "DATE DUE",
          ],
          operators: [],
          values: [],
          filterColumn: "",
          filterOperator: "",
          filterValue: "",
        },
      ],
      filterMenu: false,
      tableData: [],
      newTaskDialog: false,
      dateDueMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      tasks: "task/tasks",
      loaded: "task/loaded",
      currentCase: "eviction/currentCase",
    }),
    filterColumns() {
      return this.filters.map((item) => item.filterColumn);
    },
    filterCount() {
      return this.filters.filter(
        (item) => item.filterColumn && item.filterOperator && item.filterValue
      ).length;
    },
  },
  watch: {
    filterColumns(newVal, oldVal) {
      if (newVal.length !== oldVal.length) return;
      const diffItem = newVal.find((item, index) => item !== oldVal[index]);
      const diffIndex = newVal.indexOf(diffItem);
      if (this.filters[diffIndex].filterColumn === "DATE DUE") {
        this.filters[diffIndex].operators = [
          "On or Before",
          "On or After",
          "On",
        ];
        this.filters[diffIndex].filterValue = null;
      } else {
        this.filters[diffIndex].operators = ["Equal To"];
        this.filters[diffIndex].filterOperator = "Equal To";
        this.filters[diffIndex].filterValue = "";
        this.filters[diffIndex].values = this.tableData.map(
          (item) => item[this.filters[diffIndex].filterColumn]
        );
      }
    },
    tasks(val) {
      this.tableData = [];
      val.map((item) => {
        this.tableData.push({
          STATUS: item.status,
          ATTORNEY: item.attorneyName,
          CLIENT: item.clientName,
          PARTIES: item.caseParties,
          "CASE NO.": item.docketNo ?? "LT-",
          SUBJECT: item.subject,
          DETAILS: item.description,
          "DATE DUE": item.dateDue,
        });
      });
    },
  },
  async created() {
    if (!this.loaded) {
      await this.$store.dispatch("task/loadTasks");
      this.$store.commit("task/set_loaded", true);
    }
    this.tasks.map((item) => {
      this.tableData.push({
        STATUS: item.status,
        ATTORNEY: item.attorneyName,
        CLIENT: item.clientName,
        PARTIES: item.caseParties,
        "CASE NO.": item.docketNo ?? "LT-",
        SUBJECT: item.subject,
        DETAILS: item.description,
        "DATE DUE": item.dateDue,
      });
    });
  },
  methods: {
    async saveNewTask(newTask) {
      this.newTaskDialog = false;
      await this.$store.dispatch("task/create", newTask);
    },
    exportExcel() {
      this.$refs.taskTable.exportExcel();
    },
    removeFilter(index) {
      this.filters.splice(index, 1);
    },
    addFilter() {
      this.filters.push({
        columns: [
          "STATUS",
          "ATTORNEY",
          "CLIENT",
          "PARTIES",
          "CASE NO.",
          "SUBJECT",
          "DETAILS",
          "DATE DUE",
        ],
        operators: [],
        values: [],
        filterColumn: "",
        filterOperator: "",
        filterValue: "",
      });
    },
  },
};
</script>

<style scoped>
.v-menu__content {
  background-color: white;
  margin-top: 40px;
  border-radius: 15px;
  box-shadow: 15px 15px 40px #00000029 !important;
  position: absolute;
  top: 5px;
}
.v-select >>> .v-text-field__details {
  display: none;
}
.tasks-progress {
  width: 450px;
  border-radius: 10px;
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
}
.btn-color-mode-switch > label.btn-color-mode-switch-inner {
  margin: 0px;
  width: 300px;
  height: 50px;
  background: #fff;
  border-radius: 30px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  display: block;
  box-shadow: 15px 15px 40px #00000029;
}
.btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  color: #003a60;
  font-size: 20px;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
  right: 40px;
}
.btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
  content: attr(data-off);
  width: 150px;
  height: 50px;
  line-height: 50px;
  color: white;
  font-weight: bold;
  background: #4dccc4;
  border-radius: 30px;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease;
}
.btn-color-mode-switch input[type="checkbox"] {
  cursor: pointer;
  width: 300px;
  height: 50px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}
.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner {
  background: #fff;
  color: #003a60;
  font-weight: bold;
  text-align: center;
  height: 50px;
  line-height: 50px;
}
.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: 150px;
}
.btn-color-mode-switch
  input[type="checkbox"]:checked
  + label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  left: 50px;
}
</style>
