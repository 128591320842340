import Vue from "vue";
import useRequest from "@/utils/useRequest";

export default {
  namespaced: true,
  state: {
    events: [],
    tags: [],
    tagsLoaded: false,
  },
  mutations: {
    set_events(state, events) {
      Vue.set(state, "events", events);
    },
    set_tags(state, tags) {
      Vue.set(state, "tags", tags);
    },
    set_tagsLoaded(state, tagsLoaded) {
      Vue.set(state, "tagsLoaded", tagsLoaded);
    },
  },
  actions: {
    async loadEvents({ commit }, dateRange) {
      await useRequest({
        path: `events?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`,
        // showLoading: true,
        onSuccess: (events) => commit("set_events", events),
      });
    },
    async saveEvent({ commit }, { eventData, eventId = null, isNewEvent }) {
      await useRequest({
        method: "post",
        path: "events",
        showLoading: true,
        data: {
          eventData,
          eventId,
          isNewEvent,
        },
      });
    },
    async deleteEvent({ commit }, eventId) {
      await useRequest({
        method: "delete",
        path: "events",
        data: {
          eventId,
        },
      });
    },
    async loadTags({ commit }) {
      await useRequest({
        path: "tags",
        onSuccess: (tags) => commit("set_tags", tags),
      });
    },
    async saveNewTag({ state, commit }, newTag) {
      const { data: res } = await useRequest({
        method: "post",
        path: "tags",
        data: newTag,
        showLoading: true,
      });
      if (res.success) {
        commit("set_tags", [
          ...state.tags.slice(0, state.tags.length - 1),
          { ...newTag, id: res.addedId },
        ]);
      }
    },
    async updateTag({ state, commit }, updatedTag) {
      const { data: res } = await useRequest({
        method: "put",
        path: "tags",
        data: { updatedTag },
        showLoading: true,
      });
      if (res.success) {
        const tags = state.tags;
        const curTag = tags.find((item) => item.id === updatedTag.id);
        tags[state.tags.indexOf(curTag)] = updatedTag;
        commit("set_tags", tags);
      }
    },
    async deleteTag({ state, commit }, tagId) {
      const { data: res } = await useRequest({
        method: "delete",
        path: "tags",
        data: { tagId },
      });
      if (res.success) {
        commit(
          "set_tags",
          state.tags.filter((item) => item.id !== tagId)
        );
      }
    },
  },
  getters: {
    events: (state) => state.events,
    tags: (state) => state.tags,
    tagsLoaded: (state) => state.tagsLoaded,
  },
};
