<template>
  <div class="mx-auto secondary--text">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$router.push({ name: 'case-view-overview' })"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div style="width: 80%" class="pt-15 pb-4 mx-auto text-left">
      <div class="custom-title pb-4">Case Management Outcome</div>
      <div class="pb-4 mb-8">
        Let us know what the outcome of your Case Management Conference was so
        that we may better help you with the next steps.
      </div>
      <div
        class="py-4"
        style="
          height: 370px;
          border: 1px solid #c3d1d2;
          border-radius: 20px;
          position: relative;
        "
      >
        <vue-scroll :ops="ops">
          <v-menu
            v-show="!loading"
            v-for="key in keys"
            :key="key"
            :close-on-content-click="true"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-group v-model="selectedKey">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                  class="pl-8 py-1 secondary--text list"
                  :class="key === selectedKey ? 'font-weight-medium' : ''"
                  :value="key"
                >
                  {{
                    key === selectedOutcome.split("-")[0].trim()
                      ? selectedOutcome
                      : key
                  }}
                  <v-icon
                    v-if="courtOutcomesObject[key].length"
                    class="ml-auto"
                    color="accent"
                    size="xx-large"
                  >
                    mdi-menu-right
                  </v-icon>
                </v-list-item>
              </v-list-item-group>
            </template>

            <v-list
              v-if="courtOutcomesObject[key].length"
              class="secondary--text"
            >
              <v-radio-group v-model="selectedOutcome">
                <v-list-item
                  class="secondary--text pl-8"
                  v-for="value in courtOutcomesObject[key]"
                  :key="value"
                >
                  <v-radio :label="value" :value="key + ' - ' + value" />
                </v-list-item>
              </v-radio-group>
            </v-list>
          </v-menu>
        </vue-scroll>
      </div>
    </div>

    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-end py-4 pr-12 mt-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="cancel"
      >
        Cancel
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        :disabled="!selectedOutcome"
        @click="next"
      >
        Save & Continue
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="ComplicateDialogOpen" max-width="600">
      <ComplicateDialog @endFlow="cancel" />
    </v-dialog>
  </div>
</template>

<script>
import ComplicateDialog from "@/components/dialogs/ComplicateDialog";
import {
  courtOutcomesObject,
  courtOutcomesString,
  breachTermsString,
} from "@/utils/worFlowData";
import { mapGetters } from "vuex";

export default {
  name: "CourtOutcome",
  components: {
    ComplicateDialog,
  },
  data() {
    return {
      courtOutcomesObject,
      selectedKey: "",
      selectedOutcome: "",
      loading: false,
      ComplicateDialogOpen: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          opacity: 0,
          size: "8px",
          specifyBorderRadius: false,
          gutterOfEnds: "10px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "8px",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
    }),
    keys() {
      return Object.keys(this.courtOutcomesObject);
    },
  },
  activated() {
    window.scrollTo(0, 0);
    if (this.currentCase.courtOutcome) {
      this.selectedOutcome = this.currentCase.courtOutcome;
      this.selectedKey = this.selectedOutcome.split("-")[0].trim();
    }
  },
  deactivated() {
    if (this.selectedOutcome !== courtOutcomesString()[0]) this.saveData();
  },
  methods: {
    async saveData() {
      const caseData = {
        courtOutcome: this.selectedOutcome,
      };
      if (caseData.courtOutcome !== this.currentCase.courtOutcome) {
        if (caseData.courtOutcome === courtOutcomesString()[3])
          // Executed Consent to Enter Judgment - Tenant Vacates with Payment Plan
          caseData.breachTerms = [
            { desc: breachTermsString[1], selected: false },
            { desc: breachTermsString[2], selected: false },
          ];
        else
          caseData.breachTerms = [
            { desc: breachTermsString[0], selected: false },
            { desc: breachTermsString[1], selected: false },
            { desc: breachTermsString[2], selected: false },
          ];
        caseData.breachPayments = [];
        caseData.breachOtherConds = [];
      }

      await this.$store.dispatch("eviction/updateCase", {
        caseData,
        status: "courtOutcome",
      });
    },
    async next() {
      switch (this.selectedOutcome) {
        case courtOutcomesString()[0]: // Case Scheduled for Trial - Did not reach an agreement
          await this.saveData();
          this.$router.push({ name: "case-view-overview" });
          break;
        case courtOutcomesString()[1]: // Case Scheduled for Trial - Defendants didn't show up
          this.$router.push({ name: "wor-trial" });
          break;
        case courtOutcomesString()[2]: // Executed Consent to Enter Judgment - Tenant Pays and Stays
        case courtOutcomesString()[3]: // Executed Consent to Enter Judgment - Tenant Vacates with Payment Plan
        case courtOutcomesString()[4]: // Executed Consent to Enter Judgment - Tenant Vacates without Payment Plan
          this.$router.push({ name: "wor-filed-consent-judgment" });
          break;
        case courtOutcomesString()[5]: // Executed Consent to Enter Judgment - Pending Judge Approval
        case courtOutcomesString()[6]: // Other - Marini Hearing Scheduled
        case courtOutcomesString()[7]: // Other - Bankruptcy Pending
          this.ComplicateDialogOpen = true;
          break;
      }
    },
    cancel() {
      this.$router.push({ name: "case-view-overview" });
    },
  },
};
</script>

<style scoped>
.v-menu__content:empty {
  box-shadow: none;
}
.v-menu__content {
  min-width: 400px !important;
}
</style>
