<template>
  <div class="custom-data-table">
    <v-data-table
      v-if="cases.length > 0"
      :headers="headers"
      :header-props="{ 'sort-icon': 'mdi-menu-up' }"
      :must-sort="true"
      :items="cases"
      :search="search"
      :custom-filter="customFilter"
      item-key="ID"
      :items-per-page="itemsPerPage"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-skip-previous',
        lastIcon: 'mdi-skip-next',
        prevIcon: 'mdi-menu-left',
        nextIcon: 'mdi-menu-right',
        itemsPerPageText: 'Number of cases to display',
        pageText: pageText,
      }"
      @page-count="pageCount = $event"
      @update:options="updateOptions"
      @current-items="getFilteredCases"
    >
      <template v-slot:item="{ item, index }">
        <tr
          @mouseover="rowHoverIndex = index"
          @mouseleave="rowHoverIndex = -1"
          :class="{ 'on-hover': rowHoverIndex === index }"
          class="data-row"
          :style="item.isDeleted === 'Closed' ? 'color: grey !important' : ''"
        >
          <td class="first-column">
            <div
              class="ml-4"
              :class="{ 'accent--text': item.isDeleted === 'Open' }"
              @click="caseOverview(item)"
              style="cursor: pointer; text-transform: uppercase"
            >
              {{
                `${item.attorneyInfo.firstName.slice(
                  0,
                  3
                )}-${item.clientInfo.firstName.slice(0, 3)}-${
                  item.id
                }`.toUpperCase()
              }}
            </div>
          </td>
          <td>{{ $dayjs(item.created_at).format("MM/DD/YYYY") }}</td>
          <td>
            {{ item.attorneyInfo.firstName }} {{ item.attorneyInfo.lastName }}
          </td>
          <td>
            {{ item.clientInfo.firstName }} {{ item.clientInfo.lastName }}
          </td>
          <td>
            <span>{{ item.deedNames }} vs.</span>
            <span v-if="item.tenants.length">
              <span v-if="expandTenants && selectedItem.id === item.id">
                <span v-for="(tenant, index) in item.tenants" :key="index">
                  {{ tenant.name }}
                </span>
                <v-btn
                  v-if="item.tenants.length > 2"
                  text
                  color="accent"
                  class="font-weight-regular btn--plain pa-0 ma-0"
                  style="height: 20px; min-width: 0"
                  @click="hideTenantList(item)"
                >
                  less
                </v-btn>
              </span>
              <span v-else>
                <span
                  v-for="(tenant, index) in item.tenants.slice(0, 2)"
                  :key="index"
                >
                  {{ tenant.name }}
                </span>
                <v-btn
                  v-if="item.tenants.length > 2"
                  text
                  color="accent"
                  class="font-weight-regular btn--plain pa-0 ma-0"
                  style="height: 20px; min-width: 0"
                  @click="showTenantList(item)"
                >
                  more
                </v-btn>
              </span>
            </span>
            <span v-else> Pending</span>
          </td>
          <td>{{ item.county }}</td>
          <td>{{ item.docketNo ? item.docketNo : "LT-" }}</td>
          <td>
            <v-select
              v-model="item.isDeleted"
              class="mt-6"
              background-color="#F0F5F6"
              item-color="#F6F9FA"
              rounded
              solo
              flat
              dense
              :items="statusList"
              @change="updateCaseStatus(item)"
            />
          </td>
          <td style="width: 10px">
            <v-menu offset-y bottom right open-on-hover content-class="mt-n1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="primary" class="btn--plain" v-on="on">
                  <v-icon large>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item @click="caseOverview(item)">
                  <v-list-item-title class="overline info--text">
                    <i
                      style="font-size: 20px"
                      :class="`icofont-search-folder pr-4`"
                    />
                    Case Overview
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="matterManagement(item)">
                  <v-list-item-title class="overline info--text">
                    <i
                      style="font-size: 20px"
                      :class="`icofont-copy-invert pr-4`"
                    />
                    Matter Management
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="caseLedger(item)">
                  <v-list-item-title class="overline info--text">
                    <i style="font-size: 20px" :class="`icofont-coins pr-4`" />
                    Ledger
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="editCase(item)">
                  <v-list-item-title class="overline info--text">
                    <i
                      style="font-size: 20px"
                      :class="`icofont-pencil-alt-2 pr-4`"
                    />
                    Edit Flow
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="goToTasks(item)">
                  <v-list-item-title class="overline info--text">
                    <i style="font-size: 20px" :class="`icofont-tasks pr-4`" />
                    Tasks
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="goToMessages(item)">
                  <v-list-item-title class="overline info--text">
                    <i style="font-size: 20px" :class="`icofont-email pr-4`" />
                    Messages
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="goToCalendar(item)">
                  <v-list-item-title class="overline info--text">
                    <i
                      style="font-size: 20px"
                      :class="`icofont-calendar pr-4`"
                    />
                    Calendar
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteCase(item)">
                  <v-list-item-title class="overline info--text">
                    <i
                      style="font-size: 20px"
                      :class="`icofont-close-circled pr-4`"
                    />
                    Delete
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
        <td :colspan="headers.length" class="row-divider"></td>
      </template>
      <template v-if="cases.length !== 0" slot="body.append">
        <tr style="height: 30px"></tr>
      </template>
    </v-data-table>

    <div
      v-if="_cases.length === 0"
      class="d-flex justify-center align-center flex-column no-data-table-inner pa-12"
    >
      <i
        class="icofont-ui-folder primary--text"
        style="font-size: 5rem; line-height: 1"
      />
      <h5 class="secondary--text custom-subtitle">Begin your first case.</h5>
      <p class="slim--text">
        Looks like you have not initiated an eviction. Start drafting your
        pleadings. All of your case records will appear right here.
      </p>
      <v-btn
        rounded
        color="primary"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        @click="startNewCase"
      >
        Start Eviction
      </v-btn>
    </div>

    <div
      v-if="_cases.length !== 0 && cases.length === 0"
      class="d-flex justify-center align-center flex-column no-data-table-inner pa-12"
    >
      <h5 class="secondary--text custom-subtitle">
        No cases match the filter.
      </h5>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { combineTenants } from "@/utils";
import useRequest from "@/utils/useRequest";

export default {
  name: "EvictionTable",
  props: {
    search: {
      type: String,
      default: "",
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedItem: {},
      headers: [
        {
          text: "Case ID",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "13%",
          sortable: false,
        },
        {
          text: "Date Added",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Attorney",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "12%",
          sortable: false,
        },
        {
          text: "Client",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "12%",
          sortable: false,
        },
        {
          text: "Parties",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "250",
          sortable: false,
        },
        {
          text: "County",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Docket No.",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "10%",
          sortable: false,
        },
        {
          text: "Status",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "12%",
          sortable: false,
        },
        {
          class: "header-text secondary--text font-weight-medium",
          align: "center",
          sortable: false,
        },
      ],
      expandTenants: false,
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      rowHoverIndex: false,
      statusList: ["Open", "Closed"],
      cases: [],
      filteredCases: [],
    };
  },
  computed: {
    ...mapGetters({
      _cases: "eviction/cases",
    }),
    pageText() {
      return "Pages " + this.page + ` / ` + this.pageCount;
    },
  },
  watch: {
    filters: {
      handler(val) {
        if (!val.length) {
          this.cases = this._cases;
          return;
        }
        const _filters = val.filter(
          (item) => item.filterColumn && item.filterOperator && item.filterValue
        );
        if (_filters.length) {
          let _filteredCases = this._cases;
          _filters.map((item) => {
            if (item.filterColumn == "DATE ADDED") {
              switch (item.filterOperator) {
                case "On or Before":
                  _filteredCases = _filteredCases.filter(
                    (caseItem) =>
                      this.$dayjs(caseItem.created_at).format("YYYY-MM-DD") <=
                      item.filterValue
                  );
                  break;
                case "On or After":
                  _filteredCases = _filteredCases.filter(
                    (caseItem) =>
                      this.$dayjs(caseItem.created_at).format("YYYY-MM-DD") >=
                      item.filterValue
                  );
                  break;
                case "On":
                  _filteredCases = _filteredCases.filter(
                    (caseItem) =>
                      this.$dayjs(caseItem.created_at).format("YYYY-MM-DD") ==
                      item.filterValue
                  );
                  break;
              }
            } else {
              _filteredCases = _filteredCases.filter((caseItem) => {
                switch (item.filterColumn) {
                  case "CASE ID":
                    return (
                      `${caseItem.attorneyInfo.firstName.slice(
                        0,
                        3
                      )}-${caseItem.clientInfo.firstName.slice(0, 3)}-${
                        caseItem.id
                      }`.toUpperCase() == item.filterValue
                    );
                  case "ATTORNEY":
                    return (
                      `${caseItem.attorneyInfo.firstName} ${caseItem.attorneyInfo.lastName}` ==
                      item.filterValue
                    );
                  case "CLIENT":
                    return (
                      `${caseItem.clientInfo.firstName} ${caseItem.clientInfo.lastName}` ==
                      item.filterValue
                    );
                  case "PARTIES":
                    return `${caseItem.deedNames ?? "Pending"} vs. ` + (combineTenants(caseItem.tenants) ?? "Pending") == item.filterValue;
                  case "COUNTY":
                    return caseItem.county == item.filterValue;
                  case "DOCKET NO.":
                    return (caseItem.docketNo ?? "LT-") == item.filterValue;
                  case "STATUS":
                    return caseItem.isDeleted == item.filterValue;
                }
              });
            }
          });
          this.cases = _filteredCases;
        }
      },
      deep: true,
    },
  },
  async created() {
    this.cases = this._cases;
  },
  methods: {
    startNewCase() {
      this.$router.push("verified-complaint/new");
      this.$store.commit("eviction/init_steps");
      this.$store.commit("eviction/init_case");
      this.$store.commit("property/init_property");
      this.$store.commit("eviction/set_current_client_id", 0);
    },
    getFilteredCases(filteredCases) {
      this.filteredCases = filteredCases;
    },
    exportExcel() {
      const headersText = this.headers.slice(0, -1).map((item) => item.text);
      let csv_string = headersText.join(", ") + "\n";
      this.filteredCases.forEach((item) => {
        csv_string += `${item.attorneyInfo.firstName.slice(
          0,
          3
        )}-${item.clientInfo.firstName.slice(0, 3)}-${item.id}`.toUpperCase();
        csv_string += `,${this.$dayjs(item.created_at).format("DD/MM/YYYY")}`;
        csv_string += `,${item.attorneyInfo.firstName} ${item.attorneyInfo.lastName}`;
        csv_string += `,${item.clientInfo.firstName} ${item.clientInfo.lastName}`;
        csv_string += `,${item.deedNames ?? "Pending"} vs. ` + (combineTenants(item.tenants) ?? "Pending");
        csv_string += `,${item.county}`;
        csv_string += `,${item.docketNo ?? "LT-"}`;
        csv_string += `,${item.isDeleted}`;
        csv_string += "\n";
      });

      const link = document.createElement("a");
      link.style.display = "none";
      link.setAttribute("target", "_blank");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
      );
      link.setAttribute("download", "export_cases.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    customFilter(_value, search, items) {
      const isExistTenants =
        items.tenants.length > 0
          ? !(
              Object.keys(items.tenants)
                .map((key) => {
                  const item = items.tenants[key];
                  return item.name !== null
                    ? item.name
                        .toString()
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    : false;
                })
                .filter((bool) => !bool).length > 0
            )
          : false;

      return (
        (items.deedNames !== null &&
          items.deedNames
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (items.attorneyInfo !== null &&
          (
            items.attorneyInfo.firstName.toString() +
            " " +
            items.attorneyInfo.lastName.toString()
          )
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (items.clientInfo !== null &&
          (
            items.clientInfo.firstName.toString() +
            " " +
            items.clientInfo.lastName.toString()
          )
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (items.created_at !== null &&
          this.$dayjs(items.created_at)
            .format("DD/MM/YYYY")
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (items.county !== null &&
          items.county
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (items.docketNo !== null &&
          items.docketNo
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase())) ||
        (items.id !== null &&
          items.id.toString().toLowerCase().includes(search.toLowerCase())) ||
        isExistTenants
      );
    },
    updateOptions(option) {
      this.page = option.page;
    },
    async caseOverview(item) {
      await this.$store.dispatch("eviction/loadCase", {
        caseId: item.id,
        logMessage: "EvictionTable.vue at line 497",
        forceLoad: true,
      });
      this.$router.push(`case-view/${item.id}/overview`);
    },
    async matterManagement(item) {
      await this.$store.dispatch("eviction/loadCase", {
        caseId: item.id,
        logMessage: "EvictionTable.vue at line 497",
        forceLoad: true,
      });
      this.$router.push(`case-view/${item.id}/matter-management`);
    },
    async caseLedger(item) {
      await this.$store.dispatch("eviction/loadCase", {
        caseId: item.id,
        logMessage: "EvictionTable.vue at line 497",
        forceLoad: true,
      });
      this.$router.push(`case-view/${item.id}/ledger`);
    },
    async editCase(item) {
      if (item.isDeleted === "Closed") return;
      await this.$store.dispatch("eviction/loadCase", {
        caseId: item.id,
        logMessage: "EvictionTable.vue at line 581",
        forceLoad: true,
      });
      this.$store.dispatch("eviction/editCase");
    },
    goToTasks(item) {
      this.$store.commit("eviction/set_current_case", {
        ...item,
        creatingTask: true,
      });
      this.$router.push("tasks");
    },
    goToMessages(item) {
      this.$store.commit("eviction/set_current_case", {
        ...item,
        creatingMessage: true,
      });
      this.$router.push("messages");
    },
    goToCalendar(item) {
      this.$store.commit("eviction/set_current_case", {
        ...item,
        creatingEvent: true,
      });
      this.$router.push("calendar");
    },
    async deleteCase(item) {
      useRequest({
        path: `api/case/${item.id}?clientId=${item.clientId}`,
        method: "DELETE",
        showLoading: true,
      }).then(async () => {
        await this.$store.dispatch("eviction/loadCases");
        this.cases = this._cases;
      });
    },
    hideTenantList(item) {
      this.selectedItem = item;
      this.expandTenants = false;
    },
    showTenantList(item) {
      this.selectedItem = item;
      this.expandTenants = true;
    },
    async updateCaseStatus(caseItem) {
      this.$store.commit("eviction/set_current_client_id", caseItem.clientId);
      this.$store.commit("eviction/set_current_case", caseItem);
      await this.$store.dispatch("eviction/updateCase", {
        caseData: {
          isDeleted: caseItem.isDeleted === "Open" ? 0 : 1,
        },
      });
    },
  },
};
</script>

<style scoped>
.data-row {
  color: #003a60 !important;
  height: 130px !important;
}
.data-row > td {
  border-bottom: none !important;
}
.data-row:hover {
  background-color: transparent !important;
}
.first-column {
  border-left: 8px solid transparent;
}
.on-hover > .first-column {
  border-left: 8px solid #4dccc4;
}
.row-divider {
  height: 1px !important;
  background: #c3d1d2 !important;
}
.status-row {
  background-color: #f8fafb !important;
  color: #003a60 !important;
}
.status-grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  align-items: center;
  height: 65px !important;
  padding-right: 10px;
}
</style>
