<template>
  <v-row class="ma-0">
    <v-col cols="4" class="pa-12 left-panel">
      <v-btn
        text
        class="capital--btn btn--plain mb-8"
        @click="$router.push('/')"
      >
        <img src="/images/logo-white.png" height="50" alt="logo" />
      </v-btn>
      <div class="mt-16 pl-4">
        <p style="font-size: 1.5rem; color: white !important">
          Step {{ curStep in [1, 2, 8] ? curStep : 2 }}
        </p>
        <p style="color: white !important">
          {{
            curStep in [1, 2, 8]
              ? steps[curStep - 1].description
              : steps[1].description
          }}
        </p>
        <div class="mt-16">
          <v-timeline dense>
            <v-timeline-item
              v-for="(step, index) in steps"
              :key="index"
              large
              color="#003a60"
              class="align-center"
              :class="
                (curStep < 3 && index + 1 == curStep) ||
                (index == 2 && curStep == 8) ||
                (index == 1 && curStep > 2 && curStep < 8)
                  ? 'active'
                  : ''
              "
              :style="`color: ${
                (curStep < 3 && index + 1 == curStep) ||
                (index == 2 && curStep == 8) ||
                (index == 1 && curStep > 2 && curStep < 8)
                  ? 'white'
                  : 'rgb(75 115 143)'
              }; padding-bottom: ${index + 1 == steps.length ? '0' : ''}`"
            >
              <template v-slot:icon>
                {{ index + 1 }}
              </template>
              <span class="ml-8" style="font-size: 1.2rem; font-weight: 400">{{
                step.title
              }}</span>
            </v-timeline-item>
          </v-timeline>
        </div>
      </div>
    </v-col>
    <v-col cols="8" class="pt-16 px-16 secondary--text">
      <ProfileInformation v-if="curStep === 1" @updateStep="updateStep" />
      <ImportClientIndex v-if="curStep === 2" @updateStep="updateStep" />
      <div v-if="curStep > 2 && curStep < 8" class="mx-12">
        <p class="mt-8 font-weight-medium" style="font-size: 1.7rem">
          Import from Spreadsheet
        </p>
        <p>{{ importClientDescs[curStep - 4] }}</p>
        <v-card class="secondary--text mt-16 pa-16 rounded-card">
          <v-breadcrumbs
            :items="breadcrumbsItems"
            large
            class="font-weight-medium"
          >
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                style="font-size: 22px"
                :disabled="breadcrumbsItems.indexOf(item) + 3 > curStep"
              >
                <v-icon
                  :color="
                    breadcrumbsItems.indexOf(item) + 3 > curStep
                      ? 'info'
                      : 'primary'
                  "
                  style="font-size: 25px"
                  >{{
                    breadcrumbsItems.indexOf(item) + 3 > curStep
                      ? "mdi-circle-outline"
                      : "mdi-check-circle"
                  }}</v-icon
                >
                &nbsp;&nbsp;{{ item.text }}
              </v-breadcrumbs-item>
            </template>
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
          <ImportPrepareFile v-if="curStep === 3" @updateStep="updateStep" />
          <ImportUploadFile
            v-if="curStep === 4"
            @uploaded="fileUploaded"
            @updateStep="updateStep"
          />
          <ImportColumnMapping
            v-if="curStep === 5"
            :excelHeaders="excelHeaders"
            @mapColumn="mapColumn"
            @updateStep="updateStep"
          />
          <ImportLoading
            v-if="curStep === 6"
            :excelData="excelData"
            @updateStep="updateStep"
          />
          <ImportComplete
            v-if="curStep === 7"
            :importCnt="importCnt"
            @updateStep="updateStep"
          />
        </v-card>
      </div>
      <Complete v-if="curStep === 8" @updateStep="updateStep" />
    </v-col>
  </v-row>
</template>

<script>
import ProfileInformation from "../subpages/profile/ProfileInformation.vue";
import ImportClientIndex from "../subpages/profile/ImportClientIndex.vue";
import ImportPrepareFile from "../subpages/profile/ImportPrepareFile.vue";
import ImportUploadFile from "../subpages/profile/ImportUploadFile.vue";
import ImportColumnMapping from "../subpages/profile/ImportColumnMapping.vue";
import ImportLoading from "../subpages/profile/ImportLoading.vue";
import ImportComplete from "../subpages/profile/ImportComplete.vue";
import Complete from "../subpages/profile/Complete.vue";

export default {
  name: "Profile",
  components: {
    ProfileInformation,
    // Email,
    ImportClientIndex,
    ImportPrepareFile,
    ImportUploadFile,
    ImportColumnMapping,
    ImportLoading,
    ImportComplete,
    Complete,
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "Prepare File",
          href: "prepare_file",
        },
        {
          text: "Upload File",
          href: "upload_file",
        },
        {
          text: "Column Mapping",
          href: "",
        },
        {
          text: "Import",
          href: "",
        },
        {
          text: "Complete",
          href: "",
        },
      ],
      steps: [
        {
          title: "Profile Information",
          description:
            "Specify the Firm's details and the contact information of the attorneys potentially working on evictions cases.",
        },
        {
          title: "Client Import",
          description: "Import all your clients in one go.",
        },
        {
          title: "Complete",
          description: "All Done!",
        },
      ],
      importClientDescs: [
        "You can either open a blank excel spreadsheet or opt to download the sample spreadsheet we provide, and fill in the columns accordingly.",
        "Locate and upload the spreadsheet that you pre-populated with your list of clients and their contact information.",
        "If you did not use the sample spreadsheet, you need to manually map the column headers on your spreadsheet to the column headers below.",
        "Hold on while the system processes your spreadsheet.",
        "",
      ],
      curStep: 1,
      excelData: [],
      excelHeaders: [],
    };
  },
  computed: {
    importCnt() {
      return this.excelData.length;
    },
  },
  methods: {
    updateStep(direction) {
      if (direction === "next") {
        this.curStep++;
      }
      if (direction === "back") {
        this.curStep--;
      }
      if (direction === "end") {
        this.curStep = 8;
      }
      if (direction === "back-from-end") {
        this.curStep -= 6;
      }
    },
    fileUploaded(data) {
      this.excelData = JSON.parse(data);
      this.excelHeaders = Object.keys(this.excelData[0]);
    },
    mapColumn(columnList) {
      const data = structuredClone(this.excelData);
      for (let k = 0; k < data.length; k++) {
        for (const column of columnList) {
          data[k][column.key] =
            this.excelData[k][column.matching];
        }
      }
      this.excelData = structuredClone(data);
    },
  },
};
</script>

<style scoped>
.left-panel {
  background-color: #003a60;
  min-height: 100vh;
  height: auto;
  color: white;
  overflow: auto;
}
.v-timeline {
  padding-top: 0;
}
.v-timeline-item {
  padding-bottom: 48px;
}
.theme--light.v-timeline::before {
  background: rgb(75 115 143);
}
.theme--light.v-timeline .v-timeline-item__dot {
  background: rgb(70, 192, 188);
}
.theme--light.v-timeline .active >>> .v-timeline-item__dot {
  color: rgb(77, 204, 196);
  background: rgb(77, 204, 196);
}
.theme--light.v-timeline >>> .v-timeline-item__dot {
  background: rgb(75 115 143);
}
.theme--light.v-timeline
  >>> .v-timeline-item__dot--large
  .v-timeline-item__inner-dot {
  height: 48px;
  margin: 2px;
  width: 48px;
}
</style>
