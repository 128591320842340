import useRequest from "@/utils/useRequest";
import { combineTenants } from "@/utils";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    invoices: [],
    loaded: false,
  },
  mutations: {
    set_invoices(state, invoices) {
      Vue.set(state, "invoices", invoices);
    },
    set_loaded(state, loaded) {
      Vue.set(state, "loaded", loaded);
    },
  },
  actions: {
    async loadInvoices({ commit }) {
      await useRequest({
        path: "invoices",
        onSuccess: (invoices) => commit("set_invoices", invoices),
      });
    },
    async saveInvoice({ state, commit }, invoice) {
      const invoiceNo = state.invoices.length ? Math.max(...state.invoices.map(item => item.invoiceNo)) + 1 : 1;
      const { data: invoices } = await useRequest({
        method: "post",
        path: "invoices",
        data: {
          invoiceNo,
          ...invoice
        },
        showLoading: true,
      });
      commit("set_invoices", invoices);
    },
    async savePayment({ commit, dispatch, rootGetters }, payment) {
      const { data: invoices } = await useRequest({
        method: "post",
        path: "invoice-payment",
        data: payment,
      });
      commit("set_invoices", invoices);
      
      const paymentInvoices = payment.invoices;
      if (!rootGetters["property/loaded"]) {
        await dispatch("property/loadProperties", payment.clientId, {
          root: true,
        });
      }
      for (const invoice of paymentInvoices) {
        const invoiceInfo = invoices.find(item => item.id === invoice.id);
        const caseInfo = rootGetters["eviction/cases"].find(item => item.id === invoiceInfo.caseId);
        const propertyInfo = rootGetters["property/properties"].find(item => item.propertyId === caseInfo.propertyId);
        invoice.invoiceId = invoice.id;
        invoice.caseId = invoiceInfo.caseId;
        invoice.docketNo = caseInfo.docketNo;
        invoice.caseParties = `${caseInfo.deedNames ?? "Pending"} vs. ` + (combineTenants(caseInfo.tenants) ?? "Pending");
        const getUnitNo = propertyInfo.unitNo ? `, ${propertyInfo.unitNo}` : '';
        invoice.propertyAddress = `${propertyInfo.streetAddress}${getUnitNo}, ${propertyInfo.city}, ${propertyInfo.state} ${propertyInfo.zipcode}`;
        invoice.currentInvoices = invoiceInfo.currentInvoices ? JSON.parse(invoiceInfo.currentInvoices) : [];
        invoice.disburses = invoiceInfo.disburses ? JSON.parse(invoiceInfo.disburses) : [];
        invoice.priorInvoices = invoiceInfo.priorInvoices ? JSON.parse(invoiceInfo.priorInvoices) : [];
        invoice.type = invoiceInfo.type;
      }
      await useRequest({
        path: "receiptPdf",
        method: "post",
        showLoading: true,
        data: {
          templateName: 'receipt',
          attorneyInfo: {
            company: rootGetters.firm.name,
            addressLine1: rootGetters.firm.streetAddress,
            addressLine2: `${rootGetters.firm.city}-NJ, ${rootGetters.firm.zipcode}`,
            email: rootGetters.firm.email,
            // phone: this.currentCase.attorneyInfo.cell,
          },
          clientInfo: payment.clientInfo,
          invoices: paymentInvoices,
        },
      });
    },
    async update({ state, commit }, updateData) {
      const { data: res } = await useRequest({
        method: "put",
        path: "invoices",
        data: updateData,
      });
      if (res.success) {
        const invoices = state.invoices.map((invoice) => {
          if (invoice.id === updateData.id) return { ...invoice, ...updateData };
          else return invoice;
        });
        commit("set_invoices", invoices);
      }
    },
  },
  getters: {
    invoices: (state) => state.invoices,
    loaded: (state) => state.loaded,
  },
};
