import { mapGetters } from "vuex";

export default {
  data: () => ({
    summaryItems: [],
  }),
  computed: {
    ...mapGetters({
      case: "eviction/currentCase",
    }),
  },
  methods: {
    async initFeeData() {
      try {
        const caseData = this.case;
        const { pastDueRent, additionalChargesPermitted, legalFeesPermitted } =
          caseData;

        this.summaryItems = [
          {
            name: "Past Due Rent",
            total: 0,
            amounts: [],
            keys: ["pastDueRent"],
          },
        ];

        if (pastDueRent.length) {
          this.summaryItems[0].amounts = pastDueRent.filter(
            (value) => value.selected
          );
          this.summaryItems[0].total = this.calculateTotal(pastDueRent);
        }

        if (additionalChargesPermitted) {
          const lateCharges = caseData.section8 ? [] : caseData.lateCharges;
          this.summaryItems.push({
            name: "Additional Charges",
            total: this.calculateAdditionalChargeTotal(),
            amounts: [
              ...caseData.utilities,
              ...caseData.fees,
              ...caseData.otherCharges,
              ...lateCharges,
            ],
            keys: ["utilities", "fees", "otherCharges", "lateCharges"],
          });
        }

        if (legalFeesPermitted) {
          let total = 0;
          let amounts = [];
          if (!caseData.section8) {
            const courtFees = this.calculateCourtFees(caseData);
            amounts = courtFees;
            total = this.calculateTotal(courtFees);
          }
          this.summaryItems.push({
            name: "Legal Fees",
            total,
            amounts,
            keys: ["legalFees"],
          });
        }
      } catch (error) {
        console.error("initFeeData error:", error.message);
      }
    },
    calculateCourtFees(caseData) {
      if (!caseData.tenants.length) {
        console.error("No tenants found");
        this.$store.commit(
          "global/set_global_error",
          {
            message: "No tenants found on this case.",
          },
          { root: true }
        );
        return null;
      }

      const calculateTenantFee = 5 * (caseData.tenants.length - 1); //The first tenant is $0.00.

      const amountsArray = [
        {
          type: "Filing Fee",
          amount: "50.00",
          selected: caseData.legalFeesPermitted,
        },
        {
          type: "Mileage Fee",
          amount: "7.00",
          selected: caseData.legalFeesPermitted,
        },
      ];
      const attorneyFee = caseData.legalFees
        ? caseData.legalFees.find((item) => item.type == "Attorney Fee")
        : null;
      if (attorneyFee) {
        amountsArray.push(attorneyFee);
      } else {
        amountsArray.push({
          type: "Attorney Fee",
          amount: "0.00",
          selected: caseData.legalFeesPermitted,
        });
      }

      if (caseData.tenants.length > 1) {
        amountsArray.push({
          type: "Tenant Fee",
          amount: parseFloat(calculateTenantFee).toFixed(2),
          selected: caseData.legalFeesPermitted,
        });
      }
      return amountsArray;
    },
    calculateTotal(arr) {
      let total = 0;
      arr
        .filter((value) => value.selected)
        .forEach((item) => {
          if (item.amount) {
            total += parseFloat(item.amount);
          }
        });
      return total;
    },
    calculateAdditionalChargeTotal() {
      const lateCharges = this.case.section8
        ? 0
        : this.calculateTotal(this.case.lateCharges);
      return (
        lateCharges +
        this.calculateTotal(this.case.utilities) +
        this.calculateTotal(this.case.fees) +
        this.calculateTotal(this.case.otherCharges)
      );
    },
  },
};
