<template>
  <div>
    <div class="mt-4 mb-2 font-weight-medium">Map CSV Fields</div>
    <div>
      Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua.
    </div>

    <div class="mt-12" style="padding: 0 15%">
      <v-row class="mb-8 font-weight-medium">
        <v-col cols="6">Column Name</v-col>
        <v-col cols="6">Map to Field</v-col>
      </v-row>
      <v-row v-for="item in columnList" :key="item.key">
        <v-col cols="6" class="font-weight-medium">{{ item.value }}</v-col>
        <v-col cols="6">
          <v-select
            v-model="item.matching"
            :value="item.key"
            background-color="#F0F5F6"
            item-color="#F6F9FA"
            rounded
            solo
            flat
            dense
            :items="excelHeaders"
          >
            <v-icon slot="append" medium color="accent">
              mdi-chevron-down
            </v-icon>
          </v-select>
        </v-col>
      </v-row>
    </div>

    <v-card-actions class="mt-16 mb-8 d-flex justify-end">
      <v-btn
        color="secondary"
        class="mr-4 btn--plain capital--btn"
        rounded
        text
        @click="back"
      >
        Go Back
      </v-btn>
      <v-btn
        color="primary"
        class="px-10 white--text"
        rounded
        depressed
        @click="next"
      >
        Import
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
export default {
  name: "ColumnMapping",
  props: {
    excelHeaders: Array,
  },
  data() {
    return {
      columnList: [
        { key: "firstName", value: "First Name", matching: "firstName" },
        { key: "lastName", value: "Last Name", matching: "lastName" },
        { key: "company", value: "Company", matching: "company" },
        { key: "phone", value: "Phone", matching: "phone" },
        { key: "email", value: "Email", matching: "email" },
        {
          key: "streetAddress",
          value: "Street Address",
          matching: "streetAddress",
        },
        { key: "city", value: "City", matching: "city" },
        { key: "state", value: "State", matching: "state" },
        { key: "zipCode", value: "Zip Code", matching: "zipCode" },
      ],
    };
  },
  methods: {
    back() {
      this.$emit("updateStep", "back");
    },
    next() {
      this.$emit("mapColumn", this.columnList);
      this.$emit("updateStep", "next");
    },
  },
};
</script>

<style scoped>
.v-data-table >>> th,
.v-data-table >>> td {
  border: 1px solid lightgrey;
}
.theme--light.v-data-table >>> thead tr th:first-child {
  border-top-left-radius: 0 !important;
}
.theme--light.v-data-table >>> thead tr th:last-child {
  border-top-right-radius: 0 !important;
}
.theme--light.v-data-table >>> tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0 !important;
}
.theme--light.v-data-table >>> tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0 !important;
}
</style>
