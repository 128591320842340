<template>
  <div>
    <div class="success-panel">
      <v-icon color="primary" style="font-size: 70px"
        >mdi-checkbox-marked-circle-outline</v-icon
      >
      <span class="mt-4 font-weight-medium" style="font-size: 40px"
        >Import Complete</span
      >
      <span class="pt-4"
        >We have successfully imported
        {{ importCnt - errors.length }} clients.</span
      >
      <span>{{ errors.length }} clients failed to import.</span>
    </div>
    <div v-if="errors.length" class="error-panel">
      <v-row class="font-weight-medium">
        <v-col cols="6">Client</v-col>
        <v-col cols="6">Reason for Failure</v-col>
      </v-row>
      <v-row class="mt-4" v-for="item in errors" :key="item.index">
        <v-col cols="6" class="font-weight-medium"
          >Row {{ parseInt(item.index) + 1 }}</v-col
        >
        <v-col cols="6">{{ item.msg }}</v-col>
      </v-row>
    </div>

    <v-card-actions class="mt-16 mb-8 d-flex justify-end">
      <v-btn
        color="secondary"
        class="mr-4 btn--plain capital--btn"
        rounded
        text
        @click="back"
      >
        Go Back
      </v-btn>
      <v-btn
        color="primary"
        class="px-10 white--text"
        rounded
        depressed
        @click="viewClients"
      >
        View Clients
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Complete",
  props: {
    importCnt: Number,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      errors: "client/errors",
    }),
  },
  methods: {
    back() {
      this.$emit("updateStep", "back");
    },
    viewClients() {
      this.$router.push({ name: "clients" });
    },
  },
};
</script>

<style scoped>
.success-panel {
  width: 100%;
  padding: 10% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.error-panel {
  padding: 0 15%;
}
</style>
