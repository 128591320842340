import Vue from "vue";
import moment from "moment";
import router from "@/router";
import useRequest from "@/utils/useRequest";
import handleCaseResult from "@/utils/handleCaseResult";
import updateCaseSteps from "@/utils/updateCaseSteps";
import initialCaseSteps from "@/utils/initialCaseSteps.json";
import { trialActionsString } from "@/utils/worFlowData";
import { store } from "@/store";

export default {
  namespaced: true,
  state: {
    cases: [],
    currentCase: {},
    currentClientId: 0,
    currentClient: {},
    courtAddress: {},
    courtInfo: {},
    caseOrders: [],
    currentOrder: {},
    steps: initialCaseSteps,
    loading: false,
    evictionAppLoaded: false,
    pleadings: [],
    isNewClient: false,
    opposingCounsels: [],
    nextStepAfterLedger: "",
  },
  mutations: {
    set_cases(state, cases) {
      Vue.set(state, "cases", cases);
    },
    set_current_case(state, payload) {
      console.log("set_current_case:", payload);
      Vue.set(state, "currentCase", payload);
    },
    set_current_client_id(state, clientId) {
      Vue.set(state, "currentClientId", clientId);
    },
    set_current_client(state, client) {
      Vue.set(state, "currentClient", client);
    },
    init_case(state) {
      Vue.set(state, "currentCase", {});
    },
    init_steps(state) {
      const initialStepsData = JSON.parse(JSON.stringify(initialCaseSteps));
      console.log("initialStepsData :>> ", initialStepsData);

      Vue.set(state, "steps", initialStepsData);
    },
    update_current_case(state, updatedFields) {
      console.log("update_current_case, updatedFields:", updatedFields);
      const currentCaseInstance = { ...state.currentCase, ...updatedFields };
      Vue.set(state, "currentCase", currentCaseInstance);

      if (!store.getters.isAuthenticated) {
        sessionStorage.setItem("case", JSON.stringify(currentCaseInstance));
      }
    },
    set_loading(state, flag) {
      Vue.set(state, "loading", flag);
    },
    set_court_info(state, courtInfo) {
      Vue.set(state, "courtInfo", courtInfo);
    },
    set_courtAddress(state, courtAddress) {
      Vue.set(state, "courtAddress", courtAddress);
    },
    set_current_order(state, currentOrder) {
      Vue.set(state, "currentOrder", currentOrder);
    },
    set_case_orders(state, caseOrders) {
      Vue.set(state, "caseOrders", caseOrders);
    },
    update_steps(state, steps) {
      const currentCaseSteps = { ...state.steps };

      for (const key in steps) {
        currentCaseSteps[key] = steps[key];
      }

      //* Expand 'property' if it's in this route (when user reloads page)
      const { path } = router.currentRoute;
      if (path.includes("property"))
        currentCaseSteps.verifiedComplaint.children.property.showChildren = true;
      // console.log("update_steps, updating to", currentCaseSteps);
      Vue.set(state, "steps", currentCaseSteps);
    },
    set_pleadings(state, pleadings) {
      Vue.set(state, "pleadings", pleadings);
    },
    set_is_new_client(state, value) {
      Vue.set(state, "isNewClient", value);
    },
    set_opposing_counsels(state, counsels) {
      Vue.set(state, "opposingCounsels", counsels);
    },
    set_nextStepAfterLedger(state, value) {
      Vue.set(state, "nextStepAfterLedger", value);
    },
  },
  actions: {
    async loadCases({ commit, rootGetters }) {
      if (!rootGetters.isAuthenticated) return;
      const { data: cases } = await useRequest({
        path: `attorney/cases?firmId=${rootGetters.firm.id}&caseType=eviction`,
        showLoading: true,
      });

      commit("set_cases", cases);
    },
    // eslint-disable-next-line
    async loadCase({ dispatch, getters, commit, state }, { caseId, logMessage }) {
      if (process.env.NODE_ENV !== "production") {
        console.log(`eviction/loadCase is dispatched from ${logMessage}`);
      }

      commit("init_steps");

      // const { data } = await useRequest({
      //   path: `cases/${parseInt(caseId)}`,
      //   showLoading: true,
      //   throwError: true,
      // });
      const data = state.cases.find((item) => item.id === caseId);
      commit("set_current_client_id", data.clientId);

      await dispatch("property/loadProperty", data.propertyId, { root: true });
      handleCaseResult(data);

      const currentCaseSteps = { ...getters.caseSteps };
      let isCaseStepsUpdated = false;
      if (data.section8 && data.section8Type === 2) {
        currentCaseSteps.verifiedComplaint.children.legalFees.hide = true;
        isCaseStepsUpdated = true;
      }
      if (data.clrsDraft === 0) {
        currentCaseSteps.verifiedComplaint.children.leaseRegistration.hide = true;
        isCaseStepsUpdated = true;
      }
      if (isCaseStepsUpdated) commit("update_steps", currentCaseSteps);

      await dispatch("loadCourtInfo");
    },
    editCase({ state, commit }) {
      const status = state.currentCase.status;
      if (status === "vc-property") {
        const steps = state.steps;
        steps.verifiedComplaint.showChildren = true;
        steps.verifiedComplaint.children.property.showChildren = true;
        commit("update_steps", steps);
        router.push({
          name: "vc-property-owner",
          params: { id: state.currentCase.id },
        });
        return;
      }
      let routeName;
      if (status.includes("vc-")) {
        routeName =
          state.steps.verifiedComplaint.children[status.split("-")[1]]
            .routeName;
        const steps = state.steps;
        steps.verifiedComplaint.showChildren = true;
        commit("update_steps", steps);
      } else if (status.includes("wor-")) {
        routeName = "wor-trial-action";
        const steps = state.steps;
        steps.trialOutcome.showChildren = true;
        commit("update_steps", steps);
      } else if (status.includes("co-")) {
        routeName = "wor-court-outcome";
      } else {
        routeName = state.steps[status].routeName;
      }
      router.push({ name: routeName, params: { id: state.currentCase.id } });
    },
    async createCase(
      { rootGetters, commit, state },
      { caseData, nextPath, logMessage }
    ) {
      if (process.env.NODE_ENV !== "production") {
        console.log(`eviction/createCase is dispatched from ${logMessage}`);
      }

      if (!rootGetters.isAuthenticated) {
        // save to localStorage for when user creates account
        const newCase = {
          documents: [],
          status: "vc-property",
          actions: [
            {
              actionId: 181,
              actionStringId: "completeVerifiedComplaint",
            },
          ],
          submitted: false,
          confirmation: {},
          verification: {},
        };
        sessionStorage.setItem("case", JSON.stringify(newCase));
        commit("update_current_case", newCase);
        updateCaseSteps();
        return;
      }

      const { data } = await useRequest({
        method: "post",
        path: "cases",
        data: {
          firmId: rootGetters.firm.id,
          clientId: state.currentClientId,
          attorneyId: state.currentCase.attorneyId,
          caseInfo: caseData,
          status: "vc-property",
          submitted: false,
          caseType: "eviction",
        },
      });
      handleCaseResult(data);

      if (nextPath) router.push({ name: nextPath, params: { id: data.id } });
      else router.replace({ params: { id: data.id } });

      return data;
    },
    async updateCase(
      { commit, state, getters, rootGetters },
      { caseData, status, logMessage }
    ) {
      console.log("eviction/updateCase() data:", {
        caseData,
        status,
        logMessage,
      });

      const statusDate = caseData?.statusDate || moment().format("MM.DD.YY");
      const submitted = status === "verifiedComplaintComplete";
      commit("update_current_case", {
        ...caseData,
        status,
        submitted,
        statusDate,
      });

      updateCaseSteps();

      if (!rootGetters.isAuthenticated) return;
      if (!getters.currentCase.id)
        return console.error("currentCase.id is null");

      const requestData = {
        caseInfo: {
          propertyId: getters.currentCase.propertyId,
          ...caseData,
          statusDate,
        },
        submitted,
        status,
        clientId: state.currentClientId,
      };
      const { data } = await useRequest({
        path: `case/${getters.currentCase.id}`,
        method: "post",
        data: requestData,
        throwError: true,
      });
      handleCaseResult(data);
      return data;
    },
    async loadDocuments({ commit, rootGetters }, { caseId, name, logMessage }) {
      if (
        process.env.NODE_ENV !== "production" &&
        typeof logMessage !== "undefined"
      ) {
        console.log(`eviction/loadDocuments is dispatched from ${logMessage}`);
      }

      if (!rootGetters.isAuthenticated) {
        return;
      }

      const { data: documents, error } = await useRequest({
        path: `uploads/${caseId}?name=${name}`,
      });
      if (error) return;
      commit("update_current_case", { documents });
    },
    async loadCourtInfo({ commit, getters, rootGetters }) {
      const { county, countyId } =
        rootGetters["property/currentProperty"] || {};

      if (!countyId) return;
      if (county === getters.courtInfo.county) return;

      const { data } = await useRequest({
        path: `courtServiceInfo/${countyId}`,
        showLoading: true,
      });
      const {
        superiorCourtStreet,
        superiorCourtCity,
        superiorCourtName,
        superiorCourtPhone,
        superiorCourtState,
        superiorCourtZipcode,
        ...rest
      } = data;
      const courtInfo = {
        street: superiorCourtStreet,
        city: superiorCourtCity,
        name: superiorCourtName,
        phone: superiorCourtPhone,
        state: superiorCourtState,
        zipcode: superiorCourtZipcode,
        county,
        ...rest,
      };
      commit("set_court_info", courtInfo);
    },
    async createOrder({ commit }, { payload }) {
      await useRequest({
        path: `api/orders`,
        method: "post",
        data: payload,
        showLoading: true,
        throwError: true,
      });
    },
    async loadPleadings({ commit, getters }) {
      const { currentCase } = getters;
      if (!currentCase.id) return console.error("currentCase.id is null");
      const { data } = await useRequest({
        path: `pleadings/${currentCase.id}`,
        showLoading: true,
      });
      const pleadings = data || [];
      if (!currentCase?.actions) {
        if (currentCase.clrsSubmission || currentCase.clrsExemption) {
          commit("set_pleadings", [
            {
              name: "Certification of Lease and Registration Statement",
              templateName: "clrs_form",
            },
          ]);
        } else {
          commit("set_pleadings", []);
        }
        return;
      }

      //* for now, just get the first creted_at date it finds.
      const createdDate = pleadings.find((file) => file.created_at)?.created_at;
      const items = [
        {
          name: "Cover Letter",
          templateName: "cover_letter",
          drafted: createdDate,
          updated: createdDate,
        },
        {
          name: "Verified Complaint",
          templateName: "verified_complaint",
          drafted: createdDate,
          updated: createdDate,
        },
        {
          name: "Tenant Summons",
          templateName: "tenant_summons",
          drafted: createdDate,
          updated: createdDate,
        },
        {
          name: "Landlord Case Information Statement",
          templateName: "lcis",
          drafted: createdDate,
          updated: createdDate,
        },
      ];
      if (currentCase.clrsSubmission || currentCase.clrsExemption) {
        items.push({
          name: "Certification of Lease and Registration Statement",
          templateName: "clrs_form",
          drafted: createdDate,
          updated: createdDate,
        });
      }
      if (!currentCase?.actions) return;
      const actionId = currentCase.actions[0]?.actionId;
      switch (actionId) {
        // case 51: // Post Trial Actions
        //   if (
        //     courtOutcomesString().slice(2, 5).includes(currentCase.courtOutcome)
        //   ) {
        //     items.push({
        //       name: "Consent to Enter Judgment",
        //       templateName: "consent_to_enter_judgment",
        //       drafted: createdDate,
        //       updated: createdDate,
        //     });
        //   }
        //   break;
        case 61: // Request for Warrant Of Removal
          if (
            // courtOutcomesString()
            //   .slice(2, 5)
            //   .includes(currentCase.courtOutcome) ||
            trialActionsString().slice(5, 8).includes(currentCase.trialAction)
          ) {
            items.push({
              name: "Consent to Enter Judgment",
              templateName: "consent_to_enter_judgment",
              drafted: createdDate,
              updated: createdDate,
            });
          }
          if (
            trialActionsString().slice(0, 5).includes(currentCase.trialAction)
          ) {
            items.push({
              name: "Request for Warrant of Removal",
              templateName: "wor_request",
              drafted: createdDate,
              updated: createdDate,
            });
            items.push({
              name: "Certification by Landlord",
              templateName: "certification_by_landlord",
              drafted: createdDate,
              updated: createdDate,
            });
            items.push({
              name: "Certification by Landlord's Attorney",
              templateName: "certification_by_landlord_attorney",
              drafted: createdDate,
              updated: createdDate,
            });
          }
          if (
            trialActionsString().slice(2, 5).includes(currentCase.trialAction)
          ) {
            items.push({
              name: "Letter to Tenant",
              templateName: "letter_to_tenant",
              drafted: createdDate,
              updated: createdDate,
            });
          }
          break;
      }
      commit("set_pleadings", items);
    },
    async loadOpposingCounsels({ commit }) {
      const { data: res } = await useRequest({
        path: "opposingCounsels",
        showLoading: true,
      });

      commit("set_opposing_counsels", res.opposingCounsels);
    },
    async createOpposingCounsel({ commit }, { opposingCounselInfo }) {
      const { data: res } = await useRequest({
        path: "opposingCounsels",
        method: "post",
        data: {
          opposingCounselInfo,
        },
        showLoading: true,
      });

      return res.addedId;
    },
    async updateOpposingCounsel({ commit, state }, { opposingCounselInfo }) {
      const { data: res } = await useRequest({
        path: "opposingCounsels",
        method: "put",
        data: {
          opposingCounselInfo,
        },
        showLoading: true,
      });
      if (res.success) {
        const opposingCounsels = state.opposingCounsels.map((item) => {
          if (item.id === opposingCounselInfo.id)
            return { ...item, ...opposingCounselInfo };
          else return item;
        });
        commit("set_opposing_counsels", opposingCounsels);
      }
    },
  },
  getters: {
    currentCase: (state) => state.currentCase,
    currentClientId: (state) => state.currentClientId,
    currentClient: (state) => state.currentClient,
    cases: (state) => state.cases,
    caseSteps: (state) => state.steps,
    worSteps: (state) => state.worSteps,
    loading: (state) => state.loading,
    courtInfo: (state) => state.courtInfo,
    courtAddress: (state) => state.courtAddress,
    caseOrders: (state) => state.caseOrders,
    currentOrder: (state) => state.currentOrder,
    pleadings: (state) => state.pleadings,
    isNewClient: (state) => state.isNewClient,
    opposingCounsels: (state) => state.opposingCounsels,
    nextStepAfterLedger: (state) => state.nextStepAfterLedger,
  },
};
