<template>
  <v-card class="new-task-modal" style="border-radius: 20px; height: 90vh">
    <div class="close-btn">
      <v-btn color="info" icon @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <vue-scroll :ops="ops" ref="scrollRef">
      <v-form style="margin-bottom: 140px">
        <div class="pa-12 px-16 secondary--text">
          <v-card-title class="mb-4 font-weight-medium">New Task</v-card-title>
          <div class="ml-4">
            <v-row>
              <v-col cols="6">
                <span>Client</span>
                <v-select
                  v-model="newTask.clientId"
                  :items="clientList"
                  item-text="fullName"
                  item-value="id"
                  background-color="#F0F5F6"
                  item-color="#F6F9FA"
                  rounded
                  solo
                  flat
                  dense
                >
                  <v-icon slot="append" medium color="accent">
                    mdi-chevron-down
                  </v-icon>
                </v-select>
              </v-col>
              <v-col cols="6">
                <span>Case</span>
                <v-select
                  v-model="newTask.caseId"
                  :items="caseList"
                  item-text="caseParties"
                  item-value="id"
                  background-color="#F0F5F6"
                  item-color="#F6F9FA"
                  rounded
                  solo
                  flat
                  dense
                >
                  <v-icon slot="append" medium color="accent">
                    mdi-chevron-down
                  </v-icon>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <span>Date Due</span>
                <DateField :field.sync="newTask.dateDue" />
              </v-col>
              <v-col cols="6">
                <span>Status</span>
                <v-select
                  v-model="newTask.status"
                  background-color="#F0F5F6"
                  item-color="#F6F9FA"
                  rounded
                  solo
                  flat
                  dense
                  :items="statusList"
                >
                  <v-icon slot="append" medium color="accent">
                    mdi-chevron-down
                  </v-icon>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>Subject</span>
                <v-text-field
                  v-model="newTask.subject"
                  v-bind="$attrs"
                  rounded
                  solo
                  flat
                  dense
                  background-color="#F0F5F6"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>Details</span>
                <v-textarea
                  v-model="newTask.description"
                  rounded
                  solo
                  flat
                  dense
                  background-color="#F0F5F6"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-form>
    </vue-scroll>

    <div class="card-actions" style="margin-top: -20px">
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="cancel"
      >
        Cancel
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 ml-8 white--text capital--btn"
        depressed
        :disabled="!valid"
        @click="save"
      >
        Add New
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { combineTenants } from "@/utils";

export default {
  name: "NewTaskDialog",
  props: {
    fromCaseView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          opacity: 0,
          size: "10px",
          specifyBorderRadius: false,
          gutterOfEnds: "85px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "10px",
        },
      },
      caseList: null,
      statusList: ["Pending", "Overdue", "In Progress", "Completed"],
      remindList: [
        "Minutes before",
        "Hours before",
        "Days before",
        "Weeks before",
      ],
      newTask: {
        clientId: null,
        caseId: null,
        dateDue: "",
        subject: "",
        description: "",
        status: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      clients: "client/clients",
      clientsLoaded: "client/loaded",
      cases: "eviction/cases",
      currentCase: "eviction/currentCase",
    }),
    client() {
      return this.newTask.clientId;
    },
    clientList() {
      return this.clients.map((item) => {
        return {
          id: item.id,
          fullName: item.firstName + " " + item.lastName,
        };
      });
    },
    valid() {
      return (
        this.newTask.clientId && this.newTask.caseId && this.newTask.status
      );
    },
  },
  watch: {
    client(selectedClientId) {
      if (!selectedClientId) return;
      const matchedCases = this.cases.filter(
        (item) => item.clientId === selectedClientId
      );
      this.caseList = this.getCaseList(matchedCases);
    },
  },
  async created() {
    this.caseList = this.getCaseList(this.cases);
    if (!this.clientsLoaded) {
      await this.$store.dispatch("client/loadClients");
      this.$store.commit("client/set_loaded", true);
    }
    if (this.fromCaseView) {
      this.newTask.clientId = this.currentCase.clientId;
      this.newTask.caseId = this.currentCase.id;
    }
  },
  methods: {
    initTask() {
      if (this.fromCaseView) {
        this.newTask = {
          clientId: this.newTask.clientId,
          caseId: this.newTask.caseId,
          dateDue: "",
          subject: "",
          description: "",
          status: "",
        };
      } else {
        this.newTask = {
          clientId: null,
          caseId: null,
          dateDue: "",
          subject: "",
          description: "",
          status: "",
        };
      }
    },
    getCaseList(items) {
      return items.map((item) => {
        return {
          id: item.id,
          caseParties:
            (item.docketNo ?? "LT-") +
            " / " +
            (item.deedNames ?? "Pending") +
            " vs. " +
            (combineTenants(item.tenants) ?? "Pending"),
        };
      });
    },
    save() {
      this.$emit("saveNewTask", this.newTask);
      this.$refs.scrollRef.scrollTo({ x: 0, y: 0 }, 0);
      this.initTask();
    },
    cancel() {
      this.$emit("close");
      this.$refs.scrollRef.scrollTo({ x: 0, y: 0 }, 0);
      this.initTask();
    },
  },
};
</script>

<style>
.new-task-modal {
  overflow-y: visible;
}
.new-task-modal .v-text-field__details {
  display: none;
}
</style>

<style scoped>
.__vuescroll >>> .__panel {
  overscroll-behavior: contain;
}

.close-btn {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1;
}
.theme--light.v-divider {
  border-color: grey;
}
.v-divider--vertical {
  align-self: center;
  height: 20px;
}
.v-btn >>> .v-btn__content {
  opacity: 1 !important;
}
</style>
