<template>
  <div :class="isCard ? '' : 'custom-data-table'">
    <v-data-table
      v-if="tasks.length > 0"
      v-model="selected"
      :headers="headers"
      :header-props="{ 'sort-icon': 'mdi-menu-up' }"
      :must-sort="true"
      :items="tasks"
      :search="search"
      :custom-filter="customFilter"
      item-key="ID"
      :class="isCard ? '' : 'my-table'"
      :items-per-page="itemsPerPage"
      :hide-default-footer="isCard"
      :footer-props="
        isCard
          ? {}
          : {
              showFirstLastPage: true,
              firstIcon: 'mdi-skip-previous',
              lastIcon: 'mdi-skip-next',
              prevIcon: 'mdi-menu-left',
              nextIcon: 'mdi-menu-right',
              itemsPerPageText: 'Number of tasks to display',
              pageText: pageText,
            }
      "
      @page-count="pageCount = $event"
      @update:options="updateOptions"
      @current-items="getFilteredTasks"
    >
      <template v-slot:item="{ item, index }">
        <tr
          @mouseover="rowHoverIndex = index"
          @mouseleave="rowHoverIndex = -1"
          :class="{ 'on-hover': rowHoverIndex === index }"
          class="data-row"
        >
          <td class="first-column">
            <v-select
              v-model="item.status"
              class="mt-6"
              background-color="#F0F5F6"
              item-color="#F6F9FA"
              rounded
              solo
              flat
              dense
              :items="statusList"
              @change="updateTaskStatus(item)"
            />
          </td>
          <td v-if="!isCard">
            <div class="d-flex flex-row align-center">
              <v-avatar v-if="item.avatar" size="40" class="white--text">
                <v-img :src="item.avatar" />
              </v-avatar>
              <v-avatar
                v-else
                size="40"
                class="white--text font-weight-medium"
                :color="avatarBackgroundColor(item.attorneyName)"
              >
                {{ item.attorneyName.slice(0, 2).toUpperCase() }}
              </v-avatar>
              <span class="pl-4 font-weight-medium">
                {{ item.attorneyName }}
              </span>
            </div>
          </td>
          <td v-if="!isCard">
            {{ item.clientName }}
          </td>
          <td
            class="ml-4 accent--text"
            @click="caseOverview(item.caseId)"
            style="cursor: pointer"
          >
            {{ item.caseParties }}
          </td>
          <td v-if="!isCard">
            {{ item.docketNo ? item.docketNo : "LT-" }}
          </td>
          <td>
            {{ item.subject }}
          </td>
          <td>
            <div class="d-flex">
              <div class="content" style="width: 90%">
                {{ item.description }}
              </div>
              <v-menu
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mt-0" v-bind="attrs" v-on="on">
                    icofont-external-link
                  </v-icon>
                </template>

                <v-card class="px-4 secondary--text" style="width: 700px">
                  <v-card-title>{{ item.subject }}</v-card-title>
                  <v-card-text class="secondary--text">{{
                    item.description
                  }}</v-card-text>
                </v-card>
              </v-menu>
            </div>
          </td>
          <td>
            {{ item.dateDue }}
          </td>
        </tr>
        <td :colspan="headers.length" class="row-divider"></td>
      </template>
      <template v-if="tasks.length !== 0" slot="body.append">
        <tr style="height: 30px"></tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "TaskTable",
  props: {
    _tasks: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: "",
    },
    filters: {
      type: Array,
      default: () => [],
    },
    isCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Status",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "15%",
          sortable: false,
        },
        {
          text: "Attorney",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Client",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Parties",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Case No.",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Subject",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Details",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "20%",
          sortable: false,
        },
        {
          text: "Date Due",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "10%",
          sortable: false,
        },
      ],
      statusList: ["Pending", "Overdue", "In Progress", "Completed"],
      selected: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      rowHoverIndex: false,
      tasks: [],
      filteredTasks: [],
    };
  },
  computed: {
    pageText() {
      return "Pages " + this.page + ` / ` + this.pageCount;
    },
  },
  watch: {
    filters: {
      handler(val) {
        if (!val.length) {
          this.tasks = this._tasks;
          return;
        }
        const _filters = val.filter(
          (item) => item.filterColumn && item.filterOperator && item.filterValue
        );
        if (_filters.length) {
          let _filteredTasks = this._tasks;
          _filters.map((item) => {
            if (item.filterColumn == "DATE DUE") {
              switch (item.filterOperator) {
                case "On or Before":
                  _filteredTasks = _filteredTasks.filter(
                    (taskItem) =>
                      this.$dayjs(taskItem.dateDue).format("YYYY-MM-DD") <=
                      item.filterValue
                  );
                  break;
                case "On or After":
                  _filteredTasks = _filteredTasks.filter(
                    (taskItem) =>
                      this.$dayjs(taskItem.dateDue).format("YYYY-MM-DD") >=
                      item.filterValue
                  );
                  break;
                case "On":
                  _filteredTasks = _filteredTasks.filter(
                    (taskItem) =>
                      this.$dayjs(taskItem.dateDue).format("YYYY-MM-DD") ==
                      item.filterValue
                  );
                  break;
              }
            } else {
              _filteredTasks = _filteredTasks.filter((taskItem) => {
                switch (item.filterColumn) {
                  case "STATUS":
                    return taskItem.status == item.filterValue;
                  case "ATTORNEY":
                    return taskItem.attorneyName == item.filterValue;
                  case "CLIENT":
                    return taskItem.clientName == item.filterValue;
                  case "PARTIES":
                    return taskItem.caseParties == item.filterValue;
                  case "CASE NO.":
                    return (taskItem.docketNo ?? "LT-") == item.filterValue;
                  case "SUBJECT":
                    return taskItem.subject == item.filterValue;
                  case "DETAILS":
                    return taskItem.description == item.filterValue;
                  case "DATE DUE":
                    return taskItem.dateDue == item.filterValue;
                }
              });
            }
          });
          this.tasks = _filteredTasks;
        }
      },
      deep: true,
    },
    _tasks() {
      this.tasks = this._tasks;
    },
  },
  async created() {
    if (this.isCard) {
      this.headers = [
        {
          text: "Status",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "20%",
          sortable: false,
        },
        {
          text: "Parties",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Subject",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Details",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Date Due",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "15%",
          sortable: false,
        },
      ];
    }
    this.tasks = this._tasks;
  },
  methods: {
    getFilteredTasks(filteredTasks) {
      this.filteredTasks = filteredTasks;
    },
    async caseOverview(caseId) {
      await this.$store.dispatch("eviction/loadCase", {
        caseId,
        forceLoad: true,
      });
      this.$router.push(`case-view/${caseId}/overview`);
    },
    exportExcel() {
      const headersText = this.headers
        .slice(0, this.headers.length)
        .map((item) => item.text);
      let csv_string = headersText.join(", ") + "\n";
      this.filteredTasks.forEach((item) => {
        csv_string += item.status;
        csv_string += `,${item.attorneyName}`;
        csv_string += `,${item.clientName}`;
        csv_string += `,${item.caseParties}`;
        csv_string += `,${item.docketNo ?? "LT-"}`;
        csv_string += `,${item.subject}`;
        csv_string += `,${item.description}`;
        csv_string += `,${item.dateDue}`;
        csv_string += "\n";
      });

      const link = document.createElement("a");
      link.style.display = "none";
      link.setAttribute("target", "_blank");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
      );
      link.setAttribute("download", "export_tasks.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    avatarBackgroundColor(username) {
      return this.createAvatarBackgroundColor(username);
    },
    customFilter(_value, search, items) {
      return (
        (items.status !== null &&
          items.status
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())) ||
        (items.attorneyName !== null &&
          items.attorneyName
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())) ||
        (items.clientName !== null &&
          items.clientName
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())) ||
        (items.caseParties !== null &&
          items.caseParties
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())) ||
        (items.docketNo !== null &&
          items.docketNo
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())) ||
        (items.subject !== null &&
          items.subject
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase()))
      );
    },
    updateOptions(option) {
      this.page = option.page;
    },
    async updateTaskStatus(taskItem) {
      await this.$store.dispatch("task/update", {
        id: taskItem.id,
        status: taskItem.status,
      });
    },
  },
};
</script>

<style scoped>
.content {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.data-row {
  color: #003a60 !important;
  height: 80px !important;
}
.data-row > td {
  border-bottom: none !important;
}
.data-row:hover {
  background-color: transparent !important;
}
.first-column {
  border-left: 8px solid transparent;
}
.on-hover > .first-column {
  border-left: 8px solid #4dccc4;
}
.row-divider {
  height: 1px !important;
  background: #c3d1d2 !important;
}
.status-row {
  background-color: #f8fafb !important;
  color: #003a60 !important;
}
.status-grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  align-items: center;
  height: 65px !important;
  padding-right: 10px;
}
.v-menu__content {
  margin-top: 50px;
}
.v-icon.v-icon::after {
  background-color: white;
}
</style>
