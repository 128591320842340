import { render, staticRenderFns } from "./PdfViewer.vue?vue&type=template&id=2000a62a&"
import script from "./PdfViewer.vue?vue&type=script&lang=js&"
export * from "./PdfViewer.vue?vue&type=script&lang=js&"
import style0 from "pdfjs-dist/web/pdf_viewer.css?vue&type=style&index=0&prod&lang=css&"
import style1 from "./PdfViewer.vue?vue&type=style&index=1&id=2000a62a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports