<template>
  <div class="mx-auto secondary--text">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$emit('close:form')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div style="width: 80%" class="pt-10 pb-4 mx-auto text-left">
      <div class="custom-title pt-8 pb-16">Select Attorney</div>

      <div
        style="
          height: 370px;
          border: 1px solid #c3d1d2;
          border-radius: 20px;
          position: relative;
        "
      >
        <vue-scroll :ops="ops">
          <v-list v-show="!loading" style="background-color: transparent">
            <v-list-item-group
              v-if="attorneys.length > 0"
              class="py-2"
              v-model="chosenAttorney"
            >
              <v-list-item
                v-for="item in attorneys"
                :key="item.id"
                class="py-2 px-12"
              >
                <v-avatar v-if="item.avatar" size="40">
                  <v-img :src="item.avatar" />
                </v-avatar>
                <v-avatar
                  v-else
                  class="white--text font-weight-medium"
                  style="text-transform: uppercase"
                  :color="
                    avatarBackgroundColor(`${item.firstName} ${item.lastName}`)
                  "
                  size="40"
                >
                  {{ item.firstName.slice(0, 1)
                  }}{{ item.lastName.slice(0, 1) }}
                </v-avatar>
                <span class="ml-8 secondary--text d-flex flex-column">
                  <span>{{ `${item.firstName} ${item.lastName}` }}</span>
                </span>
              </v-list-item>
            </v-list-item-group>
            <v-list-item v-else class="py-1">
              <span class="secondary--text">No attorney found.</span>
            </v-list-item>
          </v-list>
        </vue-scroll>
      </div>
    </div>

    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-end py-4 pr-12 mt-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="cancel"
      >
        cancel
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        :disabled="!isAttorneyChosen"
        @click="next"
      >
        save & continue
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectAttorney",
  data() {
    return {
      chosenAttorney: "",
      loading: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          opacity: 0,
          size: "8px",
          specifyBorderRadius: false,
          gutterOfEnds: "10px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "8px",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      attorneys: "attorney/attorneys",
      currentCase: "eviction/currentCase",
    }),
    isAttorneyChosen() {
      return this.chosenAttorney !== null && this.chosenAttorney !== -1;
    },
  },
  activated() {
    window.scrollTo(0, 0);
    this.chosenAttorney = this.attorneys
      .map((attorney) => attorney.id)
      .indexOf(this.currentCase.attorneyId);
  },
  methods: {
    avatarBackgroundColor(avatarString) {
      return this.createAvatarBackgroundColor(avatarString);
    },
    next() {
      this.$store.commit("eviction/set_current_case", {
        attorneyId: this.attorneys[this.chosenAttorney].id,
      });
      this.$router.push({ name: "vc-before-client" });
    },
    cancel() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
.primary-input-field.theme--light.v-text-field--solo
  > .v-input__control
  > .v-input__slot {
  background-color: #4dccc4;
}
.primary-input-field.v-text-field.v-text-field--solo
  .v-input__control
  input::placeholder,
.primary-input-field.v-text-field.v-text-field--solo .v-input__control input {
  color: white;
  text-align: center;
}
</style>
