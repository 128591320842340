import Vue from "vue";
import useRequest from "@/utils/useRequest";
import axios from "axios";

export default {
  namespaced: true,
  state: {
    clients: [],
    loaded: false,
    importClientProgress: 0,
    errors: [],
    editingClient: null,
  },
  mutations: {
    set_clients(state, clients) {
      Vue.set(state, "clients", clients);
    },
    set_loaded(state, loaded) {
      Vue.set(state, "loaded", loaded);
    },
    set_import_client_progress(state, progress) {
      Vue.set(state, "importClientProgress", progress);
    },
    set_errors(state, errors) {
      Vue.set(state, "errors", errors);
    },
    set_editing_client(state, editingClient) {
      Vue.set(state, "editingClient", editingClient);
    },
  },
  actions: {
    async loadClients({ commit, rootGetters }) {
      const { data: clients } = await useRequest({
        path: `attorney/clients?firmId=${rootGetters.firm.id}`,
        // showLoading: true,
      });
      commit("set_clients", clients);
    },
    async saveClient({ state, commit, rootGetters }, clientInfo) {
      const { data: res } = await useRequest({
        method: "post",
        path: "attorney/clients",
        data: { clientInfo, firmId: rootGetters.firm.id },
        showLoading: true,
      });
      if (res.success) {
        if (clientInfo.id || clientInfo.infoType === "billing") {
          const clients = state.clients.map((client) => {
            if (client.id === clientInfo.id)
              return { ...client, ...clientInfo };
            else return client;
          });
          commit("set_clients", clients);
        } else {
          commit("set_clients", [
            ...state.clients,
            { ...clientInfo, cases: 0, id: res.addedId },
          ]);
        }
      }
    },
    async insertClients({ commit, state, rootGetters }, newClients) {
      const { data: res } = await axios.post(
        process.env.VUE_APP_API_URL + "/attorney/multiple-clients",
        {
          clients: newClients,
          firmId: rootGetters.firm.id,
        },
        {
          headers: {
            userType: "attorney",
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable) {
              const percentCompleted = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              commit("set_import_client_progress", percentCompleted);
            }
          },
        }
      );
      if (!res.success) {
        commit("set_errors", res.errors);
      } else {
        commit("set_errors", []);
        commit("set_clients", [...state.clients, ...res.addClients]);
      }
    },
    async deleteClient({ commit, state }, id) {
      const { data: res } = await useRequest({
        method: "delete",
        path: "attorney/clients",
        data: { id },
        showLoading: true,
      });
      if (res.success) {
        commit(
          "set_clients",
          state.clients.filter((item) => item.id !== id)
        );
      }
    },
  },
  getters: {
    clients: (state) => state.clients,
    loaded: (state) => state.loaded,
    importClientProgress: (state) => state.importClientProgress,
    errors: (state) => state.errors,
    editingClient: (state) => state.editingClient,
  },
};
