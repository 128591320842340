<template>
  <div>
    <div class="px-16 sticky-header">
      <div class="d-flex mb-8 page-subheader">
        <div class="d-flex align-center">
          <div class="secondary--text custom-heading mr-12">Clients</div>
          <v-btn
            fab
            depressed
            color="primary"
            height="45"
            width="45"
            @click="addClient"
          >
            <span
              class="white--text font-weight-regular"
              style="font-size: 50px"
              >+</span
            >
          </v-btn>
        </div>

        <div class="d-flex pl-8 mt-4 ml-auto">
          <!-- search start -->
          <v-menu
            v-model="searchMenu"
            :close-on-content-click="false"
            :nudge-width="200"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="px-4 subheader-btn ml-auto"
                v-bind="attrs"
                v-on="on"
              >
                <i class="icofont-search-1" color="grey" />&nbsp;Search
              </v-btn>
            </template>

            <v-card
              style="box-shadow: 15px 15px 40px #00000029; border-radius: 20px"
            >
              <div class="px-8 py-4 table-custom-search">
                <input
                  v-model="search"
                  type="text"
                  placeholder="Search Clients"
                />
              </div>
            </v-card>
          </v-menu>
          <!-- search end -->
        </div>
      </div>
    </div>

    <ClientTable :search="search" />
  </div>
</template>

<script>
import ClientTable from "@/components/tables/ClientTable";

export default {
  name: "Clients",
  components: {
    ClientTable,
  },
  data() {
    return {
      search: "",
      searchMenu: false,
    };
  },
  activated() {
    window.scrollTo(0, 0);
  },
  methods: {
    addClient() {
      this.$store.commit("client/set_editing_client", {
        id: null,
        firstName: "",
        lastName: "",
        company: "",
        streetAddress: "",
        unitNo: "",
        city: "",
        state: "New Jersey",
        zipcode: "",
        phone: "",
        phoneType: "office",
        ext: "",
        cell: "",
        email: "",
      });
      this.$router.push({ name: "single-client-contact" });
    },
  },
};
</script>

<style scoped>
.v-menu__content {
  margin-top: 40px;
  border-radius: 15px;
}
</style>
