<template>
  <div class="mx-auto secondary--text">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$emit('close:form')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div style="width: 80%" class="pt-10 pb-4 mx-auto text-left">
      <div class="custom-title pt-8 pb-16">Tell us about your client.</div>
      <div
        v-for="btn in btns"
        :key="btn.text"
        class="d-flex align-center justify-center my-6 mt-8"
        :class="[selected === btn.text ? 'selection-selected' : 'selection']"
        style="width: 100%"
        @click="typeClicked(btn)"
      >
        <span class="font-weight-medium" style="letter-spacing: 1.68px">
          {{ btn.text.toUpperCase() }}
        </span>
      </div>
    </div>

    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-end py-4 pr-12 mt-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="back"
      >
        go back
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        :disabled="!valid"
        @click="next"
      >
        save & continue
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BeforeSelectClient",
  data() {
    return {
      selected: "",
      btns: [
        {
          text: "Select Existing Client",
          route: { name: `vc-select-client` },
        },
        {
          text: "Add New Client",
          route: { name: `vc-client-contact` },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      caseSteps: "eviction/caseSteps",
      clientsLoaded: "client/loaded",
    }),
    valid() {
      return this.selected;
    },
  },
  activated() {
    window.scrollTo(0, 0);
    if (!this.$auth.isAuthenticated) return;
  },
  methods: {
    next() {
      this.$router.push(this.selected.route);
    },
    back() {
      this.$router.push({ name: "vc-select-attorney" });
    },
    async typeClicked(item) {
      this.selected = item;
      if (item.text == "Add New Client")
        this.$store.commit("eviction/set_is_new_client", true);
      else {
        this.$store.commit("eviction/set_is_new_client", false);
        if (!this.clientsLoaded)
          await this.$store.dispatch("client/loadClients");
      }
      this.next();
    },
  },
};
</script>
