<template>
  <div>
    <div class="progress-panel">
      <v-progress-linear
        color="primary"
        background-color="green_back"
        v-model="progress"
        height="20"
        rounded
        class="progress-bar"
      />
      <span v-if="progress < 100" class="pt-4"
        >Working on importing your data. Please wait.</span
      >
      <span v-else class="pt-4">Import finished.</span>
    </div>

    <v-card-actions class="mt-16 mb-8 d-flex justify-end">
      <v-btn
        color="secondary"
        class="mr-4 btn--plain capital--btn"
        rounded
        text
        @click="back"
      >
        Go Back
      </v-btn>
      <v-btn
        color="primary"
        class="px-10 white--text"
        rounded
        depressed
        :disabled="progress < 100"
        @click="next"
      >
        Next
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Import",
  props: {
    excelData: Array,
  },
  computed: {
    ...mapGetters({
      progress: "client/importClientProgress",
    }),
  },
  created() {
    this.startBuffer();
  },
  methods: {
    async startBuffer() {
      await this.$store.dispatch("client/insertClients", this.excelData);
    },
    back() {
      this.$emit("updateStep", "back");
    },
    next() {
      this.$emit("updateStep", "next");
    },
  },
};
</script>

<style scoped>
.progress-panel {
  width: 100%;
  padding: 15% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.progress-bar {
  width: 60%;
  border-radius: 10px;
}
</style>
