<template>
  <div class="mt-16 mx-8">
    <v-card
      rounded
      class="success-panel secondary--text"
      style="
        box-shadow: 15px 15px 40px #00000029;
        border-radius: 20px !important;
      "
    >
      <v-icon color="primary" style="font-size: 70px"
        >mdi-checkbox-marked-circle-outline</v-icon
      >
      <span class="mt-4 font-weight-medium" style="font-size: 40px"
        >Success! Your account has been created.</span
      >
      <span class="pt-4"
        >An activation link has been sent to <strong>{{ firm.email }}</strong
        >.</span
      >
    </v-card>

    <v-card-actions class="mt-16 mb-8 d-flex justify-end">
      <v-btn
        color="secondary"
        class="mr-4 btn--plain capital--btn"
        rounded
        text
        @click="back"
      >
        Go Back
      </v-btn>
      <v-btn
        color="primary"
        class="px-10 white--text"
        rounded
        depressed
        @click="complete"
      >
        Complete
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Complete",
  computed: {
    ...mapGetters({
      firm: "firm",
    }),
  },
  methods: {
    back() {
      this.$emit("updateStep", "back-from-end");
    },
    complete() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.success-panel {
  width: 100%;
  padding: 10% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
