<template>
  <v-card class="secondary--text rounded-card" @close:dialog="close">
    <v-btn color="info" icon class="float-right ma-3 btn--plain" @click="close">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div class="pa-10">
      <div class="headline font-weight-medium text-center pt-10">
        Public Housing Offices
      </div>
      <v-card-text class="pa-0">
        <div class="secondary--text font-weight-medium my-2 text-center">
          Select the Public Housing office for this property.
        </div>

        <div class="d-flex justify-space-between">
          <div
            v-for="n in 26"
            :key="n"
            class="letter-btn pa-2 font-weight-medium"
            :class="n === letter ? 'primary--text' : 'info--text'"
            @click="clickLetter(n)"
          >
            {{ String.fromCharCode(64 + n) }}
          </div>
          <div
            class="letter-btn pa-2 font-weight-medium"
            @click="clickLetter(27)"
          >
            <v-icon :color="letter === 27 ? 'primary' : 'info'"
              >icofont-plus-circle</v-icon
            >
          </div>
        </div>

        <v-container class="pa-4">
          <v-radio-group
            v-if="letter !== 27"
            v-model="selectedOffice"
            class="my-0 primary-radio-group"
            hide-details
            row
            @change="isSelected = true"
          >
            <v-row>
              <v-col
                v-for="office in letterOffices"
                :key="office.socialServiceAddresseId"
                cols="6"
                class="secondary--text py-2 d-flex align-start"
              >
                <v-radio
                  :value="office"
                  on-icon="mdi-checkbox-blank-circle"
                  class="ml-4 mt-1"
                />
                <div>
                  <div class="font-weight-bold">
                    {{ office.housingAuthority }}
                  </div>
                  <div>{{ office.address }}</div>
                  <div>{{ office.address2 }}</div>
                  <div>
                    {{ office.city }},
                    {{ office.state }}
                    {{ office.zipcode }}
                  </div>
                  <div>
                    <v-icon v-if="office.phone" color="primary" x-small>
                      <!-- mdi-cellphone-iphone </v-icon -->
                      mdi-cellphone</v-icon
                    >
                    {{ office.phone }}
                  </div>
                  <div>
                    <v-icon v-if="office.email" color="primary" x-small>
                      $email
                    </v-icon>
                    {{ office.email }}
                  </div>
                  <div
                    v-if="
                      office.housingAuthority ===
                      selectedOffice.housingAuthority
                    "
                  >
                    <div class="font-italic py-2">Address to:</div>
                    <v-text-field
                      v-model="selectedOffice.to"
                      rounded
                      hide-details="auto"
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                      class="pb-4"
                      placeholder="First and Last Name"
                    />
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-radio-group>
          <v-form
            v-else
            ref="form"
            v-model="formValid"
            style="width: 80%"
            class="pt-4 pb-4 mx-auto text-left"
          >
            <FormLabel>Name</FormLabel>
            <v-text-field
              v-model="housingAuthority"
              rounded
              solo
              flat
              dense
              background-color="#F0F5F6"
              class="pb-2"
            />
            <FormLabel>County</FormLabel>
            <v-select
              v-model="county"
              item-color="#F6F9FA"
              rounded
              item-text="countyName"
              solo
              flat
              dense
              background-color="#F0F5F6"
              :items="counties"
              class="pb-2"
              :rules="[]"
              return-object
              @blur="isCountyTouchedOrSkipped = true"
            >
              <v-icon slot="append" medium color="accent">
                mdi-chevron-down
              </v-icon>
            </v-select>
            <div
              v-if="showCountyRequired"
              class="error--text mt-n9 mb-3 ml-3 text--lg"
              style="font-size: 16px"
            >
              Item is required
            </div>
            <div class="text-left">
              <FormLabel>Street Address</FormLabel>
              <v-text-field
                v-model="address"
                rounded
                solo
                flat
                dense
                background-color="#F0F5F6"
                class="pb-2"
                :rules="[required]"
                @click="isCountyTouchedOrSkipped = true"
              />
              <FormLabel>City</FormLabel>
              <v-autocomplete
                v-model="city"
                :items="cities"
                :loading="loading"
                :search-input.sync="search"
                hide-no-data
                hide-selected
                item-text="cityName"
                placeholder="Start typing to Search"
                return-object
                rounded
                solo
                flat
                dense
                background-color="#F0F5F6"
                class="pb-2"
                :rules="[required]"
                @click="isCountyTouchedOrSkipped = true"
              >
                <!-- <template slot="selection" slot-scope="data">
                  {{ displayCityInfo(data.item) }}
                </template>
                <template slot="item" slot-scope="data">
                  {{ displayCityInfo(data.item) }}
                </template> -->
                <v-icon slot="append" medium color="accent">
                  mdi-chevron-down
                </v-icon>
              </v-autocomplete>

              <FormLabel>State</FormLabel>
              <v-text-field
                v-model="state"
                item-color="#F6F9FA"
                rounded
                solo
                flat
                dense
                disabled
                background-color="#F0F5F6"
                class="pb-2"
                :rules="[required]"
              />

              <FormLabel>Zip Code</FormLabel>
              <v-text-field
                v-model="zipcode"
                rounded
                solo
                flat
                dense
                background-color="#F0F5F6"
                class="pb-2"
                :rules="[required]"
              />

              <FormLabel>Phone</FormLabel>
              <v-text-field
                v-model="phone"
                rounded
                :rules="[required]"
                solo
                flat
                dense
                background-color="#F0F5F6"
                class="pb-2"
              />

              <FormLabel>Email</FormLabel>
              <v-text-field
                v-model="email"
                rounded
                solo
                flat
                dense
                background-color="#F0F5F6"
                class="pb-2"
                :rules="[emailValid]"
              />
            </div>
            <v-btn
              rounded
              color="accent_light"
              class="d-flex ml-auto px-8 mb-2 mt-4 white--text capital--btn"
              depressed
              :disabled="!formValid"
              @click="addOffice"
            >
              add
            </v-btn>
          </v-form>
        </v-container>
      </v-card-text>
    </div>
    <v-card-actions v-if="letter !== 27" class="justify-end py-6 px-12">
      <v-btn color="accent_light" text class="btn--plain" @click="close">
        cancel
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 white--text capital--btn"
        depressed
        :disabled="!valid"
        @click="submit"
      >
        save & continue
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import useRequest from "@/utils/useRequest";

export default {
  name: "SocialServicesDialog",
  props: {
    dialogCase: {
      type: Object,
      default: () => ({}),
    },
    dialogAction: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allOffices: [],
      letterOffices: [],
      selectedOffice: {},
      isSelected: false,
      housingAuthority: "",
      address: "",
      city: "",
      county: "",
      state: "New Jersey",
      zipcode: "",
      phone: "",
      email: "",
      letter: 1,
      formValid: false,
      isCountyTouchedOrSkipped: false,
      counties: [],
      required: (value) => !!value || "Item is required",
      emailValid: (value) => /.+@.+\..+/.test(value) || "E-mail must be valid",
      search: null,
      cities: [],
      loading: false,
    };
  },
  computed: {
    valid() {
      return this.isSelected;
    },
    showCountyRequired() {
      return !this.county && this.isCountyTouchedOrSkipped;
    },
  },
  watch: {
    county(newCounty, prevCounty) {
      const newCountyName = newCounty?.countyName;
      const prevCountyName = prevCounty?.countyName;
      if (newCountyName && newCountyName !== prevCountyName)
        this.getCitiesByCounty(newCountyName);
    },
    phone(value) {
      this.phone = this.formatPhone(value);
    },
  },
  async created() {
    this.$http
      .get(`${process.env.VUE_APP_API_URL}/socialServiceOffices`)
      .then((r) => r.data)
      .then((data) => {
        this.allOffices = data;
        this.letterOffices = this.allOffices.filter(
          (o) => o.housingAuthority?.startsWith("A")
        );
      });
    await this.getCountiesOfState();
  },
  methods: {
    async addOffice() {
      await useRequest({
        path: "socialServiceOffices",
        method: "post",
        data: {
          housingAuthority:
            this.housingAuthority.charAt(0).toUpperCase() +
            this.housingAuthority.slice(1),
          address: this.address,
          city: this.city.cityName,
          // county: this.county.county,
          state: "New Jersey",
          zipcode: this.zipcode,
          phone: this.phone,
          email: this.email,
        },
      });
      const { data } = await useRequest({
        path: "socialServiceOffices",
      });
      this.allOffices = data;
      this.letterOffices = this.allOffices.filter(
        (o) => o.housingAuthority?.startsWith("A")
      );
      this.housingAuthority = "";
      this.address = "";
      this.city = "";
      this.county = "";
      this.state = "";
      this.zipcode = "";
      this.phone = "";
      this.email = "";
    },
    formatPhone(phone) {
      const cleaned = ("" + phone).replace(/\D/g, "");
      const zip = cleaned.substring(0, 3);
      const mid = cleaned.substring(3, 6);
      const last = cleaned.substring(6, 10);
      if (cleaned.length > 6) return `(${zip}) ${mid}-${last}`;
      else if (cleaned.length > 3) return `(${zip}) ${mid}`;
      return `${zip}`;
    },
    async getCountiesOfState() {
      await useRequest({
        path: "countiesOfState",
        params: {
          stateId: 31,
        },
        onSuccess: (counties) => {
          this.counties = counties;
        },
      });
    },
    async getCitiesByCounty(county) {
      if (!county) return;
      const { data: cities } = await useRequest({
        path: "citiesByCounty",
        params: {
          county,
        },
      });
      if (!cities?.length) return;
      this.cities = cities;
      if (!cities.find((city) => city.cityName === this.city?.cityName))
        this.city = null;
      this.$refs.form.resetValidation();
    },
    clickLetter(n) {
      this.letter = n;
      if (n === 27) {
        return;
      } else {
        const letter = String.fromCharCode(64 + n);
        this.letterOffices = this.allOffices.filter(
          (o) => o.housingAuthority?.startsWith(letter)
        );
      }
    },
    submit() {
      this.$emit("update:office", this.selectedOffice);
    },
    close() {
      this.$emit("close:dialog");
    },
  },
};
</script>

<style>
.letter-btn:hover {
  cursor: pointer;
}
</style>
