<template>
  <v-card class="new-message-modal" style="border-radius: 20px; height: 90vh">
    <div class="close-btn">
      <v-btn color="info" icon @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <vue-scroll :ops="ops" ref="scrollRef">
      <v-form style="margin-bottom: 140px">
        <div class="pa-12 secondary--text">
          <v-card-title class="mb-4 font-weight-medium"
            >New Message</v-card-title
          >
          <div class="ml-4">
            <v-row>
              <v-col cols="6">
                <span>Client</span>
                <v-select
                  v-model="newMessage.clientId"
                  :items="clientList"
                  item-text="fullName"
                  item-value="id"
                  background-color="#F0F5F6"
                  item-color="#F6F9FA"
                  rounded
                  solo
                  flat
                  dense
                >
                  <v-icon slot="append" medium color="accent">
                    mdi-chevron-down
                  </v-icon>
                </v-select>
              </v-col>
              <v-col cols="6">
                <span>Case</span>
                <v-select
                  v-model="newMessage.caseId"
                  :items="caseList"
                  item-text="caseParties"
                  item-value="id"
                  background-color="#F0F5F6"
                  item-color="#F6F9FA"
                  rounded
                  solo
                  flat
                  dense
                >
                  <v-icon slot="append" medium color="accent">
                    mdi-chevron-down
                  </v-icon>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>Subject</span>
                <v-text-field
                  v-model="newMessage.subject"
                  background-color="#F0F5F6"
                  rounded
                  solo
                  flat
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>Message</span>
                <v-textarea
                  v-model="newMessage.content"
                  background-color="#F0F5F6"
                  rounded
                  solo
                  flat
                  dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-btn plain :loading="uploading" @click="onUpload">
                <span class="mr-2">Upload document</span>
                <v-icon color="primary" size="x-large">mdi-paperclip</v-icon>
              </v-btn>
              <input
                ref="uploader"
                class="d-none"
                type="file"
                accept="application/pdf,.docx"
                @change="onUploadFileChanged"
              />
            </v-row>
          </div>
        </div>
      </v-form>
    </vue-scroll>

    <div class="card-actions" style="margin-top: -20px">
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="cancel"
      >
        Cancel
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-12 mb-2 mt-4 ml-8 white--text capital--btn"
        depressed
        :disabled="!valid"
        @click="send"
      >
        Send
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import useRequest from "@/utils/useRequest";
import { combineTenants } from "@/utils";

export default {
  name: "NewMessageDialog",
  props: {
    fromCaseView: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      caseList: null,
      newMessage: {
        clientId: null,
        caseId: null,
        subject: "",
        content: "",
        file: null,
      },
      uploading: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          // background: '#EAEBEB',
          opacity: 0,
          size: "10px",
          specifyBorderRadius: false,
          gutterOfEnds: "85px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "10px",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      clients: "client/clients",
      clientsLoaded: "client/loaded",
      cases: "eviction/cases",
      currentCase: "eviction/currentCase",
    }),
    client() {
      return this.newMessage.clientId;
    },
    clientList() {
      return this.clients.map((item) => {
        return {
          id: item.id,
          fullName: item.firstName + " " + item.lastName,
        };
      });
    },
    valid() {
      return (
        this.newMessage.clientId &&
        this.newMessage.caseId &&
        this.newMessage.subject
      );
    },
  },
  watch: {
    client(selectedClientId) {
      if (!selectedClientId) return;
      const matchedCases = this.cases.filter(
        (item) => item.clientId === selectedClientId
      );
      this.caseList = this.getCaseList(matchedCases);
    },
  },
  async created() {
    this.caseList = this.getCaseList(this.cases);
    if (!this.clientsLoaded) {
      await this.$store.dispatch("client/loadClients");
      this.$store.commit("client/set_loaded", true);
    }
    if (this.fromCaseView) {
      this.newMessage.clientId = this.currentCase.clientId;
      this.newMessage.caseId = this.currentCase.id;
    }
  },
  methods: {
    initMessage() {
      if (this.fromCaseView) {
        this.newMessage = {
          clientId: this.newMessage.clientId,
          caseId: this.newMessage.caseId,
          subject: "",
          content: "",
          file: null,
        };
      } else {
        this.newMessage = {
          clientId: null,
          caseId: null,
          subject: "",
          content: "",
          file: null,
        };
      }
    },
    getCaseList(items) {
      return items.map((item) => {
        return {
          id: item.id,
          caseParties:
            (item.docketNo ?? "LT-") +
            " / " +
            (item.deedNames ?? "Pending") +
            " vs. " +
            (combineTenants(item.tenants) ?? "Pending"),
        };
      });
    },
    onUpload() {
      this.uploading = true;
      window.addEventListener(
        "focus",
        () => {
          this.uploading = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onUploadFileChanged(e) {
      try {
        this.newMessage.file = e.target.files[0];
      } catch (error) {
        console.log(`error`, error);
      } finally {
        this.$refs.uploader.value = null;
      }
    },
    async send() {
      if (this.newMessage.file) {
        const formData = new FormData();
        formData.append("caseId", this.newMessage.caseId);
        formData.append("docName", this.newMessage.file.name);
        formData.append("file", this.newMessage.file);
        await useRequest({
          path: "upload",
          method: "post",
          data: formData,
        });
      }
      this.$emit("send", {
        ...this.newMessage,
        recipientId: this.newMessage.clientId,
      });
      this.$refs.scrollRef.scrollTo({ x: 0, y: 0 }, 0);
      this.initMessage();
    },
    cancel() {
      this.$emit("close");
      this.$refs.scrollRef.scrollTo({ x: 0, y: 0 }, 0);
      this.initMessage();
    },
  },
};
</script>

<style>
.new-message-modal {
  overflow-y: visible;
}

.new-message-modal .v-text-field__details {
  display: none;
}
</style>

<style scoped>
.__vuescroll >>> .__panel {
  overscroll-behavior: contain;
}

.close-btn {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1;
}
.v-btn >>> .v-btn__content {
  opacity: 1 !important;
}
</style>
