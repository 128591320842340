<template>
  <div class="clients-table">
    <v-data-table
      v-if="clients.length > 0"
      v-model="selected"
      :headers="headers"
      :header-props="{ 'sort-icon': 'mdi-menu-up' }"
      :must-sort="true"
      :items="clients"
      :search="search"
      :custom-filter="customFilter"
      item-key="ID"
      class="my-table"
      :items-per-page="itemsPerPage"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-skip-previous',
        lastIcon: 'mdi-skip-next',
        prevIcon: 'mdi-menu-left',
        nextIcon: 'mdi-menu-right',
        itemsPerPageText: 'Number of clients to display',
        pageText: pageText,
      }"
      @page-count="pageCount = $event"
      @update:options="updateOptions"
    >
      <template v-slot:item="{ item, index }">
        <tr
          @mouseover="rowHoverIndex = index"
          @mouseleave="rowHoverIndex = -1"
          :class="{ 'on-hover': rowHoverIndex === index }"
          class="data-row"
        >
          <td class="first-column">
            <div class="d-flex flex-row align-center">
              <v-avatar v-if="item.avatar" size="40">
                <v-img :src="item.avatar" />
              </v-avatar>
              <v-avatar
                v-else
                size="40"
                :color="
                  avatarBackgroundColor(`${item.firstName} ${item.lastName}`)
                "
                class="white--text"
              >
                {{
                  item.firstName.slice(0, 1).toUpperCase() +
                  item.lastName.slice(0, 1).toUpperCase()
                }}
              </v-avatar>
              <span class="d-flex flex-column">
                <span class="pl-4 font-weight-medium">
                  {{ item.firstName }} {{ item.lastName }}
                </span>
                <span class="pl-4">
                  {{ item.company }}
                </span>
              </span>
            </div>
          </td>
          <td>
            {{ $dayjs(item.retained_at).format("MM/DD/YYYY") }}
          </td>
          <td>
            {{
              item.lastActivity
                ? $dayjs(item.lastActivity).format("MM/DD/YYYY")
                : "No activity"
            }}
          </td>
          <td>
            {{ item.cases }}
          </td>
          <td class="py-2">
            <tr>
              <td>
                <tr>
                  {{
                    item.streetAddress
                  }}
                </tr>
                <tr>
                  {{
                    item.city
                  }},
                  {{
                    item.state
                  }}
                  {{
                    item.zipcode
                  }}
                </tr>
                <tr>
                  Email:
                  {{
                    item.email
                  }}
                </tr>
                <tr>
                  Mobile Phone:
                  {{
                    item.phone
                  }}
                </tr>
                <tr>
                  Home/Office Phone:
                  {{
                    item.cell
                  }}
                  {{
                    item.ext
                  }}
                </tr>
              </td>
              <td>
                <v-icon color="accent" size="30" @click="editContactInfo(item)">
                  mdi-pencil-outline
                </v-icon>
              </td>
            </tr>
          </td>
          <td class="py-2">
            <tr>
              <td>
                <tr>{{ item.chargeType }}:</tr>
                <tr>Verified Complaint: {{ convertToCurrency(item.vcFee) }}</tr>
                <tr>Court Appearance: {{ convertToCurrency(item.courtAppearanceFee) }}</tr>
                <tr>Warrant of Removal: {{ convertToCurrency(item.worFee) }}</tr>
              </td>
              <td>  
                <v-icon color="accent" size="30" @click="editBillingInfo(item)">
                  mdi-pencil-outline
                </v-icon>
              </td>
            </tr>
          </td>
          <td>
            <v-icon
              v-if="!item.cases"
              class="ml-4"
              size="30"
              @click="deleteClient(item.id)"
            >
              mdi-delete
            </v-icon>
          </td>
        </tr>
        <td :colspan="headers.length" class="row-divider"></td>
      </template>
      <template v-if="clients.length !== 0" slot="body.append">
        <tr style="height: 30px"></tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ClientTable",
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Client",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
        },
        {
          text: "Retained",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
        },
        {
          text: "Last Activity",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
        },
        {
          text: "Cases",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Contact Information",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Billing",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          class: "header-text secondary--text font-weight-medium",
          align: "center",
          sortable: false,
        },
      ],
      selected: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      rowHoverIndex: false,
    };
  },
  computed: {
    ...mapGetters({
      clients: "client/clients",
      loaded: "client/loaded",
    }),
    pageText() {
      return "Pages " + this.page + ` / ` + this.pageCount;
    },
  },
  async created() {
    if (!this.loaded) {
      await this.$store.dispatch("client/loadClients");
      this.$store.commit("client/set_loaded", true);
    }
  },
  methods: {
    editContactInfo(item) {
      this.$store.commit("client/set_editing_client", {
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        company: item.company,
        streetAddress: item.streetAddress,
        unitNo: item.unitNo,
        city: item.city,
        state: item.state,
        zipcode: item.zipcode,
        phone: item.phone,
        phoneType: item.phoneType,
        ext: item.ext,
        cell: item.cell,
        email: item.email,
        chargeType: item.chargeType,
        vcFilingFeesIncluded: item.vcFilingFeesIncluded,
        worFilingFeesIncluded: item.worFilingFeesIncluded,
        vcFee: item.vcFee,
        courtAppearanceFee: item.courtAppearanceFee,
        worFee: item.worFee,
        paymentDueType: item.paymentDueType,
        paymentDueDays: item.paymentDueDays,
      });
      this.$router.push({ name: "single-client-contact" });
    },
    editBillingInfo(item) {
      this.$store.commit("client/set_editing_client", {
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        company: item.company,
        streetAddress: item.streetAddress,
        unitNo: item.unitNo,
        city: item.city,
        state: item.state,
        zipcode: item.zipcode,
        phone: item.phone,
        phoneType: item.phoneType,
        ext: item.ext,
        cell: item.cell,
        email: item.email,
        chargeType: item.chargeType,
        vcFilingFeesIncluded: item.vcFilingFeesIncluded,
        worFilingFeesIncluded: item.worFilingFeesIncluded,
        vcFee: item.vcFee,
        courtAppearanceFee: item.courtAppearanceFee,
        worFee: item.worFee,
        paymentDueType: item.paymentDueType,
        paymentDueDays: item.paymentDueDays,
      });
      this.$router.push({ name: "single-client-billing" });
    },
    deleteClient(id) {
      const sure = confirm("Are you sure?");
      if (sure) {
        this.$store.dispatch("client/deleteClient", id);
      }
    },
    avatarBackgroundColor(avatarString) {
      return this.createAvatarBackgroundColor(avatarString);
    },
    customFilter(_value, search, items) {
      Object.keys(items).forEach((key) => {
        if (items[key] === null) items[key] = "";
      });

      return (
        (items.firstName.toString() + " " + items.lastName.toString())
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        items.company.toString().toLowerCase().includes(search.toLowerCase()) ||
        (
          items.streetAddress.toString() +
          items.city.toString() +
          ", " +
          items.state.toString() +
          " " +
          items.zipcode.toString() +
          items.email.toString() +
          items.phone.toString() +
          items.cell.toString() +
          items.ext.toString()
        )
          .toLowerCase()
          .includes(search.toLowerCase())
      );
    },
    updateOptions(option) {
      this.page = option.page;
    },
  },
};
</script>

<style>
.clients-table .v-data-table__wrapper {
  padding: 0px 50px 50px;
}
.clients-table .v-data-table__wrapper > table {
  box-shadow: 15px 15px 40px #00000029;
  border-radius: 20px;
  min-width: 1000px;
}
.clients-table
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  height: 65px !important;
  background-color: white;
  text-transform: uppercase;
}

.v-data-table.rounded-table.theme--light {
  border-radius: 20px;
}
.v-data-table td.custom-expanded-row {
  background-color: #f8fafb;
  padding: 4px 16px;
}
.v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.v-data-table thead th.active.asc .v-data-table-header__icon {
  opacity: 1 !important;
}

.v-data-table thead th.active.desc .v-data-table-header__icon {
  opacity: 1 !important;
}

.v-data-table-header__icon {
  opacity: 1 !important;
}
.v-data-table-header tr th:first-child {
  padding-left: 36px !important;
}
</style>

<style scoped>
.data-row {
  color: #003a60 !important;
  height: 130px !important;
}
.data-row > td {
  border-bottom: none !important;
}
.add-client-row {
  height: 100px !important;
}
.data-row:hover,
.add-client-row:hover {
  background-color: transparent !important;
}
.first-column {
  border-left: 8px solid transparent;
}
.on-hover > .first-column {
  border-left: 8px solid #4dccc4;
}
.row-divider {
  height: 1px !important;
  background: #c3d1d2 !important;
}
.status-row {
  background-color: #f8fafb !important;
  color: #003a60 !important;
}
.status-grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  align-items: center;
  height: 65px !important;
  padding-right: 10px;
}
</style>
