<template>
  <div class="mx-auto secondary--text">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$emit('close:form')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div style="width: 80%" class="pt-15 pb-4 mx-auto text-left">
      <div class="custom-title pt-8 pb-4">Payment Plan</div>
      <div class="pb-4">
        Enter the amounts the parties settled upon as the agreed payment plan
        for the Consent to Enter Judgment - Tenant Remains.
      </div>

      <v-btn
        rounded
        color="primary"
        class="ml-4 mb-2 mt-4 btn--plain capital--btn"
        text
        @click="
          $router.push({ name: 'case-view-ledger', params: currentCase.id })
        "
      >
        View Ledger
      </v-btn>

      <div
        class="d-flex justify-space-between mx-8 mt-2 font-weight-medium secondary--text"
      >
        <span
          >Total Balance Due:
          {{ convertToCurrency(currentCase.pastDueRentPaid) }}</span
        >
        <span
          >Remaining Balance Due:
          {{
            convertToCurrency(currentCase.pastDueRentPaid - totalPayment)
          }}</span
        >
      </div>

      <v-form ref="formDate" class="px-8 pt-8 pb-4 mx-auto text-left">
        <v-row class="font-weight-medium secondary--text pb-3">
          <v-col cols="4">Amount Due</v-col>
          <v-col cols="8">Date(s) Due</v-col>
        </v-row>
        <div v-for="(paymentItem, index) in payments" :key="index">
          <v-row class="display-flex align-center">
            <v-col cols="4">
              <v-text-field
                v-model="paymentItem.amount"
                rounded
                type="number"
                solo
                flat
                dense
                background-color="#F0F5F6"
                style="height: 45px"
              >
                <div slot="prepend-inner">$</div>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="paymentItem.menu"
                :close-on-content-click="false"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="paymentItem.dateDueDisplay"
                    append-icon="$calendar"
                    color="accent"
                    readonly
                    rounded
                    solo
                    flat
                    dense
                    background-color="#F0F5F6"
                    v-on="on"
                    @click:append="paymentItem.menu = true"
                    style="height: 45px"
                  />
                </template>
                <v-date-picker
                  v-model="paymentItem.dateDue"
                  no-title
                  :show-current="false"
                  color="primary"
                  class="custom-date"
                  @input="closedDateDueMenu(paymentItem.dateDue, index)"
                />
              </v-menu>
            </v-col>
            <v-col cols="1">
              <v-icon
                v-if="payments.length > 1"
                size="xx-large"
                @click="deletePayment(index)"
                >mdi-close</v-icon
              >
            </v-col>
            <v-col cols="1">
              <v-btn
                v-if="index === payments.length - 1"
                color="primary"
                depressed
                icon
                :disabled="
                  !paymentItem.amount ||
                  paymentItem.amount === '0' ||
                  !paymentItem.dateDue
                "
                @click="addPayment(index)"
              >
                <v-icon size="xxx-large">mdi-plus-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </div>

    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-end py-4 pr-12 mt-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="goBack"
      >
        Go Back
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        :disabled="!valid"
        @click="next"
      >
        Save & Continue
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="warnDialog" persistent value="''" max-width="500">
      <WarnDialog
        color="accent"
        type="warning"
        text="The amount inserted in the Payment Plan is less than the amount due. Enter an additional payment or access the Ledger screen, and write off the balance due."
        @goToLedger="$router.push({ name: 'case-view-ledger' })"
        @goBack="warnDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WarnDialog from "@components/dialogs/WarnDialog";
import { courtOutcomesString, trialActionsString } from "@/utils/worFlowData";

export default {
  name: "PaymentPlan",
  components: {
    WarnDialog,
  },
  data() {
    return {
      valid: true,
      payments: [],
      consentTermRents: [],
      warnDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
    }),
    totalPayment() {
      if (!this.payments.length) return 0;
      return this.payments.reduce(
        (result, item) =>
          result +
          (item.amount !== "" && item.amount !== null && item.dateDue
            ? parseFloat(item.amount)
            : 0),
        0
      );
    },
  },
  activated() {
    window.scrollTo(0, 0);
    this.payments = this.currentCase.paymentPlan || [];
    if (!this.payments.length)
      this.payments = [
        {
          amount: null,
          dateDue: "",
          dateDueDisplay: "",
          menu: false,
        },
      ];
  },
  deactivated() {
    this.saveData();
    this.calculateConsentTermRents();
  },
  methods: {
    async saveData() {
      const payments = this.payments;
      this.payments.map((item, index) => {
        if (item.amount === null || item.amount === 0 || item.dateDue === "") {
          payments.splice(index, 1);
        }
      });
      await this.$store.dispatch("eviction/updateCase", {
        caseData: {
          paymentPlan: payments.length ? payments : null,
        },
        status: this.$route.name.includes("trial")
          ? "wor-paymentPlan"
          : "co-paymentPlan",
      });
    },
    addPayment() {
      this.payments.push({
        amount: null,
        dateDue: "",
        dateDueDisplay: "",
        menu: false,
      });
    },
    calculateConsentTermRents() {
      if (!this.currentCase.paymentPlan) return;
      this.consentTermRents = [];
      const lastPastDueRentDate = this.currentCase.pastDueRent.filter(
        (item) => item.selected
      )[0].timePeriod; // MM-DD-YYYY
      let firstConsentMonth = parseInt(lastPastDueRentDate.split("-")[0]) + 1;
      firstConsentMonth = firstConsentMonth % 12;
      const today = this.$dayjs().format("YYYY-MM-DD");
      const lastConsentMonth = parseInt(
        this.currentCase.paymentPlan.slice(-1)[0].dateDue.split("-")[1]
      );
      const rentDueBy = parseInt(this.currentCase.rentDueBy);
      if (lastConsentMonth >= firstConsentMonth) {
        for (let i = firstConsentMonth; i <= lastConsentMonth; i++) {
          const baseDate = `${i > 9 ? i : `0${i}`}-${
            lastPastDueRentDate.split("-")[1]
          }-${lastPastDueRentDate.split("-")[2]}`;
          const rent = {
            rentMonth: this.$dayjs(baseDate).format("MMMM, YYYY"),
            dueDate:
              this.$dayjs(baseDate).format("MM/") +
              (rentDueBy > 9 ? rentDueBy : `0${rentDueBy}`) +
              this.$dayjs(baseDate).format("/YYYY"),
            selectedDueDate: this.$dayjs(baseDate).format("YYYY-MM-DD"),
            amount: this.currentCase.rentAmount,
            menu: false,
            happened: this.$dayjs(baseDate).format("YYYY-MM-DD") < today,
            changed: false,
          };
          this.consentTermRents.push(rent);
        }
      } else {
        for (let i = firstConsentMonth; i <= 12; i++) {
          const baseDate = `${i > 9 ? i : `0${i}`}-${
            lastPastDueRentDate.split("-")[1]
          }-${lastPastDueRentDate.split("-")[2]}`;
          const rent = {
            rentMonth: this.$dayjs(baseDate).format("MMMM, YYYY"),
            dueDate:
              this.$dayjs(baseDate).format("MM/") +
              (rentDueBy > 9 ? rentDueBy : `0${rentDueBy}`) +
              this.$dayjs(baseDate).format("/YYYY"),
            selectedDueDate: this.$dayjs(baseDate).format("YYYY-MM-DD"),
            amount: this.currentCase.rentAmount,
            menu: false,
            happened: this.$dayjs(baseDate).format("YYYY-MM-DD") < today,
            changed: false,
          };
          this.consentTermRents.push(rent);
        }
        // next year
        const nextYear = parseInt(lastPastDueRentDate.split("-")[2]) + 1;
        for (let i = 1; i <= lastConsentMonth; i++) {
          const baseDate = `${i > 9 ? i : `0${i}`}-${
            lastPastDueRentDate.split("-")[1]
          }-${nextYear}`;
          const rent = {
            rentMonth: this.$dayjs(baseDate).format("MMMM, YYYY"),
            dueDate:
              this.$dayjs(baseDate).format("MM/") +
              (rentDueBy > 9 ? rentDueBy : `0${rentDueBy}`) +
              this.$dayjs(baseDate).format("/YYYY"),
            selectedDueDate: this.$dayjs(baseDate).format("YYYY-MM-DD"),
            amount: this.currentCase.rentAmount,
            menu: false,
            happened: this.$dayjs(baseDate).format("YYYY-MM-DD") < today,
            changed: false,
          };
          this.consentTermRents.push(rent);
        }
      }
      this.$store.dispatch("eviction/updateCase", {
        caseData: {
          consentTermRents: this.consentTermRents,
        },
        status: this.$route.name.includes("trial")
          ? "wor-paymentPlan"
          : "co-paymentPlan",
      });
    },
    next() {
      if (this.currentCase.pastDueRentPaid > this.totalPayment) {
        this.warnDialog = true;
        return;
      }
      if (this.$route.name.includes("trial")) {
        if (this.currentCase.trialAction === trialActionsString()[3]) {
          // Request for Warrant of Removal - Breach of Consent - Vacate with Payment Plan
          this.$router.push({ name: "wor-trial-confirm-breach" });
        } else if (this.currentCase.trialAction === trialActionsString()[6]) {
          // Executed Consent to Enter Judgment - Tenant Vacates with Payment Plan
          this.$router.push({ name: "wor-trial-additional-condition" });
        } else {
          this.$router.push({ name: "wor-trial-rent-consent-term" });
        }
      } else {
        if (this.currentCase.courtOutcome === courtOutcomesString()[2]) {
          this.$router.push({ name: "wor-rent-consent-term" });
        } else {
          this.$router.push({ name: "wor-additional-condition" });
        }
      }
    },
    goBack() {
      if (this.$route.name.includes("trial")) {
        switch (this.currentCase.trialAction) {
          case trialActionsString()[6]: // Executed Consent to Enter Judgment - Tenant Vacates with Payment Plan
            this.$router.push({ name: "wor-trial-move-out-date" });
            break;
          default:
            this.$router.push({ name: "wor-trial-action" });
            break;
        }
      } else {
        if (this.currentCase.courtOutcome === courtOutcomesString()[2]) {
          // Executed Consent to Enter Judgment - Tenant Pays and Stays
          this.$router.push({ name: "wor-court-outcome" });
        } else {
          this.$router.push({ name: "wor-move-out-date" });
        }
      }
    },
    closedDateDueMenu(date, index) {
      this.payments[index].menu = false;
      this.payments[index].dateDue = date;
      this.payments[index].dateDueDisplay =
        this.$dayjs(date).format("MMMM DD, YYYY");
    },
    deletePayment(index) {
      this.$delete(this.payments, index);
    },
  },
};
</script>

<style lang="scss">
.payments-form {
  &__form {
    // width: 70%;

    &-add-action {
      margin-top: -20px;
    }

    &-divider {
      margin: 24px auto;
    }
  }
}
</style>
