<template>
  <div>
    <v-card
      class="rounded-card"
      style="
        box-shadow: 15px 15px 40px #00000029;
        border-radius: 20px;
        min-width: 320px;
      "
    >
      <div
        class="current-status-header d-flex justify-space-between align-center px-10 py-6"
      >
        <p class="font-weight-medium">Current Status</p>
      </div>
      <div class="current-status-content px-10">
        <p class="red--text font-italic font-weight-bold">Coming Soon</p>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import getCaseStatusLabel from "@/utils/getCaseStatusLabel";

export default {
  name: "CurrentStatus",
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
    }),
    latestStatusLabel() {
      return getCaseStatusLabel(this.currentCase);
    },
  },
};
</script>

<style scoped>
.current-status-header {
  background-color: #4dccc4;
}
.current-status-header p {
  color: white !important;
  margin: 0;
  font-size: 1.25rem;
}
.current-status-header i {
  transform: rotate(20deg);
}
.current-status-content {
  padding: 40px 0 10px 0;
}
.current-status-footer {
  background-color: #f8fafb;
}

.bullet-priority {
  width: 16px;
  height: 16px;
  background: #ff854e;
  border: 1px solid #ff854e;
  border-radius: 50%;
  margin-right: 6px;
  align-self: center;
}

.step-status {
  text-align: center;
  padding: 5px 20px;
  width: 130px;
  border-radius: 23px;
  background-color: #dae3e9;
  font-size: 18px;
  color: #8ba4b4;
  font-style: italic;
}
</style>
