<template>
  <VerifiedComplaintBox
    :continue-btn-disabled="!valid"
    :vc-cancel-btn-props="{
      btnText: 'cancel',
    }"
    :vc-continue-btn-props="{
      btnText: 'Update',
    }"
    @vcBoxContinue="handleContinue"
    @vcBoxBack="$router.push({ name: 'case-view-overview' })"
    @vcBoxCancel="$router.push({ name: 'case-view-overview' })"
  >
    <div class="custom-title pt-8 pb-4">Trial</div>
    <div class="pb-4">
      Enter the information below as received from the court in the Case
      Management Conference. We will calendar all these critical dates so that
      you and your client don't miss any important events.
    </div>

    <v-form ref="form" v-model="valid" class="pt-10 pb-4 text-left">
      <div class="text-left">
        <div class="font-weight-medium secondary--text pb-2">Docket Number</div>
        <v-text-field
          v-model="docketNo"
          rounded
          solo
          flat
          dense
          background-color="#F0F5F6"
          :rules="[(v) => !!v || 'Item is required']"
        />
        <div class="font-weight-medium secondary--text pb-2">Trial Date</div>
        <div class="dateAndTime">
          <v-menu
            v-model="trialDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="trialDate"
                append-icon="$calendar"
                color="accent"
                readonly
                rounded
                solo
                flat
                dense
                background-color="#F0F5F6"
                v-on="on"
                @click:append="trialDateMenu = true"
              />
            </template>
            <v-date-picker
              v-model="selectedTrialDate"
              no-title
              :show-current="false"
              color="primary"
              class="custom-date"
              @input="closedTrialDateMenu"
              :rules="[(v) => !!v || 'Item is required']"
            />
          </v-menu>
        </div>
        <div class="font-weight-medium secondary--text pb-2">Trial Time</div>
        <div class="dateAndTime">
          <v-menu
            v-model="trialTimeMenu"
            transition="scale-transition"
            offset-y
            max-width="300px"
            :close-on-content-click="false"
            :nudge-right="10"
            :nudge-top="25"
            rounded="xl"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="trialTime"
                append-icon="mdi-clock-time-nine-outline"
                color="accent"
                readonly
                rounded
                solo
                flat
                dense
                background-color="#F0F5F6"
                v-on="on"
                @click:append="trialTimeMenu = true"
                :rules="[(v) => !!v || 'Item is required']"
              />
            </template>
            <EVTimePicker @meridiemClicked="evTimePickerSelected" />
          </v-menu>
        </div>
        <div class="font-weight-medium secondary--text pb-2">
          Virtual / In Person
        </div>
        <v-select
          v-model="trialType"
          background-color="#F0F5F6"
          item-color="#F6F9FA"
          rounded
          solo
          flat
          dense
          :items="['In Person', 'Virtual']"
          :rules="[(v) => !!v || 'Item is required']"
        >
          <v-icon slot="append" medium color="accent">
            mdi-chevron-down
          </v-icon>
        </v-select>
        <div class="font-weight-medium secondary--text pb-2">
          Opposing Counsel?
        </div>
        <v-select
          v-model="hasOpposingCounsel"
          background-color="#F0F5F6"
          item-color="#F6F9FA"
          rounded
          solo
          flat
          dense
          :items="['Yes', 'No']"
          :rules="[(v) => !!v || 'Item is required']"
        >
          <v-icon slot="append" medium color="accent">
            mdi-chevron-down
          </v-icon>
        </v-select>
        <div>
          <v-row class="mt-2 mb-2">
            <v-col cols="1" class="pa-0">
              <v-checkbox
                v-model="clientAttendTrial"
                :disabled="true"
                color="primary"
                off-icon="mdi-checkbox-blank-circle-outline"
                on-icon="mdi-checkbox-blank-circle"
                class="mt-1"
              />
            </v-col>
            <v-col cols="11" class="d-flex pa-0">
              <p class="secondary--text mt-1">
                I want my client to attend the Trial.
                <span class="red--text font-italic">(Coming Soon)</span>
              </p>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-form>

    <v-dialog v-model="eventConflictDialogOpen" persistent max-width="600">
      <EventConflictDialog
        @close:dialog="eventConflictDialogOpen = false"
        @accessEvent="accessEvent"
      />
    </v-dialog>

    <v-dialog v-model="eventDetailDialogOpen" persistent max-width="600">
      <EventDetailDialog
        @close="eventDetailDialogOpen = false"
        :eventData="conflictedEvent"
        @editEvent="editEvent"
        @doubleBook="saveEvent"
      />
    </v-dialog>

    <v-dialog v-model="newEventDialogOpen" persistent max-width="700">
      <NewEventDialog
        type="edit"
        :eventData="conflictedEvent"
        @close="newEventDialogOpen = false"
      />
    </v-dialog>
  </VerifiedComplaintBox>
</template>

<script>
import { mapGetters } from "vuex";
import useRequest from "@/utils/useRequest";
import { combineTenants } from "@/utils";
import VerifiedComplaintBox from "@components/VerifiedComplaintBox";
import EVTimePicker from "@components/eviction/EVTimePicker";
import EventConflictDialog from "@components/dialogs/EventConflictDialog";
import EventDetailDialog from "@/components/dialogs/EventDetailDialog.vue";
import NewEventDialog from "@/components/dialogs/NewEventDialog.vue";

export default {
  name: "Trial",
  components: {
    EVTimePicker,
    VerifiedComplaintBox,
    EventConflictDialog,
    EventDetailDialog,
    NewEventDialog,
  },
  data: () => ({
    valid: true,
    docketNo: "",
    trialDate: null,
    selectedTrialDate: null,
    trialDateMenu: false,
    trialTime: null,
    trialTimeMenu: false,
    trialType: null,
    hasOpposingCounsel: null,
    clientAttendTrial: false,
    eventConflictDialogOpen: false,
    eventDetailDialogOpen: false,
    newEventDialogOpen: false,
    conflictedEvent: null,
    isNewEvent: null,
  }),
  computed: {
    ...mapGetters({
      cases: "eviction/cases",
      currentCase: "eviction/currentCase",
      currentClientId: "eviction/currentClientId",
    }),
  },
  activated() {
    window.scrollTo(0, 0);
    this.docketNo = this.currentCase.docketNo
      ? this.currentCase.docketNo
      : "LT-";
    this.selectedTrialDate = this.currentCase.trialDate ?? null;
    this.trialDate = this.selectedTrialDate
      ? this.$dayjs(this.selectedTrialDate).format("MMMM DD, YYYY")
      : "";
    this.trialTime = this.currentCase.trialTime ?? null;
    this.trialType = this.currentCase.trialType ?? null;
    this.hasOpposingCounsel = this.currentCase.hasOpposingCounsel
      ? "Yes"
      : "No";
    // this.clientAttendTrial = this.currentCase.clientAttendTrial ?? null;
    this.isNewEvent = !this.trialTime || !this.trialDate;
  },
  methods: {
    accessEvent() {
      this.eventConflictDialogOpen = false;
      this.eventDetailDialogOpen = true;
    },
    editEvent() {
      this.eventDetailDialogOpen = false;
      this.newEventDialogOpen = true;
    },
    async saveEvent(isDoubleBook) {
      const event = {
        clientId: this.currentClientId,
        caseId: this.currentCase.id,
        date: this.selectedTrialDate,
        title: "Trial",
        startTime: this.tConvert(this.trialTime),
        endTime: "16:00:00", // default end time
        reminder: "",
        details: "",
      };
      await this.$store.dispatch("event/saveEvent", {
        eventData: event,
        isNewEvent: this.isNewEvent || isDoubleBook,
      });
      this.eventDetailDialogOpen = false;
      this.next();
    },
    tConvert(time) {
      if (!time) return;
      let hours = Number(time.match(/^(\d+)/)[1]);
      const minutes = Number(time.match(/:(\d+)/)[1]);
      const AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "PM" && hours < 12) hours = hours + 12;
      if (AMPM == "AM" && hours == 12) hours = hours - 12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;

      return sHours + ":" + sMinutes + ":00";
    },
    async isConflicted() {
      const { data: events } = await useRequest({
        path: `event-conflict?eventDate=${
          this.selectedTrialDate
        }&eventTime=${this.tConvert(this.trialTime)}&title=Trial&caseId=${
          this.currentCase.id
        }`,
      });
      if (events.length) {
        const item = events[0];
        const conflictedCase = this.cases.find(
          (caseItem) => caseItem.id === item.caseId
        );
        const caseParties = `${conflictedCase.deedNames ?? "Pending"} vs. ` + (combineTenants(conflictedCase.tenants) ?? "Pending");
        const clientName = `${conflictedCase.clientInfo.firstName} ${conflictedCase.clientInfo.lastName}`;
        this.conflictedEvent = {
          eventId: item.id,
          caseId: item.caseId,
          caseParties,
          clientName,
          docketNo: conflictedCase.docketNo ?? "LT-",
          county: conflictedCase.county,
          clientId: item.clientId,
          title: item.title,
          details: item.details,
          startTime: item.startTime,
          endTime: item.endTime,
          date: item.date,
          color: item.tagColor,
          tag: item.tagName,
          handlingConflict: true,
        };

        return true;
      }

      return false;
    },
    async handleContinue() {
      const isConflicted = await this.isConflicted();
      if (isConflicted) {
        this.eventConflictDialogOpen = true;
      } else {
        this.saveEvent(); // save event in case of not conflicting
      }
    },
    async next() {
      await this.saveData();
      if (this.trialType === "Virtual")
        this.$router.push({ name: "wor-trial-virtual-room" });
      else {
        this.$router.push({ name: "case-view-overview" });
      }
    },
    async saveData() {
      const caseData = {
        docketNo: this.docketNo ?? null,
        trialDate: this.selectedTrialDate,
        trialTime: this.trialTime,
        trialType: this.trialType,
        hasOpposingCounsel: this.hasOpposingCounsel === "Yes",
        // clientAttendTrial: this.clientAttendTrial,
      };
      await this.$store.dispatch("eviction/updateCase", {
        caseData,
        status: "trialDate",
        logMessage: "Trial.vue:207",
      });
    },
    evTimePickerSelected(data) {
      this.trialTime = data;
      this.trialTimeMenu = false;
    },
    closedTrialDateMenu(data) {
      this.selectedTrialDate = data;
      this.trialDate = this.$dayjs(this.selectedTrialDate).format(
        "MMMM DD, YYYY"
      );
      this.trialDateMenu = false;
    },
  },
};
</script>

<style scoped>
.dateAndTime {
  display: flex;
  gap: 20px;
}
</style>
