<template>
  <div>
    <div class="mx-12">
      <p class="mt-8 font-weight-medium" style="font-size: 1.7rem">
        Profile Information
      </p>
      <p>
        Create your firm and add the attorney(s) that will be working on
        eviction cases.
      </p>
    </div>

    <v-form v-model="valid" flat>
      <div class="px-12 py-6">
        <div>
          <v-row>
            <p class="ml-2 font-weight-medium" style="font-size: 1.4rem">
              Firm Information
            </p>
          </v-row>
          <v-row>
            <v-col cols="6">
              <TextField title="Firm Name" :field.sync="firm.name" />
            </v-col>
            <v-col cols="6">
              <TextField
                title="Street Address"
                :field.sync="firm.streetAddress"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <TextField title="City" :field.sync="firm.city" />
            </v-col>
            <v-col cols="6">
              <SelectField
                title="State"
                :field.sync="firm.state"
                :items="states"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <TextField title="Zip Code" :field.sync="firm.zipcode" />
            </v-col>
            <v-col cols="6">
              <div class="mt-2 d-flex align-center">
                <v-avatar size="70" class="profile-upload">
                  <v-icon v-if="firm.letterhead" size="x-large"
                    >icofont-ui-check</v-icon
                  >
                  <v-icon v-else color="info" size="xx-large"
                    >icofont-ui-note</v-icon
                  >
                </v-avatar>
                <v-btn
                  class="ml-2"
                  color="secondary"
                  plain
                  :loading="isLetterheadSelecting"
                  @click="onLetterheadSelect"
                >
                  <v-icon size="xx-large">icofont-cloud-upload</v-icon>
                  <span class="ml-2">Upload Letterhead</span>
                </v-btn>
                <input
                  ref="uploaderLetterhead"
                  class="d-none"
                  type="file"
                  accept="application/pdf"
                  @change="onLetterheadFileChanged"
                />
              </div>
            </v-col>
          </v-row>
        </div>

        <div v-if="attorneys.length">
          <div v-for="(attorney, index) in attorneys" :key="index">
            <v-row>
              <span class="ml-2 font-weight-medium" style="font-size: 1.4rem">{{
                `Attorney ${index + 1}`
              }}</span>
              <v-btn
                class="btn--plain ml-4 mt-1"
                color="accent"
                icon
                tile
                @click="deleteAttorney(attorney.id)"
              >
                <v-icon style="font-size: 25px"> mdi-trash-can-outline </v-icon>
              </v-btn>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="mr-8">
                  <div class="font-weight-medium secondary--text pb-2">
                    First Name
                  </div>
                  <v-text-field
                    v-model="attorney.firstName"
                    v-bind="$attrs"
                    v-on:focusout="
                      updateField({
                        id: attorney.id,
                        firstName: attorney.firstName,
                      })
                    "
                    rounded
                    :rules="[required]"
                    solo
                    flat
                    dense
                    background-color="#F0F5F6"
                    class="pb-2"
                  />
                </div>
              </v-col>
              <v-col cols="6">
                <div class="mr-8">
                  <div class="font-weight-medium secondary--text pb-2">
                    Last Name
                  </div>
                  <v-text-field
                    v-model="attorney.lastName"
                    v-bind="$attrs"
                    v-on:focusout="
                      updateField({
                        id: attorney.id,
                        lastName: attorney.lastName,
                      })
                    "
                    rounded
                    :rules="[required]"
                    solo
                    flat
                    dense
                    background-color="#F0F5F6"
                    class="pb-2"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="mr-8">
                  <div class="font-weight-medium secondary--text pb-2">
                    Phone Number
                  </div>
                  <v-text-field
                    v-model="attorney.cell"
                    v-bind="$attrs"
                    v-on:focusout="
                      updateField({ id: attorney.id, cell: attorney.cell })
                    "
                    rounded
                    :rules="[required]"
                    solo
                    flat
                    dense
                    background-color="#F0F5F6"
                    class="pb-2"
                  />
                </div>
              </v-col>
              <v-col cols="6">
                <div class="mr-8">
                  <div class="font-weight-medium secondary--text pb-2">
                    Email Address
                  </div>
                  <v-text-field
                    v-model="attorney.email"
                    v-bind="$attrs"
                    v-on:focusout="
                      updateField({ id: attorney.id, email: attorney.email })
                    "
                    rounded
                    :rules="[emailValid]"
                    solo
                    flat
                    dense
                    background-color="#F0F5F6"
                    class="pb-2"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <div class="mr-8">
                  <div class="font-weight-medium secondary--text pb-2">
                    New Jersey State Bar No.
                  </div>
                  <v-text-field
                    v-model="attorney.njStateBarNo"
                    v-bind="$attrs"
                    v-on:focusout="
                      updateField({
                        id: attorney.id,
                        njStateBarNo: attorney.njStateBarNo,
                      })
                    "
                    rounded
                    :rules="[required]"
                    solo
                    flat
                    dense
                    background-color="#F0F5F6"
                    class="pb-2"
                  />
                </div>
              </v-col>
              <v-col cols="6">
                <div class="mt-2 d-flex align-center">
                  <v-avatar size="70" class="profile-upload">
                    <img
                      v-if="attorney.avatar"
                      :src="attorney.avatar"
                      alt="avatar"
                    />
                    <v-icon v-else color="info" size="xx-large"
                      >icofont-user-alt-7</v-icon
                    >
                  </v-avatar>
                  <v-btn
                    class="ml-2"
                    color="secondary"
                    plain
                    :loading="isAvatarSelecting"
                    @click="onAvatarSelect(index)"
                  >
                    <v-icon size="xx-large">icofont-cloud-upload</v-icon>
                    <span class="ml-2">Upload Profile Picture</span>
                  </v-btn>
                  <input
                    :ref="`uploaderAvatar${index}`"
                    class="d-none"
                    type="file"
                    accept="image/*"
                    @change="(e) => onAvatarFileChanged(e, index, attorney.id)"
                  />
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

        <v-row>
          <v-col cols="6">
            <v-btn
              class="btn--plain ml-12"
              color="accent"
              icon
              tile
              @click="addAttorney"
            >
              <v-icon style="font-size: 35px"> mdi-plus-circle-outline </v-icon>
              <span class="ml-4 secondary--text">Add Attorney</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <v-card-actions class="px-16 d-flex justify-end">
        <v-btn
          color="primary"
          class="px-8 white--text"
          rounded
          depressed
          :disabled="!totalValid"
          @click="next"
        >
          Save & Continue
        </v-btn>
      </v-card-actions>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { states } from "@/resources/index.js";
import useRequest from "@/utils/useRequest";

export default {
  name: "ProfileInformation",
  data() {
    return {
      valid: false,
      states,
      isAvatarSelecting: false,
      isLetterheadSelecting: false,
      required: (value) => !!value || "Item is required",
      emailValid: (value) => /.+@.+\..+/.test(value) || "E-mail must be valid",
    };
  },
  computed: {
    ...mapGetters({
      firm: "firm",
      attorneys: "attorney/attorneys",
    }),
    totalValid() {
      return this.valid && this.attorneys.length;
    },
  },
  methods: {
    onAvatarSelect(index) {
      this.isAvatarSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isAvatarSelecting = false;
        },
        { once: true }
      );
      this.$refs[`uploaderAvatar${index}`][0].click();
    },
    async onAvatarFileChanged(e, index, attorneyId) {
      try {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        const { data: avatar } = await useRequest({
          path: "upload_avatar",
          method: "post",
          data: formData,
          headers: {
            attorneyId,
          },
          throwError: true,
        });
        const attorneys = this.attorneys.map((attorney) => {
          if (attorney.id === attorneyId) return { ...attorney, avatar };
          else return attorney;
        });
        this.$store.commit("attorney/set_attorneys", attorneys);
      } catch (error) {
        console.log(`error`, error);
      } finally {
        this.$refs[`uploaderAvatar${index}`][0].value = null;
      }
    },
    onLetterheadSelect() {
      this.isLetterheadSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isLetterheadSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploaderLetterhead.click();
    },
    async onLetterheadFileChanged(e) {
      try {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        const { data: letterhead } = await useRequest({
          path: "upload_avatar",
          method: "post",
          data: formData,
          throwError: true,
        });
        const updatedFirm = { ...this.firm, letterhead };
        this.$store.commit("set_firm", updatedFirm);
      } catch (error) {
        console.log(`error`, error);
      } finally {
        this.$refs.uploaderLetterhead.value = null;
      }
    },
    async addAttorney() {
      await this.$store.dispatch("attorney/insert", {
        firmId: this.firm.id,
        firstName: "",
        lastName: "",
        cell: "",
        email: "",
        njStateBarNo: "",
        avatar: null,
      });
    },
    async updateField(updatedAttorney) {
      await this.$store.dispatch("attorney/update", updatedAttorney);
    },
    async deleteAttorney(id) {
      await this.$store.dispatch("attorney/delete", id);
    },
    next() {
      this.$emit("updateStep", "next");
      this.$store.dispatch("updateFirm", this.firm);
    },
  },
};
</script>

<style scoped>
.profile-upload {
  border: 1px solid #afc2ce;
}
.v-btn >>> .v-btn__content {
  opacity: 1 !important;
}
</style>
