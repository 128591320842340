<template>
  <v-card class="new-event-modal" style="border-radius: 20px; height: 90vh">
    <div class="close-btn">
      <v-btn color="info" icon @click="onCancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <vue-scroll :ops="ops" ref="scrollRef">
      <v-form style="margin-bottom: 140px">
        <div class="pa-12 secondary--text">
          <v-card-title class="mb-4 font-weight-medium"
            >{{ type == "new" ? "New" : "Edit" }} Event</v-card-title
          >
          <div class="ml-4">
            <v-row>
              <v-col cols="6">
                <span>Client</span>
                <v-select
                  v-model="newEvent.clientId"
                  :items="clientList"
                  item-text="fullName"
                  item-value="id"
                  :rules="rules"
                  background-color="#F0F5F6"
                  item-color="#F6F9FA"
                  rounded
                  solo
                  flat
                  dense
                >
                  <v-icon slot="append" medium color="accent">
                    mdi-chevron-down
                  </v-icon>
                </v-select>
              </v-col>
              <v-col cols="6">
                <span>Case</span>
                <v-select
                  v-model="newEvent.caseId"
                  :items="caseList"
                  item-text="caseParties"
                  item-value="id"
                  :rules="rules"
                  background-color="#F0F5F6"
                  item-color="#F6F9FA"
                  rounded
                  solo
                  flat
                  dense
                >
                  <v-icon slot="append" medium color="accent">
                    mdi-chevron-down
                  </v-icon>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <span>Date</span>
                <DateField :field.sync="newEvent.date" />
              </v-col>
              <v-col cols="6">
                <span>Tags</span>
                <v-select
                  v-model="newEvent.tagId"
                  :items="[...eventTags, { id: 0, name: 'New Tag' }]"
                  item-text="name"
                  item-value="id"
                  :rules="rules"
                  background-color="#F0F5F6"
                  item-color="#F6F9FA"
                  rounded
                  solo
                  flat
                  dense
                >
                  <v-icon slot="append" medium color="accent">
                    mdi-chevron-down
                  </v-icon>
                </v-select>
                <div
                  v-if="newEvent.tagId === 0"
                  class="mt-2"
                >
                  <span>Tag Name</span>
                  <v-text-field
                    v-model="newEvent.newTagName"
                    v-bind="$attrs"
                    rounded
                    :rules="rules"
                    solo
                    flat
                    dense
                    background-color="#F0F5F6"
                  />
                  <v-color-picker
                    class="mt-0"
                    v-model="newTagColor"
                    @input="newEvent.newTagColor = newTagColor.hexa"
                    :swatches="swatches"
                    show-swatches
                    hide-canvas
                    hide-inputs
                    hide-sliders
                  ></v-color-picker>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>Title</span>
                <v-text-field
                  v-model="newEvent.title"
                  v-bind="$attrs"
                  rounded
                  :rules="rules"
                  solo
                  flat
                  dense
                  background-color="#F0F5F6"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <span>Start Time</span>
                <v-menu
                  v-model="startTimeMenu"
                  transition="scale-transition"
                  offset-y
                  max-width="300px"
                  :close-on-content-click="false"
                  :nudge-right="10"
                  :nudge-top="25"
                  rounded="xl"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="startTime"
                      append-icon="mdi-clock-time-nine-outline"
                      color="accent"
                      readonly
                      rounded
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                      v-on="on"
                      @click:append="startTimeMenu = true"
                    />
                  </template>

                  <EVTimePicker @meridiemClicked="startTimeSelected" />
                </v-menu>
              </v-col>
              <v-col cols="6">
                <span>End Time</span>
                <v-menu
                  v-model="endTimeMenu"
                  transition="scale-transition"
                  offset-y
                  max-width="300px"
                  :close-on-content-click="false"
                  :nudge-right="10"
                  :nudge-top="25"
                  rounded="xl"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="endTime"
                      append-icon="mdi-clock-time-nine-outline"
                      color="accent"
                      readonly
                      rounded
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                      v-on="on"
                      @click:append="endTimeMenu = true"
                    />
                  </template>

                  <EVTimePicker @meridiemClicked="endTimeSelected" />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-switch
                  v-model="allDay"
                  label="All Day"
                  color="primary"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <span>Reminder</span>
                <div class="d-flex flex-row">
                  <v-text-field
                    v-model="reminderValue"
                    v-bind="$attrs"
                    rounded
                    :rules="rules"
                    solo
                    flat
                    dense
                    background-color="#F0F5F6"
                    style="width: 70px; min-width: 70px"
                  />
                  <v-divider class="mx-4" vertical />
                  <v-select
                    v-model="reminderType"
                    background-color="#F0F5F6"
                    item-color="#F6F9FA"
                    rounded
                    :rules="rules"
                    solo
                    flat
                    dense
                    :items="remindList"
                  >
                    <v-icon slot="append" medium color="accent">
                      mdi-chevron-down
                    </v-icon>
                  </v-select>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span>Details</span>
                <v-textarea
                  v-model="newEvent.details"
                  rounded
                  :rules="rules"
                  solo
                  flat
                  dense
                  background-color="#F0F5F6"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-form>
    </vue-scroll>

    <div class="card-actions" style="margin-top: -20px">
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="onCancel"
      >
        Cancel
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 ml-8 white--text capital--btn"
        depressed
        :disabled="!valid"
        @click="onSave"
      >
        {{ type == "new" ? "Add New" : "Save" }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import EVTimePicker from "@components/eviction/EVTimePicker";
import { mapGetters } from "vuex";
import { combineTenants } from "@/utils";

export default {
  name: "NewEventDialog",
  components: {
    EVTimePicker,
  },
  props: {
    type: String,
    eventData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          opacity: 0,
          size: "10px",
          specifyBorderRadius: false,
          gutterOfEnds: "85px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "10px",
        },
      },
      swatches: [
        ["#FF0000"],
        ["#AA0000"],
        ["#550000"],
        ["#FFFF00"],
        ["#AAAA00"],
        ["#555500"],
        ["#00FF00"],
        ["#00AA00"],
        ["#005500"],
      ],
      startTime: "",
      startTimeMenu: false,
      endTime: "",
      endTimeMenu: false,
      allDay: false,
      caseList: [],
      remindList: [
        "Minutes before",
        "Hours before",
        "Days before",
        "Weeks before",
      ],
      reminderValue: "",
      reminderType: "",
      newEvent: {
        clientId: null,
        caseId: null,
        date: "",
        startTime: "",
        endTime: "",
        title: "",
        details: "",
        tagId: null,
        newTagName: "",
        newTagColor: null,
        reminder: "",
      },
      newTagColor: null,
    };
  },
  computed: {
    ...mapGetters({
      clients: "client/clients",
      clientsLoaded: "client/loaded",
      cases: "eviction/cases",
      currentCase: "eviction/currentCase",
      eventTags: "event/tags",
    }),
    client() {
      return this.newEvent.clientId;
    },
    clientList() {
      return this.clients.map((item) => {
        return {
          id: item.id,
          fullName: item.firstName + " " + item.lastName,
        };
      });
    },
    rules() {
      switch (this.rulesType) {
        case "none":
          return [];
        case "required":
        default:
          return [(v) => !!v || "Item is required"];
      }
    },
    valid() {
      return (
        this.newEvent.clientId && this.newEvent.caseId && this.newEvent.date
      );
    },
  },
  watch: {
    client(selectedClientId) {
      if (!selectedClientId) return;
      const matchedCases = this.cases.filter(
        (item) => item.clientId === selectedClientId
      );
      this.caseList = this.getCaseList(matchedCases);
    },
    allDay(value) {
      if (value) {
        this.startTime = this.tConvert("08:45:00");
        this.endTime = this.tConvert("13:00:00");
        this.newEvent.startTime = "08:45:00";
        this.newEvent.endTime = "13:00:00";
      }
    },
  },
  async mounted() {
    if (!this.clientsLoaded) {
      await this.$store.dispatch("client/loadClients");
      this.$store.commit("client/set_loaded", true);
    }
    if (this.type === "edit" && this.eventData !== null) {
      this.startTime = this.tConvert(this.eventData.startTime);
      this.endTime = this.tConvert(this.eventData.endTime);
      this.reminderValue = this.eventData.reminder?.split('-')[0];
      this.reminderType = this.eventData.reminder?.split('-')[1];
      this.newEvent = this.eventData;
      if (this.eventData.startTime === "08:45:00" && this.eventData.endTime === "13:00:00") {
        this.allDay = true;
      }
    }
    this.caseList = this.getCaseList(this.cases);
    if (this.currentCase.creatingEvent) {
      this.newEvent.clientId = this.currentCase.clientId;
      this.newEvent.caseId = this.currentCase.id;
    }
  },
  methods: {
    initEvent() {
      this.newEvent = {
        clientId: null,
        caseId: null,
        date: "",
        startTime: "",
        endTime: "",
        title: "",
        details: "",
        tagId: null,
        newTagName: "",
        reminder: "",
      };
      this.$store.commit("eviction/set_current_case", {
        ...this.currentCase,
        creatingEvent: false,
      });
    },
    getCaseList(items) {
      return items.map((item) => {
        return {
          id: item.id,
          caseParties:
            (item.docketNo ?? "Pending") +
            " / " +
            (item.deedNames ?? "Pending") +
            " vs. " +
            (combineTenants(item.tenants) ?? "Pending"),
        };
      });
    },
    tConvert(time) {
      // Check correct time format and split into components
      time = time.toString().split(":");

      if (time.length > 1) {
        // If time format correct
        time[0] = time[0] % 12 || 12; // Adjust hours
        time[2] = time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      }
      return time[0] + ":" + time[1] + time[2]; // return adjusted time or original string
    },
    onSave() {
      this.newEvent.reminder = this.reminderValue + "-" + this.reminderType;
      this.newEvent.startTime = this.newEvent.startTime || "08:00:00";
      this.newEvent.endTime = this.newEvent.endTime || "16:00:00"; // default ent time

      if (this.type === "edit")
        this.$emit("saveEvent", { ...this.newEvent, eventId: this.eventData.eventId });
      else {
        this.$emit("saveNewEvent", this.newEvent);
        this.initEvent();
      }
      this.$refs.scrollRef.scrollTo({ x: 0, y: 0 }, 0);
    },
    onCancel() {
      this.$emit("close");
      this.$refs.scrollRef.scrollTo({ x: 0, y: 0 }, 0);
      if (this.type !== "edit")
        this.initEvent();
    },
    startTimeSelected(data) {
      this.startTime = data;
      const time = data.split(" ")[0];
      let hour = time.split(":")[0];
      const minute = time.split(":")[1];
      if (data.split(" ")[1] == "PM") hour = (parseInt(hour) + 12).toString();
      this.newEvent.startTime = `${hour}:${minute}:00`;
      this.startTimeMenu = false;
    },
    endTimeSelected(data) {
      this.endTime = data;
      const time = data.split(" ")[0];
      let hour = time.split(":")[0];
      const minute = time.split(":")[1];
      if (data.split(" ")[1] == "PM") hour = (parseInt(hour) + 12).toString();
      this.newEvent.endTime = `${hour}:${minute}:00`;
      this.endTimeMenu = false;
    },
  },
};
</script>

<style>
.new-event-modal {
  overflow-y: visible;
}
.new-event-modal .v-text-field__details {
  display: none;
}
</style>

<style scoped>
.__vuescroll >>> .__panel {
  overscroll-behavior: contain;
}
.close-btn {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1;
}
.v-chip >>> .v-chip__content {
  width: 100%;
}
.v-input >>> .v-input--selection-controls__input + .v-label {
  margin-left: 5px;
  color: #003A60;
  font-size: 18px;
}
</style>
