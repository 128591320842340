<template>
  <div class="mt-4">
    <v-container class="pb-0">
      <v-row align-content="center">
        <v-col :cols="cols" class="pt-0">
          <vue-dropzone
            id="custom-dropzone"
            ref="dropzone"
            class="custom-dropzone"
            :options="dropzoneOptions"
            :use-custom-slot="true"
            @vdropzone-upload-progress="uploadProgress"
            @vdropzone-file-added="vdropzoneFileAdded"
            @vdropzone-complete="dropzoneComplete"
            @vdropzone-sending="sendingEvent"
            @vdropzone-success="uploaded"
            @vdropzone-error="vdropzoneError"
          >
            <div class="dropzone-custom-content">
              <i
                class="icofont-cloud-upload icofont-3x primary--text pa-0"
                style="line-height: normal"
              />
              <div
                class="secondary--text"
                style="font-family: 'futura-pt', sans-serif !important"
              >
                Drag file to upload or
                <span class="font-weight-medium primary--text">browse</span>.
              </div>
            </div>
          </vue-dropzone>
        </v-col>

        <v-col>
          <div class="dropzone-previews new-preview-zone">
            <div v-for="(item, index) in uploadedFiles" :key="item.id">
              <div class="d-flex mb-4">
                <i
                  :class="`icofont-file-${fileExtension(
                    item.filename
                  )} icofont-2x mr-4 uploaded-file-icon`"
                  style="cursor: pointer"
                  @click="viewFile(item)"
                />
                <div class="full-width">
                  <div class="d-flex justify-space-between">
                    <div
                      class="font-weight-medium"
                      style="cursor: pointer"
                      @click="viewFile(item)"
                    >
                      {{ item[ducumentNameAlias] }}
                    </div>
                    <v-icon
                      x-small
                      color="info"
                      @click="removeFile({ index, type: 'uploaded' })"
                    >
                      mdi-close
                    </v-icon>
                  </div>

                  <div
                    class="d-flex justify-space-between info--text"
                    style="font-size: 0.9rem"
                  >
                    <div>{{ item.filename }}</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- new fils -->
            <div v-for="(file, index) in uploadingFiles" :key="index">
              <div class="d-flex mb-4">
                <i
                  :class="`icofont-file-${fileExtension(
                    file.uploadingFileName
                  )} icofont-2x mr-4 uploaded-file-icon`"
                  style="cursor: pointer"
                  @click="viewFile(file)"
                />
                <div class="full-width">
                  <div class="d-flex justify-space-between">
                    <div
                      class="font-weight-medium"
                      style="cursor: pointer"
                      @click="viewFile(file)"
                    >
                      {{ file.uploadingDocumentName }}
                    </div>
                    <v-icon
                      x-small
                      color="info"
                      @click="removeFile({ index, type: 'uploading' })"
                    >
                      mdi-close
                    </v-icon>
                  </div>
                  <v-progress-linear
                    background-color="#F0F5F6"
                    color="primary"
                    :value="file.uploadProgressNumber"
                    class="my-1"
                    rounded
                  />
                  <div
                    class="d-flex justify-space-between info--text"
                    style="font-size: 0.9rem"
                  >
                    <div>{{ file.uploadingFileName }}</div>

                    <div>{{ file.uploadProgressBytes }} MB</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import vueDropzone from "vue2-dropzone";
import useRequest from "@/utils/useRequest";
import openSecuredFile from "@/utils/openSecuredFile";

export default {
  name: "DocumentUploadLease",
  components: {
    vueDropzone,
  },
  props: {
    uploadedFiles: Array,
  },
  data() {
    return {
      documentName: "Lease",
      menu: false,
      uploading: false,
      uploadingFiles: {},
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}/${
          this.$auth.isAuthenticated ? "upload" : "uploadGuest"
        }`,
        parallelUploads: 1,
        uploadMultiple: false,
        previewTemplate: this.template(),
        previewsContainer: ".new-preview-zone",
        autoProcessQueue: false,
        headers: {
          Authorization: this.$http.defaults.headers.common.Authorization,
          userType: "attorney",
        },
        acceptedFiles: "image/*,application/pdf,.docx,.xlsx",
      },
      periodError: false,
    };
  },
  computed: {
    case() {
      return this.$store.getters["eviction/currentCase"];
    },
    property() {
      return this.$store.getters["property/currentProperty"];
    },
    clientId() {
      return this.$store.getters.client.id;
    },
    isAuthenticated() {
      return this.$auth.isAuthenticated;
    },
    dateConverted() {
      return this.convertDateWithSlashes(this.filedDate);
    },
    cols() {
      return Object.keys(this.uploadingFiles).length > 0 ||
        this.uploadedFiles.length
        ? 6
        : 12;
    },
    ducumentNameAlias() {
      return "name";
    },
  },
  deactivated() {
    if (!this.$auth.isAuthenticated) this.saveGuestFiles();
    this.uploadingFiles = {};
  },
  methods: {
    async viewFile(file) {
      if (!this.isAuthenticated) {
        const { filename, documentUrl, type } = file;
        const newTab = window.open(filename);
        newTab.document.write(
          `<title>${filename}</title><style>body{margin:0;padding:0;overflow:hidden;}</style>`
        );
        if (type === "pdf")
          newTab.document.write(
            "<iframe width='100%' height='100%' src=" +
              documentUrl +
              "></iframe>"
          );
        else newTab.document.write(`<img alt=${filename} src=${documentUrl}>`);
        return;
      }
      await openSecuredFile({
        id: file.id,
        filename: file.filename,
        folder: "documents",
        documentUrl: file.documentUrl,
      });
    },
    uploadManual(file) {
      this.$set(this.uploadingFiles, file.name, {
        uploadingFileName: file.name,
        uploadingDocumentName: this.documentName,
        // uploadingfiledDate: file.filedDate,
        uploadProgressNumber: 0,
        uploadProgressBytes: 0,
      });
      this.$refs.dropzone.processQueue();
      this.uploading = true;
    },
    uploadProgress(file, progress, bytesSent) {
      this.uploadingFiles[file.name].uploadProgressNumber = progress;
      // convert bytes to MB
      this.uploadingFiles[file.name].uploadProgressBytes =
        Math.round((bytesSent / 1048576 + Number.EPSILON) * 100) / 100;
    },
    vdropzoneFileAdded(file) {
      setTimeout(() => {
        this.uploadManual(file);
      }, 500);
    },
    vdropzoneError(file, message, xhr) {
      console.log("vdropzoneError:", { file, message, xhr });
    },
    sendingEvent(file, xhr, formData) {
      const { uploadingDocumentName } = this.uploadingFiles[file.name];
      formData.append("caseId", this.case.id);
      formData.append("docName", uploadingDocumentName);
    },
    uploaded(file, response) {
      const id = response.newDocumentId;
      const updatedFile = {
        ...this.uploadingFiles[file.name],
        id,
        documentUrl: response.newDocumentUrl, //file.dataURL,
        docId: id,
        type: response.type,
        name: response.name,
        filename: file.name,
      };

      if (this.isAuthenticated) {
        this.$delete(this.uploadingFiles, file.name);
        this.$set(this.uploadingFiles, id, updatedFile);
      } else {
        this.$set(this.uploadingFiles, file.name, updatedFile);
      }
    },
    dropzoneComplete() {
      this.uploading = false;
    },
    template() {
      return `<div></div>`;
    },
    async removeFile({ index, type }) {
      if (type === "uploading") {
        const idx = this.uploadingFiles[index].id;
        this.$delete(this.uploadingFiles, index);
        await useRequest({ path: `upload/${parseInt(idx)}`, method: "delete" });

        return;
      }

      // type == uploaded
      const idx = this.uploadedFiles[index].id;
      const itemToDelete = this.case.documents.filter((item) => item.id == idx);
      const currentCase = this.case;
      currentCase.documents.splice(
        currentCase.documents.indexOf(itemToDelete[0]),
        1
      );
      this.$store.commit("eviction/update_current_case", currentCase);

      await useRequest({ path: `upload/${parseInt(idx)}`, method: "delete" });
    },
    saveGuestFiles() {
      const guestFilesToSave = Object.keys(this.uploadingFiles).map((key) => {
        const { name, filename, documentUrl, type } = this.uploadingFiles[key];
        if (documentUrl)
          return {
            name,
            type,
            filename,
            documentUrl,
            documentName: name,
          };
      });
      if (!guestFilesToSave.length) return;

      const updatedDocs = [...this.uploadedFiles, ...guestFilesToSave];
      this.$store.commit("eviction/update_current_case", {
        documents: updatedDocs,
      });
    },
  },
};
</script>

<style>
#custom-dropzone {
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
  background: #f6f9fa 0% 0% no-repeat padding-box;
  border: 1px solid #f6f9fa;
  border-radius: 20px;
  padding: 20px 20px;
  min-height: 120px;
}
.custom-dropzone {
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
  background: #f6f9fa 0% 0% no-repeat padding-box;
  border: 1px solid #f6f9fa;
  border-radius: 20px;
  padding: 20px 20px;
  min-height: 120px;
}
</style>
