<template>
  <div>
    <div class="mx-12">
      <p class="mt-8 font-weight-medium" style="font-size: 1.7rem">
        Client Import
      </p>
      <p>
        You can upload all your clients and their contact information in one
        easy step, by uploading a spreadsheet with all your clients or with an
        API.
      </p>
    </div>

    <div class="d-flex justify-space-between mx-16 mt-16">
      <v-card
        class="d-flex flex-column justify-center align-center secondary--text mx-4 px-12 rounded-card import-card"
      >
        <img src="images/spreadsheet-import.png" height="90" alt="spreadsheet" />
        <div class="mt-4 mb-2 font-weight-medium" style="font-size: 24px">
          Spreadsheet Import
        </div>
        <div>
          Populate all your client information onto a spreadsheet and upload
          that spreadsheet to FileEvictions.
        </div>
        <v-btn
          rounded
          color="primary"
          class="px-8 mt-12 white--text capital--btn"
          @click="next"
        >
          Start Import
        </v-btn>
      </v-card>
      <v-card
        class="d-flex flex-column justify-center align-center secondary--text mx-4 px-12 rounded-card import-card"
      >
        <Ribbon
          text="Coming Soon"
          color="#4DCCC4"
          url="https://fileevictions.com/upcoming-features/"
        />
        <img src="images/api-integration.png" height="80" alt="api-integration" />
        <div class="mt-4 mb-2 font-weight-medium" style="font-size: 24px">
          API Integration
        </div>
        <div>
          Integrate with your Client Base located on MRI, QuickBooks or any
          other software.
        </div>
        <v-btn
          rounded
          color="primary"
          disabled
          class="px-8 mt-12 white--text capital--btn"
        >
          Choose API
        </v-btn>
      </v-card>
    </div>

    <v-card-actions class="mt-16 px-16 d-flex justify-end">
      <v-btn
        color="secondary"
        class="mr-4 btn--plain capital--btn"
        rounded
        text
        @click="back"
      >
        Go Back
      </v-btn>
      <v-btn
        color="primary"
        class="px-8 white--text"
        rounded
        depressed
        @click="skip"
      >
        Skip
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import Ribbon from "vue-ribbon";

export default {
  name: "ProfileImportClient",
  components: {
    Ribbon,
  },
  data() {
    return {};
  },
  created() {
    if (!this.$auth.isAuthenticated) return this.$auth.loginWithRedirect();
  },
  methods: {
    back() {
      this.$emit("updateStep", "back");
    },
    next() {
      this.$emit("updateStep", "next");
    },
    skip() {
      this.$emit("updateStep", "end");
    },
  },
};
</script>

<style scoped>
.import-card {
  height: 420px;
  box-shadow: 15px 15px 40px #00000029 !important;
  border-radius: 20px !important;
  width: 400px;
  min-width: 200px;
}
.import-card >>> .vue-ribbon:after {
  color: white !important;
  border: none !important;
}
</style>
