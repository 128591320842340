<template>
  <div>
    <LoadingOverlay :active.sync="loading" color="#4DCCC4" />
    <div class="secondary--text">
      <div v-if="!file">
        <div
          :class="['dropZone', dragging ? 'dropZone-over' : '']"
          @dragenter="dragging = true"
          @dragleave="dragging = false"
        >
          <div class="dropZone-info" @drag="onChange">
            <i
              class="icofont-cloud-upload icofont-4x primary--text pa-0"
              style="line-height: normal"
            />
            <span>Drag file to upload</span>
            <span
              >or
              <span
                class="font-weight-medium primary--text"
                style="cursor: pointer"
                >browse</span
              >.</span
            >
            <div class="dropZone-upload-limit-info">
              <div class="info--text">Maximum size: 64MB</div>
            </div>
          </div>
          <input type="file" @change="onChange" />
        </div>
      </div>
      <div v-else class="dropZone-uploaded">
        <div class="dropZone-uploaded-info">
          <div class="uploadedFile-info">
            <v-icon color="primary" style="font-size: 60px">
              mdi-microsoft-excel
            </v-icon>
            <div>{{ file.name }}</div>
          </div>
          <v-btn
            color="secondary"
            class="mt-2 btn--plain capital--btn"
            rounded
            text
            @click="removeFile"
            >Remove File</v-btn
          >
        </div>
      </div>
    </div>

    <v-card-actions class="mt-16 mb-8 d-flex justify-end">
      <v-btn
        color="secondary"
        class="mr-4 btn--plain capital--btn"
        rounded
        text
        @click="back"
      >
        Go Back
      </v-btn>
      <v-btn
        color="primary"
        class="px-10 white--text"
        rounded
        depressed
        :disabled="!fileLoaded"
        @click="next"
      >
        Upload
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import * as XLSX from "xlsx";

export default {
  name: "ImportUploadFile",
  data() {
    return {
      file: "",
      dragging: false,
      loading: false,
      excelData: "",
    };
  },
  computed: {
    fileLoaded() {
      return !this.loading && this.file !== "";
    },
  },
  activated() {
    this.file = "";
  },
  methods: {
    onChange(e) {
      const files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.createFile(files[0]);
    },
    parseExcel(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.loading = true;
        const data = e.target.result;
        const workbook = XLSX.read(data, {
          type: "binary",
        });
        const XL_row_object = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[workbook.SheetNames[0]]
        );
        this.excelData = JSON.stringify(XL_row_object);
        this.loading = false;
      };

      reader.onerror = (ex) => {
        console.log(ex);
      };

      reader.readAsBinaryString(file);
    },
    createFile(file) {
      if (
        !file.type.match(".csv") &&
        !file.type.match("application/vnd.ms-excel") &&
        !file.type.match(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        )
      ) {
        alert("please select excel file(*.csv, *.xlsx, *.xls).");
        this.dragging = false;
        return;
      }

      if (file.size > 64000000) {
        alert("please check file size no over 64MB.");
        this.dragging = false;
        return;
      }

      this.file = file;
      this.parseExcel(file);
      this.dragging = false;
    },
    removeFile() {
      this.file = "";
    },
    back() {
      this.$emit("updateStep", "back");
    },
    next() {
      this.$emit("uploaded", this.excelData);
      this.$emit("updateStep", "next");
    },
  },
};
</script>

<style scoped>
.dropZone {
  width: 100%;
  height: 400px;
  position: relative;
  border: 2px dashed #fff;
}

.dropZone:hover {
  border: 2px dashed #eee;
}

.dropZone-info {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 100%;
  height: 400px;
  position: relative;
  border: 2px dashed #fff;
}
.dropZone-uploaded:hover {
  border: 2px dashed #eee;
}

.dropZone-uploaded-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}
</style>
