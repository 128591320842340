import Vue from "vue";
import Vuex from "vuex";
import propertyModule from "./property.js";
import evictionModule from "./eviction.js";
import globalModule from "./global.js";
import clientModule from "./client.js";
import attorneyModule from "./attorney.js";
import taskModule from "./task.js";
import messageModule from "./message.js";
import eventModule from "./event.js";
import invoiceModule from "./invoice.js";
import router from "@/router";
import useRequest from "@/utils/useRequest.js";
Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    property: propertyModule,
    eviction: evictionModule,
    global: globalModule,
    client: clientModule,
    attorney: attorneyModule,
    task: taskModule,
    message: messageModule,
    event: eventModule,
    invoice: invoiceModule,
  },
  state: {
    status: "",
    token: localStorage.getItem("acc_token") || "",
    auth: false,
    authLoaded: false,
    errorMsg: "",
    loading: false,
    upcoming: false,
    firm: {},
  },
  getters: {
    authLoaded: (state) => () => state.authLoaded,
    isAuthenticated: (state) => state.auth,
    authStatus: (state) => state.status,
    errorMsg: (state) => state.errorMsg,
    loading: (state) => state.loading,
    upcoming: (state) => state.upcoming,
    firm: (state) => state.firm,
  },
  actions: {
    async guestInitialSetup({ dispatch, commit }) {
      console.log("guestInitialSetup");
      const userData = JSON.parse(sessionStorage.getItem("userData"));
      commit("set_attorney", userData);

      await dispatch(
        "eviction/loadCase",
        { logMessage: "store.js.vue at line 223" },
        { root: true }
      );
    },
    // eslint-disable-next-line
    async initialSetup({ commit, dispatch }) {
      try {
        const { data: firm } = await useRequest({
          path: "firm",
          throwError: true,
        });
        commit("set_firm", firm);
        await dispatch("attorney/loadAttorneys");

        await dispatch("eviction/loadCases");

        const { name, params, path } = router.currentRoute;
        if (!name) router.push("/"); //*If user goes to '/verified-complaint

        //* If in property or in VC, then get currentProperty and currentCase
        const paramId = params.id;
        if (name.startsWith("vc-") && paramId === "new") {
          //* User reloaded page before completing varifeid complaint (not a guest)
          router.push({ name: "vc-before-creating" });
          return;
        }

        if (paramId && paramId !== "new") {
          if (name.startsWith("vc-") || name.startsWith("saf-")) {
            const caseId = paramId;
            console.log("caseId :>> ", caseId);
            await dispatch(
              "eviction/loadCase",
              { caseId, logMessage: "store.js.vue at line 259" },
              { root: true }
            );
          } else if (path.startsWith("/properties")) {
            const propertyId = paramId;
            await dispatch("property/loadProperty", propertyId, { root: true });
          } else if (
            path.startsWith("/case-view") ||
            path.endsWith("/order-details")
          ) {
            const caseId = paramId;
            await dispatch(
              "eviction/loadCase",
              { caseId, logMessage: "store.js.vue at line 261" },
              { root: true }
            );
          }
        }

        return;
      } catch (err) {
        console.log("initialSetup error:", err);
        throw new Error(err);
      }
    },
    async updateUser({ getters }, userData) {
      console.log("updateUser userData :>> ", userData);
      if (!getters.isAuthenticated) {
        const prevUserData = JSON.parse(sessionStorage.getItem("userData"));
        sessionStorage.setItem(
          "userData",
          JSON.stringify({ ...prevUserData, ...userData })
        );
        return;
      }
      await useRequest({
        method: "put",
        path: "api/update-user",
        data: { user: userData },
      });
    },
    async updateFirm({ commit }, updatedFirm) {
      const { data: res } = await useRequest({
        method: "put",
        path: "firm",
        data: updatedFirm,
      });
      if (res.success) {
        commit("set_firm", updatedFirm);
      }
    },
  },
  mutations: {
    // mutations for authorization
    set_auth(state, auth) {
      state.auth = auth;
    },
    set_firm(state, firm) {
      state.firm = firm;
    },
    set_auth_loaded(state, loaded) {
      state.authLoaded = loaded;
    },
    set_auth_error(state, err) {
      state.errorMsg = err;
    },
    // mutations for application
    set_loading(state, flag) {
      state.loading = flag;
    },
    set_upcoming(state, flag) {
      state.upcoming = flag;
    },
  },
});
