import { render, staticRenderFns } from "./ConsentLedgerView.vue?vue&type=template&id=9dd0c79c&scoped=true&"
import script from "./ConsentLedgerView.vue?vue&type=script&lang=js&"
export * from "./ConsentLedgerView.vue?vue&type=script&lang=js&"
import style0 from "./ConsentLedgerView.vue?vue&type=style&index=0&id=9dd0c79c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9dd0c79c",
  null
  
)

export default component.exports