<template>
  <StandardDialog
    title="Add Payments"
    cancelBtnText="cancel"
    saveBtnText="send receipt to client"
    :cancelFunction="close"
    :saveFunction="complete"
    :formValid="formValid"
    contentWidth="70%"
  >
    <div class="utilities-dialog">
      <v-form
        ref="formRange"
        class="utilities-dialog__form pb-4 mx-auto text-left"
      >
        <div class="font-weight-medium secondary--text pb-2">Client</div>
        <v-select
          v-model="newPayment.clientId"
          :items="clientList"
          item-text="fullName"
          item-value="id"
          rounded
          solo
          flat
          dense
          background-color="#F0F5F6"
        >
          <v-icon slot="append" medium color="accent">
            mdi-chevron-down
          </v-icon>
        </v-select>

        <div class="font-weight-medium secondary--text pb-2">Invoices</div>
        <div class="d-flex" v-for="(item, index) in newPayment.invoices" :key="item.id">
          <v-select
            v-model="item.id"
            :items="invoiceList"
            item-text="fullName"
            item-value="id"
            rounded
            solo
            flat
            dense
            background-color="#F0F5F6"
          >
            <v-icon slot="append" medium color="accent">
              mdi-chevron-down
            </v-icon>
          </v-select>
          <v-btn
            fab
            depressed
            color="primary"
            height="40"
            width="40"
            class="ml-2"
            @click="addInvoice"
          >
            <span class="white--text font-weight-regular" style="font-size: 45px">+</span>
          </v-btn>
          <v-btn
            v-if="index !== 0"
            fab
            depressed
            color="accent"
            height="40"
            width="40"
            class="ml-2"
            @click="removeInvoice(index)"
          >
            <span class="white--text font-weight-regular">
              <v-icon>icofont-minus</v-icon>
            </span>
          </v-btn>
        </div>

        <div class="font-weight-medium secondary--text pb-2">Amount paid</div>
        <v-text-field
          v-model="newPayment.payAmount"
          rounded
          type="number"
          solo
          flat
          dense
          background-color="#F0F5F6"
        >
          <div slot="prepend-inner">$</div>
        </v-text-field>

        <div class="font-weight-medium secondary--text pb-2">Date paid</div>
        <v-menu
          v-model="newPayment.menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="newPayment.date"
              append-icon="$calendar"
              color="accent"
              readonly
              rounded
              solo
              flat
              dense
              background-color="#F0F5F6"
              v-on="on"
              @click:append="newPayment.menu = true"
            />
          </template>
          <v-date-picker
            v-model="newPayment.selectedDate"
            no-title
            :show-current="false"
            color="primary"
            class="custom-date"
            @input="fillDate"
          />
        </v-menu>
      </v-form>
    </div>
  </StandardDialog>
</template>

<script>
import StandardDialog from "./StandardDialog.vue";
import { mapGetters } from "vuex";

export default {
  name: "AddPaymentOfInvoiceDialog",
  components: {
    StandardDialog,
  },
  data() {
    return {
      newPayment: {
        clientId: null,
        invoices: [
          {
            id: null,
          },
        ],
        payAmount: "",
        date: "",
        selectedDate: "",
        menu: false,
      },
      invoiceList: [],
    };
  },
  computed: {
    ...mapGetters({
      case: "eviction/currentCase",
      clients: "client/clients",
      clientsLoaded: "client/loaded",
      invoices: "invoice/invoices",
      invoicesLoaded: "invoice/loaded",
    }),
    formValid() {
      return this.newPayment.invoices && this.newPayment.payAmount && this.newPayment.date;
    },
    client() {
      return this.newPayment.clientId;
    },
    clientList() {
      return this.clients.map((item) => {
        return {
          id: item.id,
          fullName: item.firstName + " " + item.lastName,
        };
      });
    },
  },
  watch: {
    client(selectedClientId) {
      if (!selectedClientId) return;
      const matchedInvoices = this.invoices.filter(
        (item) => item.clientId === selectedClientId
      );
      this.invoiceList = this.getInvoiceList(matchedInvoices);
    },
  },
  async created() {
    if (!this.clientsLoaded) {
      await this.$store.dispatch("client/loadClients");
      this.$store.commit("client/set_loaded", true);
    }
  },
  methods: {
    getInvoiceList(items) {
      return items.map((item) => {
        return {
          id: item.id,
          fullName: "Invoice #" + item.invoiceNo + " / " + (item.docketNo ?? "LT-"),
        };
      });
    },
    addInvoice() {
      this.newPayment.invoices.push({
        id: null,
      });
    },
    removeInvoice(index) {
      this.newPayment.invoices.splice(index, 1);
    },
    initialize() {
      this.newPayment = {
        clientId: null,
        invoices: [
          {
            id: null,
          },
        ],
        payAmount: "",
        date: "",
        selectedDate: "",
        menu: false,
      };
    },
    close() {
      this.$emit("close");
      this.initialize();
    },
    complete() {
      this.$emit("send", {
        ...this.newPayment,
        date: this.$dayjs(this.newPayment.date).format("MM/DD/YYYY"),
        clientInfo: this.clients.find(item => item.id === this.newPayment.clientId),
      });
      this.close();
    },
    fillDate(data) {
      this.newPayment.selectedDate = data;
      this.newPayment.date = this.$dayjs(data).format("MMMM DD, YYYY");
      this.newPayment.menu = false;
    },
  },
};
</script>

<style lang="scss">
.utilities-dialog {
  &__form {
    // width: 70%;

    &-add-action {
      margin-top: -20px;
    }

    &-divider {
      margin: 24px auto;
    }
  }
}
</style>
