<template>
  <div class="mx-auto secondary--text">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$emit('close:form')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <div style="width: 80%" class="pt-10 pb-4 mx-auto text-left">
      <div class="custom-title pt-8 pb-4">
        Let's start with your client's property.
      </div>
      <div class="pb-12">
        We’ll need some basic information about the property to get started.
        This includes address, owner information, and registration status.
        Ready?
      </div>

      <div
        v-for="btn in btns"
        :key="btn.text"
        class="d-flex align-center justify-center my-6"
        :class="[selected === btn.text ? 'selection-selected' : 'selection']"
        style="width: 100%"
        @click="typeClicked(btn)"
      >
        <span class="font-weight-medium" style="letter-spacing: 1.68px">{{
          btn.text.toUpperCase()
        }}</span>
      </div>
    </div>
    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-end py-4 pr-12 mt-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="back"
      >
        go back
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        :disabled="!valid"
        @click="next"
      >
        save & continue
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BeforeProperty",
  data() {
    return {
      selected: "",
      btns: [
        {
          text: "Select existing Property",
          route: { name: `vc-select-property` },
        },
        {
          text: "Add unit to existing Property",
          route: { name: `vc-select-property`, query: { addUnit: true } },
        },
        {
          text: "Add new Property",
          route: { name: `vc-property-address` },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      caseSteps: "eviction/caseSteps",
      currentClientId: "eviction/currentClientId",
    }),
    valid() {
      return this.selected;
    },
  },
  activated() {
    window.scrollTo(0, 0);
  },
  methods: {
    next() {
      if (this.selected.text === "Add new Property") {
        this.$store.commit("property/init_property");
      }

      this.$router.push(this.selected.route);
    },
    back() {
      this.$router.push({ name: "vc-before-client" });
    },
    async typeClicked(item) {
      this.selected = item;
      if (this.selected.text !== "Add new Property") {
        await this.$store.dispatch(
          "property/loadProperties",
          this.currentClientId
        );
      }
      this.next();
    },
  },
};
</script>
