<template>
  <div>
    <div class="px-16 sticky-header">
      <div class="d-flex mb-8 page-subheader">
        <div class="d-flex align-center">
          <div class="secondary--text custom-heading mr-6">Invoice</div>
          <v-btn
            fab
            depressed
            color="primary"
            height="45"
            width="45"
            @click="addInvoice"
          >
            <span
              class="white--text font-weight-regular"
              style="font-size: 50px"
              >+</span
            >
          </v-btn>

          <div class="secondary--text custom-heading ml-16 mr-6">Payment</div>
          <v-btn
            fab
            depressed
            color="primary"
            height="45"
            width="45"
            @click="addPayment"
          >
            <span
              class="white--text font-weight-regular"
              style="font-size: 50px"
              >+</span
            >
          </v-btn>
        </div>

        <div class="d-flex pl-8 mt-4 ml-auto">
          <!-- search start -->
          <v-menu
            v-model="searchMenu"
            :close-on-content-click="false"
            :nudge-width="200"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="px-4 subheader-btn" v-bind="attrs" v-on="on">
                <i class="icofont-search-1" color="grey" />&nbsp;Search
              </v-btn>
            </template>

            <v-card
              style="box-shadow: 15px 15px 40px #00000029; border-radius: 20px"
            >
              <div class="px-8 py-4 table-custom-search">
                <input
                  v-model="search"
                  type="text"
                  placeholder="Search Invoices"
                />
              </div>
            </v-card>
          </v-menu>
          <!-- search end -->

          <!-- export start -->
          <v-btn class="ml-4 px-4 subheader-btn" @click="exportExcel">
            <i class="icofont-external" />&nbsp;Export
          </v-btn>
          <!-- export end -->

          <!-- filter start -->
          <v-menu
            v-model="filterMenu"
            :close-on-content-click="false"
            :nudge-width="530"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-4 px-4 subheader-btn" v-bind="attrs" v-on="on">
                <v-icon size="xx-large" color="primary"
                  >icofont-dotted-down</v-icon
                >&nbsp;Filter By:
              </v-btn>
            </template>

            <v-card
              style="box-shadow: 15px 15px 40px #00000029; border-radius: 20px"
            >
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <strong class="secondary--text">FILTER BY</strong>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list>
                <v-list-item v-for="(item, index) in filters" :key="index">
                  <v-list-item-action>
                    <v-icon @click="removeFilter(index)">mdi-close</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-select
                      v-model="item.filterColumn"
                      :items="item.columns"
                      class="mr-2"
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                      style="max-width: 200px"
                    >
                      <v-icon slot="append" medium color="accent">
                        mdi-chevron-down
                      </v-icon>
                    </v-select>
                    <v-select
                      v-model="item.filterOperator"
                      :items="item.operators"
                      class="mr-2"
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                      style="max-width: 150px"
                    >
                      <v-icon slot="append" medium color="accent">
                        mdi-chevron-down
                      </v-icon>
                    </v-select>
                    <v-menu
                      v-if="item.filterColumn.includes('DATE')"
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      offset-x
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="
                            item.filterValue
                              ? $dayjs(item.filterValue).format('MM/DD/YYYY')
                              : $dayjs().format('MM/DD/YYYY')
                          "
                          v-bind="attrs"
                          v-on="on"
                          append-icon="mdi-calendar"
                          class="mr-4"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.filterValue"
                        no-title
                        @input="dateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-select
                      v-else
                      v-model="item.filterValue"
                      :items="item.values"
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                      style="max-width: 200px"
                    >
                      <v-icon slot="append" medium color="accent">
                        mdi-chevron-down
                      </v-icon>
                    </v-select>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-card-actions>
                <v-btn
                  class="btn--plain ml-12"
                  color="secondary"
                  icon
                  @click="addFilter"
                >
                  <v-icon class="mr-2" color="accent" style="font-size: 28px"
                    >mdi-plus-circle-outline</v-icon
                  >
                  Add Filter
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-badge v-if="filterCount > 0" color="primary" overlap>
            <span slot="badge" style="font-size: 17px; font-weight: bold">{{
              filterCount
            }}</span>
          </v-badge>
          <!-- filter end -->
        </div>
      </div>
    </div>

    <div v-if="invoices.length" class="ml-16 mb-6 secondary--text font-weight-bold" style="font-size: 25px;">
      Total Outstanding Payment: {{ convertToCurrency(totalOutstandingPayment) }}
    </div>
    <InvoiceTable
      :search="search"
      :filters="filters"
      :_invoices="invoices"
      @tableFiltered="tableFiltered"
      ref="invoiceTable"
    />
    
    <v-dialog v-model="addPaymentOfInvoiceDialog" persistent max-width="700">
      <AddPaymentOfInvoiceDialog
        @close="addPaymentOfInvoiceDialog = false"
        @send="savePayment"
      />
    </v-dialog>
  </div>
</template>

<script>
import InvoiceTable from "@/components/tables/InvoiceTable";
import AddPaymentOfInvoiceDialog from "@/components/dialogs/AddPaymentOfInvoiceDialog.vue";
import useRequest from "@/utils/useRequest";
import { mapGetters } from "vuex";

export default {
  name: "Invoices",
  components: {
    InvoiceTable,
    AddPaymentOfInvoiceDialog,
  },
  data() {
    return {
      search: "",
      searchMenu: false,
      filters: [
        {
          columns: [
            "STATUS",
            "CLIENT",
            "PARTIES",
            "DOCKET NO.",
            "INVOICE NUMBER",
            "INVOICE AMOUNT",
            "AMOUNT PAID",
            "DUE DATE",
            "DATE PAID",
          ],
          operators: [],
          values: [],
          filterColumn: "",
          filterOperator: "",
          filterValue: "",
        },
      ],
      filterMenu: false,
      dateMenu: false,
      tableData: [],
      addPaymentOfInvoiceDialog: false,
      totalOutstandingPayment: 0,
    };
  },
  computed: {
    ...mapGetters({
      invoices: "invoice/invoices",
      loaded: "invoice/loaded",
      cases: "eviction/cases",
      clients: "client/clients",
      clientsLoaded: "client/loaded",
    }),
    filterColumns() {
      return this.filters.map((item) => item.filterColumn);
    },
    filterCount() {
      return this.filters.filter(
        (item) => item.filterColumn && item.filterOperator && item.filterValue
      ).length;
    },
  },
  watch: {
    filterColumns(newVal, oldVal) {
      if (newVal.length !== oldVal.length) return;
      const diffItem = newVal.find((item, index) => item !== oldVal[index]);
      const diffIndex = newVal.indexOf(diffItem);
      if (this.filters[diffIndex].filterColumn.includes("DATE")) {
        this.filters[diffIndex].operators = [
          "On or Before",
          "On or After",
          "On",
        ];
        this.filters[diffIndex].filterValue = null;
      } else {
        this.filters[diffIndex].operators = ["Equal To"];
        this.filters[diffIndex].filterOperator = "Equal To";
        this.filters[diffIndex].filterValue = "";
        this.filters[diffIndex].values = this.tableData.map(
          (item) => item[this.filters[diffIndex].filterColumn]
        );
      }
    },
    invoices(val) {
      this.tableData = [];
      val.map((item) => {
        this.tableData.push({
          STATUS: item.status,
          CLIENT: item.clientName,
          PARTIES: item.caseParties,
          "DOCKET NO.": item.docketNo ?? "LT-",
          "INVOICE NUMBER": item.invoiceNo,
          "INVOICE AMOUNT": item.amount,
          "DUE DATE": item.dueDate,
          "AMOUNT PAID": item.amountPaid,
          "DATE PAID": item.datePaid,
        });
      });
      this.totalOutstandingPayment = this.invoices.reduce((total, item) => total + parseFloat(item.amount) - parseFloat(item.amountPaid ?? 0), 0);
    },
  },
  async created() {
    if (!this.loaded) {
      await this.$store.dispatch("invoice/loadInvoices");
      this.$store.commit("invoice/set_loaded", true);
    }
    if (!this.clientsLoaded) {
      await this.$store.dispatch("client/loadClients");
      this.$store.commit("client/set_loaded", true);
    }
    this.invoices.map((item) => {
      this.tableData.push({
        STATUS: item.status,
        CLIENT: item.clientName,
        PARTIES: item.caseParties,
        "DOCKET NO.": item.docketNo ?? "LT-",
        "INVOICE NUMBER": item.invoiceNo,
        "INVOICE AMOUNT": item.amount,
        "DUE DATE": item.dueDate,
        "AMOUNT PAID": item.amountPaid,
        "DATE PAID": item.datePaid,
      });
    });
    this.totalOutstandingPayment = this.invoices.reduce((total, item) => total + parseFloat(item.amount) - parseFloat(item.amountPaid ?? 0), 0);
  },
  methods: {
    tableFiltered(filteredInvoices) {
      this.totalOutstandingPayment = filteredInvoices.reduce((total, item) => total + parseFloat(item.amount) - parseFloat(item.amountPaid ?? 0), 0);
    },
    async sendNewInvoice(newInvoice) {
      this.newInvoiceDialog = false;
      await this.$store.dispatch("invoice/saveNewInvoice", newInvoice);
    },
    addInvoice() {
      this.$router.push({ name: "invoices-new" });
    },
    addPayment() {
      this.addPaymentOfInvoiceDialog = true;
    },
    async savePayment(payment) {
      await this.$store.dispatch("invoice/savePayment", payment);
      const clientInfo = this.clients.find(item => item.id === payment.clientId);
      const invoiceInfo = this.invoices.find(item => item.id === payment.invoices[0].id);
      const { data } = await useRequest({
        path: `invoicePdf?caseId=${invoiceInfo.caseId}&type=${invoiceInfo.type}&templateName=receipt`,
        showLoading: true,
      });
      useRequest({
        path: "send-receipt-email",
        method: "post",
        data: {
          email: clientInfo.email,
          name: `${clientInfo.firstName} ${clientInfo.lastName}`,
          caseId: invoiceInfo.caseId,
          type: invoiceInfo.type,
          // caseNumber: this.currentCase.docketNo,
          // propertyAddr: this.currentProperty.streetAddress,
          // tenantName: combineTenants(this.currentCase.tenants) ?? "Pending",
          amountPaid: payment.payAmount,
          datePaid: payment.date,
          receiptLink: data.fileUrl,
        },
      });
    },
    exportExcel() {
      this.$refs.invoiceTable.exportExcel();
    },
    removeFilter(index) {
      this.filters.splice(index, 1);
    },
    addFilter() {
      this.filters.push({
        columns: [
          "STATUS",
          "CLIENT",
          "PARTIES",
          "DOCKET NO.",
          "INVOICE NUMBER",
          "INVOICE AMOUNT",
          "AMOUNT PAID",
          "DUE DATE",
          "DATE PAID",
        ],
        operators: [],
        values: [],
        filterColumn: "",
        filterOperator: "",
        filterValue: "",
      });
    },
  },
};
</script>

<style scoped>
.v-menu__content {
  background-color: white;
  margin-top: 40px;
  border-radius: 15px;
  box-shadow: 15px 15px 40px #00000029 !important;
  position: absolute;
  top: 5px;
}
.v-select >>> .v-text-field__details {
  display: none;
}
</style>
