var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-12 left-panel",attrs:{"cols":"4"}},[_c('v-btn',{staticClass:"capital--btn btn--plain mb-8",attrs:{"text":""},on:{"click":function($event){return _vm.$router.push('/')}}},[_c('img',{attrs:{"src":"/images/logo-white.png","height":"50","alt":"logo"}})]),_c('div',{staticClass:"mt-16 pl-4"},[_c('p',{staticStyle:{"font-size":"1.5rem","color":"white !important"}},[_vm._v(" Step "+_vm._s(_vm.curStep in [1, 2, 8] ? _vm.curStep : 2)+" ")]),_c('p',{staticStyle:{"color":"white !important"}},[_vm._v(" "+_vm._s(_vm.curStep in [1, 2, 8] ? _vm.steps[_vm.curStep - 1].description : _vm.steps[1].description)+" ")]),_c('div',{staticClass:"mt-16"},[_c('v-timeline',{attrs:{"dense":""}},_vm._l((_vm.steps),function(step,index){return _c('v-timeline-item',{key:index,staticClass:"align-center",class:(_vm.curStep < 3 && index + 1 == _vm.curStep) ||
              (index == 2 && _vm.curStep == 8) ||
              (index == 1 && _vm.curStep > 2 && _vm.curStep < 8)
                ? 'active'
                : '',style:(`color: ${
              (_vm.curStep < 3 && index + 1 == _vm.curStep) ||
              (index == 2 && _vm.curStep == 8) ||
              (index == 1 && _vm.curStep > 2 && _vm.curStep < 8)
                ? 'white'
                : 'rgb(75 115 143)'
            }; padding-bottom: ${index + 1 == _vm.steps.length ? '0' : ''}`),attrs:{"large":"","color":"#003a60"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_vm._v(" "+_vm._s(index + 1)+" ")]},proxy:true}],null,true)},[_c('span',{staticClass:"ml-8",staticStyle:{"font-size":"1.2rem","font-weight":"400"}},[_vm._v(_vm._s(step.title))])])}),1)],1)])],1),_c('v-col',{staticClass:"pt-16 px-16 secondary--text",attrs:{"cols":"8"}},[(_vm.curStep === 1)?_c('ProfileInformation',{on:{"updateStep":_vm.updateStep}}):_vm._e(),(_vm.curStep === 2)?_c('ImportClientIndex',{on:{"updateStep":_vm.updateStep}}):_vm._e(),(_vm.curStep > 2 && _vm.curStep < 8)?_c('div',{staticClass:"mx-12"},[_c('p',{staticClass:"mt-8 font-weight-medium",staticStyle:{"font-size":"1.7rem"}},[_vm._v(" Import from Spreadsheet ")]),_c('p',[_vm._v(_vm._s(_vm.importClientDescs[_vm.curStep - 4]))]),_c('v-card',{staticClass:"secondary--text mt-16 pa-16 rounded-card"},[_c('v-breadcrumbs',{staticClass:"font-weight-medium",attrs:{"items":_vm.breadcrumbsItems,"large":""},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-breadcrumbs-item',{staticStyle:{"font-size":"22px"},attrs:{"disabled":_vm.breadcrumbsItems.indexOf(item) + 3 > _vm.curStep}},[_c('v-icon',{staticStyle:{"font-size":"25px"},attrs:{"color":_vm.breadcrumbsItems.indexOf(item) + 3 > _vm.curStep
                    ? 'info'
                    : 'primary'}},[_vm._v(_vm._s(_vm.breadcrumbsItems.indexOf(item) + 3 > _vm.curStep ? "mdi-circle-outline" : "mdi-check-circle"))]),_vm._v("   "+_vm._s(item.text)+" ")],1)]}},{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}],null,false,1798882647)}),(_vm.curStep === 3)?_c('ImportPrepareFile',{on:{"updateStep":_vm.updateStep}}):_vm._e(),(_vm.curStep === 4)?_c('ImportUploadFile',{on:{"uploaded":_vm.fileUploaded,"updateStep":_vm.updateStep}}):_vm._e(),(_vm.curStep === 5)?_c('ImportColumnMapping',{attrs:{"excelHeaders":_vm.excelHeaders},on:{"mapColumn":_vm.mapColumn,"updateStep":_vm.updateStep}}):_vm._e(),(_vm.curStep === 6)?_c('ImportLoading',{attrs:{"excelData":_vm.excelData},on:{"updateStep":_vm.updateStep}}):_vm._e(),(_vm.curStep === 7)?_c('ImportComplete',{attrs:{"importCnt":_vm.importCnt},on:{"updateStep":_vm.updateStep}}):_vm._e()],1)],1):_vm._e(),(_vm.curStep === 8)?_c('Complete',{on:{"updateStep":_vm.updateStep}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }