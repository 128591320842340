<template>
  <v-card
    height="80%"
    width="100%"
    style="box-shadow: 15px 15px 40px #00000029; border-radius: 20px"
  >
    <div class="pa-4 pl-12 secondary--text">
      <v-card-title class="font-weight-bold px-0">
        <div class="d-flex">Overview</div>
        <v-spacer />
        <div>
          <v-btn
            icon
            color="info"
            class="btn--plain mr-2"
            @click="editCaseInfo"
          >
            <v-icon size="xx-large">{{
              editing ? "mdi-content-save-outline" : "mdi-pencil-outline"
            }}</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            <div>
              <div class="overline info--text font-weight-bold mb-0">
                {{ currentCase.vcDateFiled ? "filed" : "drafted" }}
              </div>
              <div v-if="editing">
                <v-menu
                  v-model="filedDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="$dayjs(filedDate).format('MMMM DD, YYYY')"
                      append-icon="$calendar"
                      color="secondary"
                      readonly
                      rounded
                      solo
                      flat
                      dense
                      v-on="on"
                      background-color="#F0F5F6"
                      @click:append="filedDateMenu = true"
                    />
                  </template>
                  <v-date-picker
                    v-model="filedDate"
                    no-title
                    color="primary"
                    class="custom-date"
                    @input="filedDateMenu = false"
                  />
                </v-menu>
              </div>
              <div v-else>
                <p v-if="currentCase.vcDateFiled" class="spaced-text mb-0">
                  {{ $dayjs(currentCase.vcDateFiled).format("MMMM DD, YYYY") }}
                </p>
                <p v-else class="spaced-text mb-0">
                  {{ $dayjs(currentCase.statusDate).format("MMMM DD, YYYY") }}
                </p>
              </div>
            </div>
          </v-col>
          <v-col>
            <div>
              <p class="overline info--text font-weight-bold mb-0">ground</p>
              <p class="spaced-text mb-0">Non-Payment of Rent</p>
            </div>
          </v-col>
          <v-col>
            <div>
              <p class="overline info--text font-weight-bold mb-0">county</p>
              <v-select
                v-if="editing"
                v-model="county"
                item-color="#F6F9FA"
                rounded
                item-text="countyName"
                solo
                flat
                dense
                background-color="#F0F5F6"
                :items="counties"
              >
                <v-icon slot="append" medium color="accent">
                  mdi-chevron-down
                </v-icon>
              </v-select>
              <p v-else class="spaced-text mb-0">
                {{ currentProperty.county }}
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div>
              <p class="overline info--text font-weight-bold mb-0">parties</p>
              <p class="spaced-text mb-0 pr-5">
                {{
                  currentProperty.deedNames
                    ? currentProperty.deedNames
                    : "Pending"
                }}
                vs.
                {{ compileTenants(currentCase.tenants) }}
              </p>
            </div>
          </v-col>
          <v-col>
            <div>
              <p class="overline info--text font-weight-bold mb-0">
                Trial Date
              </p>
              <div v-if="editing">
                <v-menu
                  v-model="trialDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="$dayjs(trialDate).format('MMMM DD, YYYY')"
                      append-icon="$calendar"
                      color="secondary"
                      readonly
                      rounded
                      solo
                      flat
                      dense
                      v-on="on"
                      background-color="#F0F5F6"
                      @click:append="trialDateMenu = true"
                    />
                  </template>
                  <v-date-picker
                    v-model="trialDate"
                    no-title
                    color="primary"
                    class="custom-date"
                    @input="trialDateMenu = false"
                  />
                </v-menu>
              </div>
              <div v-else>
                <p
                  v-if="currentCase.trialDate"
                  class="spaced-text mb-0"
                  style="color: #8ba4b4"
                >
                  {{ $dayjs(currentCase.trialDate).format("MMMM DD, YYYY") }}
                </p>
                <p v-else class="spaced-text" style="color: #8ba4b4">Pending</p>
              </div>
            </div>
          </v-col>
          <v-col>
            <div>
              <p class="overline info--text font-weight-bold mb-0">
                suit amount
              </p>
              <p class="spaced-text mb-0">
                {{ getInitialSuitAmount(currentCase) }}
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div>
              <p class="overline info--text font-weight-bold mb-0">
                docket no.
              </p>
              <v-text-field
                v-if="editing"
                v-model="docketNo"
                color="secondary"
                rounded
                solo
                flat
                dense
                background-color="#F0F5F6"
              />
              <p v-else class="spaced-text mb-0" style="color: #8ba4b4">
                {{ currentCase.docketNo ? currentCase.docketNo : "LT-" }}
              </p>
            </div>
          </v-col>
          <v-col>
            <div>
              <p class="overline info--text font-weight-bold mb-0">
                lease type
              </p>
              <v-select
                v-if="editing"
                v-model="leaseType"
                item-color="#F6F9FA"
                rounded
                item-text="countyName"
                solo
                flat
                dense
                background-color="#F0F5F6"
                :items="['Residential', 'Commercial']"
              >
                <v-icon slot="append" medium color="accent">
                  mdi-chevron-down
                </v-icon>
              </v-select>
              <p v-else class="spaced-text mb-0">
                {{
                  currentCase.leaseTypeTitle
                    ? currentCase.leaseTypeTitle
                    : "Pending"
                }}
              </p>
            </div>
          </v-col>
          <v-col>
            <div>
              <p class="overline info--text font-weight-bold mb-1">
                court contact
              </p>
              <div v-if="editing" class="d-flex" style="width: 85%">
                <v-text-field
                  v-model="courtContact.phone"
                  color="secondary"
                  rounded
                  solo
                  flat
                  dense
                  background-color="#F0F5F6"
                  style="width: 50%"
                />
                <v-text-field
                  v-model="courtContact.ext"
                  color="secondary"
                  rounded
                  solo
                  flat
                  dense
                  background-color="#F0F5F6"
                  class="ml-4"
                  style="width: 20%"
                />
              </div>
              <p v-else class="spaced-text mb-0">
                {{ courtAddress.phone }}
                {{ courtAddress.ext }}
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <div>
              <p class="overline info--text font-weight-bold mb-0">
                property address
              </p>
              <div>
                <p class="spaced-text mb-0">
                  {{ currentProperty.streetAddress
                  }}<span v-if="currentProperty.unitNo"
                    >, {{ currentProperty.unitNo }}</span
                  >
                  {{ currentProperty.city }},
                  {{ currentProperty.state }}
                  {{ currentProperty.zipcode }}
                </p>
              </div>
            </div>
          </v-col>
          <v-col>
            <div>
              <p class="overline info--text font-weight-bold mb-0">
                opposing counsel
              </p>
              <div class="d-flex">
                <p
                  v-if="
                    currentCase.opposingCounselInfo &&
                    currentCase.opposingCounselInfo.fullName
                  "
                  class="spaced-text mb-0 mr-4"
                >
                  {{ currentCase.opposingCounselInfo.fullName }}
                </p>
                <v-btn
                  color="accent_light"
                  class="px-4 white--text"
                  rounded
                  depressed
                  @click="
                    $router.push({
                      name:
                        currentCase.opposingCounselInfo &&
                        currentCase.opposingCounselInfo.fullName
                          ? 'opposing-counsel-info'
                          : 'before-select-opposing-counsel',
                    })
                  "
                >
                  {{
                    currentCase.opposingCounselInfo &&
                    currentCase.opposingCounselInfo.fullName
                      ? "View"
                      : "Add Opposing Counsel"
                  }}
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="card-action-bottom d-flex align-items-center">
      <v-btn
        color="accent_light"
        class="px-4 white--text"
        rounded
        depressed
        @click="editFlow"
      >
        Edit Flow
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import useRequest from "@/utils/useRequest";

export default {
  name: "CaseOverviewCard",
  props: {
    currentCase: {
      type: Object,
      default: () => {},
    },
    currentProperty: {
      type: Object,
      default: () => {},
    },
    courtAddress: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editing: false,
      counties: [],
      filedDate: "",
      filedDateMenu: false,
      manageConf: "",
      manageConfMenu: false,
      trialDate: "",
      trialDateMenu: false,
      county: "",
      docketNo: "",
      leaseType: "",
      courtContact: {
        phone: "",
        ext: "",
      },
    };
  },
  async mounted() {
    window.scrollTo(0, 0);
    await this.getCountiesOfState();
  },
  methods: {
    async getCountiesOfState() {
      await useRequest({
        path: "countiesOfState",
        params: {
          stateId: 31,
        },
        onSuccess: (counties) => {
          this.counties = counties;
        },
      });
    },
    async editCaseInfo() {
      this.editing = !this.editing;
      if (this.editing) {
        this.filedDate = this.$dayjs(
          this.currentCase.vcDateFiled ?? this.currentCase.statusDate
        ).format("YYYY-MM-DD");
        this.manageConf = this.currentCase.manageConf
          ? this.$dayjs(this.currentCase.manageConf).format("YYYY-MM-DD")
          : this.$dayjs().format("YYYY-MM-DD");
        this.county = this.currentProperty.county;
        this.trialDate = this.currentCase.trialDate
          ? this.$dayjs(this.currentCase.trialDate).format("YYYY-MM-DD")
          : this.$dayjs().format("YYYY-MM-DD");
        this.docketNo = this.currentCase.docketNo;
        this.leaseType = this.currentCase.leaseTypeTitle;
        this.courtContact.phone = this.courtAddress.phone;
        this.courtContact.ext = this.courtAddress.ext;
      } else {
        this.$store.dispatch("eviction/updateCase", {
          caseData: {
            vcDateFiled: this.$dayjs(this.filedDate).format("YYYY-MM-DD"),
            manageConf: this.$dayjs(this.manageConf).format("YYYY-MM-DD"),
            trialDate: this.$dayjs(this.trialDate).format("YYYY-MM-DD"),
            docketNo: this.docketNo,
            leaseTypeTitle: this.leaseType,
          },
        });
        await this.$store.dispatch("property/saveProperty", {
          propertyData: {
            county: this.county,
          },
        });
        this.$store.dispatch("eviction/loadCase", {
          caseId: this.currentCase.id,
        });
        this.$store.commit("eviction/set_court_info", {
          phone: this.courtContact.phone,
          ext: this.courtContact.ext,
        });
      }
    },
    async editFlow() {
      this.$store.dispatch("eviction/editCase");
    },
    compileTenants(tenants) {
      let tenantsCompiled = "";
      if (!tenants) return "Pending";
      if (this.currentCase.tenantCompanyName) {
        return this.currentCase.tenantCompanyName;
      }
      for (let i = 0; i < tenants.length; i++) {
        if (tenants.length - 2 === i) {
          tenantsCompiled += `${tenants[i].name} ` + `and `;
        } else if (tenants.length - 1 === i) {
          tenantsCompiled += tenants[i].name;
        } else {
          tenantsCompiled += `${tenants[i].name}` + `, `;
        }
      }
      return tenantsCompiled === "" ? "Pending" : tenantsCompiled;
    },
    changeNumToCurrency(num) {
      if (!num) return "$0.00";
      return `$${parseFloat(num)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")}`;
    },
    calculateTotal(arr) {
      let total = 0;
      arr.forEach((item) => {
        if (item.amount && item.selected) {
          total += parseFloat(item.amount);
        }
      });
      return total;
    },
    getInitialSuitAmount(item) {
      let totalMoney = 0;
      [
        "lateCharges",
        "utilities",
        "fees",
        "otherCharges",
        "pastDueRent",
        "legalFees",
      ].forEach((property) => {
        try {
          if (item[property] === null || item[property] === undefined) return;
          totalMoney += this.calculateTotal(item[property]);
        } catch (e) {
          return e;
        }
      });
      return this.changeNumToCurrency(totalMoney);
    },
  },
};
</script>

<style scoped>
.v-input {
  max-width: 85%;
}
.v-input >>> .v-text-field__details {
  display: none !important;
}
</style>
