<template>
  <div class="invoices-table">
    <v-data-table
      v-if="invoices.length > 0"
      v-model="selected"
      :headers="headers"
      :header-props="{ 'sort-icon': 'mdi-menu-up' }"
      :must-sort="true"
      :items="invoices"
      :search="search"
      :custom-filter="customFilter"
      item-key="ID"
      class="my-table"
      :items-per-page="itemsPerPage"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-skip-previous',
        lastIcon: 'mdi-skip-next',
        prevIcon: 'mdi-menu-left',
        nextIcon: 'mdi-menu-right',
        itemsPerPageText: 'Number of invoices to display',
        pageText: pageText,
      }"
      @page-count="pageCount = $event"
      @update:options="updateOptions"
      @current-items="getFilteredInvoices"
    >
      <template v-slot:item="{ item, index }">
        <tr
          @mouseover="rowHoverIndex = index"
          @mouseleave="rowHoverIndex = -1"
          :class="{ 'on-hover': rowHoverIndex === index }"
          class="data-row"
        >
          <td class="first-column">
            {{ isDueDatePassed(item) ? "Payment Overdue" : item.status }}
          </td>
          <td>
            {{ item.clientName }}
          </td>
          <td>
            {{ item.caseParties }}
          </td>
          <td
            class="ml-4 accent--text"
            @click="caseOverview(item.caseId)"
            style="cursor: pointer"
          >
            {{ item.docketNo ? item.docketNo : "LT-" }}
          </td>
          <td>
            {{ item.invoiceNo }}
          </td>
          <td>
            {{ convertToCurrency(item.amount) }}
          </td>
          <td>
            {{ convertToCurrency(item.amountPaid) }}
          </td>
          <td>
            <tr>{{ item.dueDate }}</tr>
            <tr v-if="isDueDatePassed(item) " class="text-center info--text">{{ datediff(parseDate(item.dueDate), parseDate($dayjs(new Date).format("MM/DD/YYYY"))) }} days passed</tr>
          </td>
          <td>
            {{ item.datePaid ? item.datePaid : '' }}
          </td>
          <td style="width: 10px">
            <v-menu offset-y bottom right open-on-hover content-class="mt-n1">
              <template v-slot:activator="{ on }">
                <v-btn small icon color="primary" class="btn--plain" v-on="on">
                  <v-icon large>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item @click="resendInvoice(item)">
                  <v-list-item-title class="overline info--text">
                    <i style="font-size: 20px" :class="`icofont-coins pr-4`" />
                    Resend Invoice
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="resendReceipt(item)">
                  <v-list-item-title class="overline info--text">
                    <i style="font-size: 20px" :class="`icofont-coins pr-4`" />
                    Resend Receipt
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="viewInvoice(item)">
                  <v-list-item-title class="overline info--text">
                    <i
                      style="font-size: 20px"
                      :class="`icofont-eye pr-4`"
                    />
                    View Invoice
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="viewReceipt(item)">
                  <v-list-item-title class="overline info--text">
                    <i
                      style="font-size: 20px"
                      :class="`icofont-eye pr-4`"
                    />
                    View Receipt
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
        <td :colspan="headers.length" class="row-divider"></td>
      </template>
      <template v-if="invoices.length !== 0" slot="body.append">
        <tr style="height: 30px"></tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { combineTenants } from "@/utils";
import useRequest from "@/utils/useRequest";

export default {
  name: "InvoiceTable",
  props: {
    _invoices: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: "",
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Status",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "240px",
          sortable: false,
        },
        {
          text: "Client",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Parties",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Docket No.",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "120px",
          sortable: false,
        },
        {
          text: "Invoice Number",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "150px",
          sortable: false,
        },
        {
          text: "Invoice Amount",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "160px",
          sortable: false,
        },
        {
          text: "Amount Paid",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "130px",
          sortable: false,
        },
        {
          text: "Due Date",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "150px",
          sortable: false,
        },
        {
          text: "Date Paid",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "100px",
          sortable: false,
        },
        {
          class: "header-text secondary--text font-weight-medium",
          align: "center",
          sortable: false,
        },
      ],
      selected: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      rowHoverIndex: false,
      invoices: [],
      filteredInvoices: [],
      currentItem: null,
    };
  },
  computed: {
    ...mapGetters({
      cases: "eviction/cases",
      properties: "property/properties",
    }),
    pageText() {
      return "Pages " + this.page + ` / ` + this.pageCount;
    },
    today() {
      return this.$dayjs(new Date()).format("YYYY-MM-DD");
    },
  },
  watch: {
    filters: {
      handler(val) {
        if (!val.length) {
          this.invoices = this._invoices;
          return;
        }
        const _filters = val.filter(
          (item) => item.filterColumn && item.filterOperator && item.filterValue
        );
        if (_filters.length) {
          let _filteredInvoices = this._invoices;
          _filters.map((item) => {
            if (item.filterColumn === "DUE DATE") {
              switch (item.filterOperator) {
                case "On or Before":
                  _filteredInvoices = _filteredInvoices.filter(
                    (invoiceItem) =>
                      this.$dayjs(invoiceItem.dueDate).format("YYYY-MM-DD") <=
                      this.$dayjs(item.filterValue).format("YYYY-MM-DD")
                  );
                  break;
                case "On or After":
                  _filteredInvoices = _filteredInvoices.filter(
                    (invoiceItem) =>
                      this.$dayjs(invoiceItem.dueDate).format("YYYY-MM-DD") >=
                      this.$dayjs(item.filterValue).format("YYYY-MM-DD")
                  );
                  break;
                case "On":
                  _filteredInvoices = _filteredInvoices.filter(
                    (invoiceItem) =>
                      this.$dayjs(invoiceItem.dueDate).format("YYYY-MM-DD") ==
                      this.$dayjs(item.filterValue).format("YYYY-MM-DD")
                  );
                  break;
              }
            } else if (item.filterColumn === "DATE PAID") {
              switch (item.filterOperator) {
                case "On or Before":
                  _filteredInvoices = _filteredInvoices.filter(
                    (invoiceItem) =>
                      invoiceItem.datePaid && this.$dayjs(invoiceItem.datePaid).format("YYYY-MM-DD") <=
                      this.$dayjs(item.filterValue).format("YYYY-MM-DD")
                  );
                  break;
                case "On or After":
                  _filteredInvoices = _filteredInvoices.filter(
                    (invoiceItem) =>
                      invoiceItem.datePaid && this.$dayjs(invoiceItem.datePaid).format("YYYY-MM-DD") >=
                      this.$dayjs(item.filterValue).format("YYYY-MM-DD")
                  );
                  break;
                case "On":
                  _filteredInvoices = _filteredInvoices.filter(
                    (invoiceItem) =>
                      invoiceItem.datePaid && this.$dayjs(invoiceItem.datePaid).format("YYYY-MM-DD") ==
                      this.$dayjs(item.filterValue).format("YYYY-MM-DD")
                  );
                  break;
              }
            } else {
              _filteredInvoices = _filteredInvoices.filter((invoiceItem) => {
                switch (item.filterColumn) {
                  case "STATUS":
                    return invoiceItem.status == item.filterValue;
                  case "CLIENT":
                    return invoiceItem.clientName == item.filterValue;
                  case "PARTIES":
                    return invoiceItem.caseParties == item.filterValue;
                  case "DOCKET NO.":
                    return (invoiceItem.docketNo ?? "Pending") == item.filterValue;
                  case "INVOICE NUMBER":
                    return invoiceItem.invoiceNo == item.filterValue;
                  case "INVOICE AMOUNT":
                    return invoiceItem.amount == item.filterValue;
                  case "AMOUNT PAID":
                    return invoiceItem.amountPaid == item.filterValue;
                }
              });
            }
          });
          this.invoices = _filteredInvoices;
        }
      },
      deep: true,
    },
    _invoices() {
      this.invoices = this._invoices;
    },
  },
  async created() {
    this.invoices = this._invoices;
  },
  methods: {
    datediff(first, second) {        
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
    },
    parseDate(str) {
      var mdy = str.split('/');
      return new Date(mdy[2], mdy[0] - 1, mdy[1]);
    },
    isDueDatePassed(item) {
      const passed = this.$dayjs(item.dueDate).format("YYYY-MM-DD") < this.today;
      if (passed && item.status !== "Payment Overdue") {
        this.$store.dispatch("invoice/update", {
          id: item.id,
          status: "Payment Overdue",
        });
      }

      return passed;
    },
    async caseOverview(caseId) {
      await this.$store.dispatch("eviction/loadCase", {
        caseId,
        forceLoad: true,
      });
      this.$router.push(`case-view/${caseId}/overview`);
    },
    getFilteredInvoices(filteredInvoices) {
      this.filteredInvoices = filteredInvoices;
      this.$emit("tableFiltered", this.invoices);
    },
    exportExcel() {
      const headersText = this.headers
        .slice(0, this.headers.length - 1)
        .map((item) => item.text);
      let csv_string = headersText.join(", ") + "\n";
      this.filteredInvoices.forEach((item) => {
        csv_string += item.status;
        csv_string += `,${item.clientName}`;
        csv_string += `,${item.caseParties}`;
        csv_string += `,${item.docketNo ?? "LT-"}`;
        csv_string += `,${item.invoiceNo ?? ""}`;
        csv_string += `,${item.amount ?? ""}`;
        csv_string += `,${item.amountPaid ?? ""}`;
        csv_string += `,${item.dueDate ?? ""}`;
        csv_string += `,${item.datePaid ?? ""}`;
        csv_string += "\n";
      });

      const link = document.createElement("a");
      link.style.display = "none";
      link.setAttribute("target", "_blank");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
      );
      link.setAttribute("download", "export_invoices.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async viewInvoice(item) {
      const { data } = await useRequest({
        path: `invoicePdf?caseId=${item.caseId}&type=${item.type}&templateName=invoice`,
        showLoading: true,
      });
      window.open(data.fileUrl, "_blank");
    },
    async viewReceipt(item) {
      if (!item.datePaid) return;
      const { data } = await useRequest({
        path: `invoicePdf?caseId=${item.caseId}&type=${item.type}&templateName=receipt`,
        showLoading: true,
      });
      window.open(data.fileUrl, "_blank");
    },
    async resendInvoice(item) {
      await this.$store.dispatch("property/loadProperties", item.clientId, {
        root: true,
      });
      const caseInfo = this.cases.find(caseItem => caseItem.id === item.caseId);
      const propertyInfo = this.properties.find(propertyItem => propertyItem.propertyId === caseInfo.propertyId);
      const getUnitNo = propertyInfo.unitNo ? `, ${propertyInfo.unitNo}` : '';
      const propertyAddress = `${propertyInfo.streetAddress}${getUnitNo}, ${propertyInfo.city}, ${propertyInfo.state} ${propertyInfo.zipcode}`;
      const { data } = await useRequest({
        path: `invoicePdf?caseId=${item.caseId}&type=${item.type}&templateName=invoice`,
        showLoading: true,
      });
      useRequest({
        path: "send-invoice-email",
        method: "post",
        data: {
          email: caseInfo.clientInfo.email,
          name: caseInfo.clientInfo.name,
          caseId: item.caseId,
          type: item.type,
          caseNumber: item.docketNo ?? "LT-",
          propertyAddr: propertyAddress,
          tenantName: combineTenants(caseInfo.tenants) ?? "Pending",
          amount: item.amount,
          dueDate: item.dueDate,
          invoiceLink: data.fileUrl,
        },
      });
    },
    resendReceipt(item) {

    },
    customFilter(_value, search, items) {
      Object.keys(items).forEach((key) => {
        if (items[key] === null) items[key] = "";
      });

      return (
        items.status.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        items.clientName.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        items.caseParties.toString().toLowerCase().includes(search.toLowerCase()) ||
        items.docketNo.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        items.id.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        items.amount.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        items.amountPaid.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        items.dueDate.toString()
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        items.datePaid.toString()
          .toLowerCase()
          .includes(search.toLowerCase())
      );
    },
    updateOptions(option) {
      this.page = option.page;
    },
  },
};
</script>

<style>
.invoices-table .v-data-table__wrapper {
  padding: 0px 50px 50px;
}
.invoices-table .v-data-table__wrapper > table {
  box-shadow: 15px 15px 40px #00000029;
  border-radius: 20px;
  min-width: 1000px;
}
.invoices-table
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  height: 65px !important;
  background-color: white;
  text-transform: uppercase;
}

.v-data-table.rounded-table.theme--light {
  border-radius: 20px;
}
.v-data-table td.custom-expanded-row {
  background-color: #f8fafb;
  padding: 4px 16px;
}
.v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.v-data-table thead th.active.asc .v-data-table-header__icon {
  opacity: 1 !important;
}

.v-data-table thead th.active.desc .v-data-table-header__icon {
  opacity: 1 !important;
}

.v-data-table-header__icon {
  opacity: 1 !important;
}
.v-data-table-header tr th:first-child {
  padding-left: 36px !important;
}
</style>

<style scoped>
.data-row {
  color: #003a60 !important;
  height: 80px !important;
}
.data-row > td {
  border-bottom: none !important;
}
.add-invoice-row {
  height: 100px !important;
}
.data-row:hover,
.add-invoice-row:hover {
  background-color: transparent !important;
}
.first-column {
  border-left: 8px solid transparent;
}
.on-hover > .first-column {
  border-left: 8px solid #4dccc4;
}
.row-divider {
  height: 1px !important;
  background: #c3d1d2 !important;
}
.status-row {
  background-color: #f8fafb !important;
  color: #003a60 !important;
}
.status-grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  align-items: center;
  height: 65px !important;
  padding-right: 10px;
}
</style>
